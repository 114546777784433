<template>
  <div>
    <header-top-dashboard primary> </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-overlay :value="updateLoading" absolute>
        データを更新しています
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card class="card-shadow">
        <div class="card-header-padding card-border-bottom">
          <div class="d-flex justify-space-between mb-5">
            <div>
              <div class="font-weight-600 text-h3 text-typo mb-1">
                候補者一覧
              </div>
              <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
                新しく候補者を追加する場合は、画面右手の候補者の追加ボタンをクリックしてください。
              </p>
            </div>
            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
              color="teal"
              @click="$router.push({ path: 'candidates/create' })"
            >
              <v-icon class="mr-2">fa-plus</v-icon>
              候補者を追加
            </v-btn>
          </div>
          <div class="mt-m13">
            <v-row>
              <v-col>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      height="43"
                      class="input-style font-size-select text-light-input placeholder-light select-width"
                      v-model="ageAtApplication"
                      :items="ageOptions"
                      item-text="text"
                      item-value="value"
                      placeholder="応募時年齢"
                      persistent-hint
                      @input="loadCandidates"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                      height="46"
                      placeholder="雇用形態"
                      v-model="employmentStatus"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      height="43"
                      class="input-style font-size-select text-light-input placeholder-light select-width"
                      v-model="selectedStatus"
                      :items="statusOptions"
                      item-text="text"
                      item-value="value"
                      persistent-hint
                      @input="loadCandidates"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-select
                      outlined
                      height="43"
                      class="input-style font-size-select text-light-input placeholder-light select-width"
                      v-model="storeName"
                      :items="stores"
                      item-text="text"
                      item-value="value"
                      placeholder="店舗名"
                      persistent-hint.
                      @input="loadCandidates"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="8">
                    <div class=" d-flex align-center">
                      <v-menu
                        ref="menuFrom"
                        v-model="menuFromIsOpen"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="applyAtFrom"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="custom-style"
                          ref="picker"
                          locale="jp-ja"
                          v-model="applyAtFrom"
                          color="#46f3c4"
                          :day-format="(date) => new Date(date).getDate()"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @input="
                            menuFromIsOpen = false;
                            loadCandidates();
                          "
                        >
                        <v-btn 
                            text color="primary" @click="clearApplyFrom()">Cancel
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                        <p class="font-weight-bold mb-0 mx-6">
                          ~
                        </p>
                      <v-menu
                        ref="menuTo"
                        v-model="menuToIsOpen"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="applyAtTo"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          class="custom-style"
                          ref="picker"
                          locale="jp-ja"
                          v-model="applyAtTo"
                          color="#46f3c4"
                          :day-format="(date) => new Date(date).getDate()"
                          :max="new Date().toISOString().substr(0, 10)"
                          min="1950-01-01"
                          @input="
                            menuToIsOpen = false;
                            loadCandidates();
                          "
                        >
                          <v-btn 
                            text color="primary" @click="clearApplyTo()">Cancel
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-dialog width="538" v-model="deleteDialog">
          <template v-slot:default="dialog">
            <v-card class="card-shadow bg-gradient-danger">
              <div
                class="card-header-padding d-flex align-center justify-space-between pb-10"
              >
                <v-card-title class="pa-0 text-h3 font-weight-600 text-white">
                  注意
                </v-card-title>
                <v-btn
                  icon
                  @click="dialog.value = false"
                  elevation="0"
                  :ripple="false"
                  height="38"
                  min-width="38"
                  width="38"
                  class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
                  color="rgba(255,255,255,.6)"
                >
                  <v-icon size="13">fas fa-times</v-icon>
                </v-btn>
              </div>
              <v-card-text class="card-padding pt-6 text-center">
                <v-icon size="48" class="text-white"> ni-bell-55 </v-icon>
                <h4
                  class="heading mt-6 text-white font-weight-600 text-uppercase"
                >
                  候補者ID {{ deleteCandidateId }}
                  {{ deleteName }} を削除しようとしています
                </h4>
                <p class="text-white font-size-root font-weight-thin mt-3">
                  一度削除した候補者は元に戻すことができません。<br />
                  本当に削除してよろしいですか？
                </p>
              </v-card-text>
              <div
                class="card-padding d-flex align-center justify-space-between"
              >
                <v-btn
                  elevation="0"
                  max-width="136"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
                  color="#fff"
                  @click="deleteCandidate"
                  :loading="deleteLoading"
                >
                  はい、削除します
                </v-btn>
                <v-btn
                  @click="dialog.value = false"
                  elevation="0"
                  max-width="136"
                  :ripple="false"
                  height="43"
                  class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
                  color="transparent"
                >
                  閉じる
                </v-btn>
              </div>
            </v-card>
          </template>
        </v-dialog>
        <v-card-text class="px-0 py-0">
          <v-data-table
            :header-props="{ 'sort-icon': 'fa-sort-up' }"
            :headers="headers"
            :items="candidates"
            :loading="loading"
            loading-text="データを取得しています... Please wait"
            class="table thead-light text-body"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
          >
            <template v-slot:item.CandidateId="{ item }">
              <div class="d-flex align-center">
                <v-checkbox
                  v-model="selectedEmail"
                  :value="
                    item.Email ? item.Email : item.Email + item.CandidateId
                  "
                  :key="item.CandidateId"
                ></v-checkbox>
                {{ item.CandidateId }}
              </div>
            </template>

            <template v-slot:item.Status="{ item }">
              <span :class="statusListItem[item.Status].color">{{
                statusListItem[item.Status].text
              }}</span>
            </template>

            <template v-slot:item.Name="{ item }">
              {{ item.Name }} ({{ item.Kana }})
<!--              <div class="pointer-cursor" @click="-->
<!--                      $router.push({ path: 'candidates-show/' + item.CandidateId })-->
<!--                    ">-->
<!--                {{ item.Name }} ({{ item.Kana }})-->
<!--              </div>-->
            </template>

            <template v-slot:item.Referral="{ item }">
              <span v-if="item.Referral === 2">あり</span>
              <span v-if="item.Referral === 1">なし</span>
            </template>

            <template v-slot:item.TrafficSource="{ item }">
              <v-select
                dense
                outlined
                v-model="item.TrafficSource"
                item-text="text"
                item-value="value"
                :items="trafficSourceItem"
                hide-details
                @change="updateTrafficSource(item.CandidateId, $event)">
                <template #selection="{ item }">
                  <span class="statusText"><v-icon class="mr-1 icon-width" :color="item.color">{{ item.icon }}</v-icon>{{ item.text }}</span>
                </template>
                <template #item="{ item }">
                  <span class="statusText"><v-icon class="mr-1 icon-width" :color="item.color">{{ item.icon }}</v-icon>{{ item.text }}</span>
                </template>
              </v-select>
            </template>

            <template v-slot:item.ApplyAt="{ item }">
              {{ $dayjs(item.ApplyAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>

            <template v-slot:item.UpdatedAt="{ item }">
              {{ $dayjs(item.UpdatedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      $router.push({ path: 'candidates/' + item.CandidateId })
                    "
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                    outlined
                    v-on="on"
                    color="cyan"
                  >
                    <v-icon size="16">mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>編集画面へ遷移します</span>
              </v-tooltip>
            </template>

            <template v-slot:item.deleteAction="{ item }">
              <v-btn
                @click="openDeleteDialog(item.CandidateId, item.Name)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                color="red"
                outlined
              >
                <v-icon size="16">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding">
          <v-row>
            <v-col cols="12" class="ml-auto d-flex justify-end">
              <!-- TODO: May be we need this later -->
              <!-- <v-btn
                elevation="1"
                :ripple="false"
                height="43"
                class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3"
                plain
                :disabled="selectedEmail.length === 0"
                @click="openEmailDialog"
              >
                <v-icon class="mr-2">fa-envelope</v-icon>
                メール一括送信
              </v-btn> -->
              <v-btn
                elevation="1"
                :ripple="false"
                height="43"
                class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3"
                plain
                @click="download"
              >
                <v-icon class="mr-2">fa-file-export</v-icon>
                エクスポート
              </v-btn>
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#46f3c4"
                v-model="page"
                :length="pageCount"
                circle
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-actions>

        <div v-if="emailDialog && selectedEmail && selectedEmail.length > 0">
          <email-modal
            :emailDialog="emailDialog"
            :selectedEmail="selectedEmail"
            @closeEmailDialog="closeEmailDialog"
          />
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped>

::v-deep .articles td {
  white-space: nowrap;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}
::v-deep .articles th {
  white-space: nowrap;
}

.pointer-cursor {
  cursor: pointer;
}
.custom-style >>> .v-date-picker-table {
  height: min-content;
}

.v-data-table::v-deep .text-start:nth-child(1) {
  padding-left: 24px;
}

</style>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import dayjs from "dayjs";
import papa from "papaparse";
import CandidateDetail from "./CandidateDetail";
import { debounce } from "@/util/debounce"
import HeaderTopDashboard from "@/components/HeaderTopDashboard";
import EmailModal from "./components/EmailModal.vue";
import CANDIDATE_STATUS from "@/const/candidateItem.js";
import TRAFFIC_SOURCE from "@/const/trafficSource";

export default {
  name: "Candidates",
  components: {
    HeaderTopDashboard,
    CandidateDetail,
    EmailModal,
  },
  data() {
    return {
      loading: false,
      deleteCandidateId: 0,
      deleteName: "",
      deleteDialog: false,
      deleteLoading: false,
      candidates: [],
      stores: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      checkboxModel: [],
      selectedStatus: 0,
      applyAtFrom: null,
      applyAtTo: null,
      ageAtApplication: 0,
      employmentStatus: "",
      storeName: "",
      trafficSource: 0,
      menuFromIsOpen: false,
      menuToIsOpen: false,
      emailDialog: false,
      selectedEmail: [],
      debouncedFetch : "",
      filteredData: "",
      updateLoading: false,

       // trafficSourceItem を TRAFFIC_SOURCE の内容で設定
      trafficSourceItem: TRAFFIC_SOURCE,

      headers: [
        { text: "アクション", value: "actions", sortable: false },
        {
          text: "候補者ID",
          align: "start",
          value: "CandidateId",
        },
        { text: "名前", value: "Name" },
        { text: "ステータス", value: "Status" },
        { text: "店舗名", value: "Store.StoreName" },
        { text: "雇用形態", value: "EmploymentStatus" },
        { text: "職種", value: "Occupation" },
        { text: "応募時年齢", value: "Age" },
        { text: "リファラル", value: "Referral" },
        { text: "流入経路", value: "TrafficSource" },
        { text: "応募日時", value: "ApplyAt" },
        { text: "最終更新者", value: "UpdatedByName" },
        { text: "更新日時", value: "UpdatedAt" },
        { text: "削除", value: "deleteAction", sortable: false },
      ],
      statusItem: CANDIDATE_STATUS.statusSelect,
      statusListItem: CANDIDATE_STATUS.statusList,
      ageItem: {
        0: { value: null, text: "全年齢" },
        1: { value: 10, text: "10代" },
        2: { value: 20, text: "20代" },
        3: { value: 30, text: "30代" },
        4: { value: 40, text: "40代" },
        5: { value: 50, text: "50代" },
        6: { value: 60, text: "60代" },
        7: { value: 70, text: "70代" },
      },
      receivingMethodItem: {
        1: { value: 1, text: "WEB" },
        2: { value: 2, text: "電話" },
        3: { value: 3, text: "メール" },
        9: { value: 9, text: "その他" },
      },
      csvHeaderCandidates: [
        "候補者ID",
        "記事ID",
        "ステータス",
        "雇用形態",
        "職種",
        "店舗ID",
        "店舗名",
        "氏名",
        "かな",
        "メールアドレス",
        "電話番号",
        "生年月日",
        "年齢",
        "都道府県",
        "住所",
        "候補者備考",
        "紹介フラグ",
        "紹介者",
        "応募URL",
        "採用側メモ",
        "応募方法",
        "流入経路",
        "応募日時",
        "最終更新日時",
        "最終更新者",
      ],
    };
  },

  computed: {
    statusOptions() {
      return Object.values(this.statusItem);
    },
    ageOptions() {
      return Object.values(this.ageItem);
    },
  },
  watch: {
        'ageAtApplication'(ageVal) {
            this.filteredData = { ...this.filteredData, ageAtApplication: ageVal }
            this.$router.push({query: this.filteredData });
        },
        'employmentStatus'(employStatusVal) {
            this.filteredData = { ...this.filteredData, employmentStatus: employStatusVal }
            this.$router.push({query: this.filteredData });
            this.debouncedFetch();
        },
        'storeName'(storeNameVal) {
          this.filteredData = { ...this.filteredData,  storeName: storeNameVal }
          this.$router.push({query: this.filteredData });
        },
        'selectedStatus'(selectStatusVal) { 
          this.filteredData = { ...this.filteredData, selectedStatus: selectStatusVal }
          this.$router.push({query: this.filteredData });
        },
        'applyAtFrom'(applyFromVal) { 
          this.filteredData = { ...this.filteredData, applyAtFrom: applyFromVal }
          this.$router.push({query: this.filteredData });
        },
        'applyAtTo'(applyToVal){
          this.filteredData = { ...this.filteredData, applyAtTo: applyToVal }
          this.$router.push({query: this.filteredData });
        },
        'page'(newPage) {
          this.filteredData = { ...this.filteredData, page: newPage }
          this.$router.push({query: this.filteredData });
        },
  },
  methods: {
    clearApplyFrom() {
      this.applyAtFrom = "";
      this.menuFromIsOpen = false;
      this.loadCandidates();
    },
    clearApplyTo() {
      this.applyAtTo = "";
      this.menuToIsOpen = false;
      this.loadCandidates();
    },
    openDeleteDialog(candidateId, candidateName) {
      this.deleteDialog = true;
      this.deleteCandidateId = candidateId;
      this.deleteName = candidateName;
    },
    openEmailDialog() {
      if (this.selectedEmail.length == 0) {
        return;
      } else {
        this.emailDialog = true;
      }
    },
    closeEmailDialog() {
      this.emailDialog = false;
    },
    deleteCandidate() {
      this.deleteLoading = true;
      axios
        .delete("/candidates/" + this.deleteCandidateId)
        .then(() => {
          this.openSnackbarSuccess();
          this.loadCandidates();
        })
        .catch((e) => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          }
        })
        .finally(() => {
          this.deleteLoading = false;
          this.deleteDialog = false;
          this.deleteCandidateId = 0;
          this.deleteName = "";
        });
    },

    loadCandidates() {
      this.loading = true;
      // Construct the base URL
      let url = "/candidates?companyId=" + this.$route.params.companyId;

      // Append other parameters if they exist
      if (this.employmentStatus) {
        url += "&employmentStatus=" + this.employmentStatus;
      }

      if (this.ageAtApplication) {
        url += "&age=" + this.ageAtApplication;
      }

      if (this.applyAtFrom) {
        url += "&applyAtFrom=" + this.applyAtFrom;
      }

      if (this.applyAtTo) {
        url += "&applyAtTo=" + this.applyAtTo;
      }

      if (this.selectedStatus) {
        url += "&status=" + this.selectedStatus;
      }

      if (this.storeName) {
        url += "&storeName=" + encodeURIComponent(this.storeName);
      }
      axios
        .get(url)
        .then((res) => {
          this.candidates = res.data;
          this.page = this.$route.query.page ? Number(this.$route.query.page) : this.page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadStores() {
      axios
        .get("/stores?companyId=" + this.$route.params.companyId)
        .then((res) => {
          const store = res.data.map((store) => {
            return {
              value: store.StoreName, 
              text: store.StoreName
            }
          });
          this.stores = [{ value: null, text: "全店" }, ...store];
        })
    },
    async download() {
      const ret = await axios.get(
        "/candidates?companyId=" + this.$route.params.companyId
      );
      console.log(ret);
      const downloadData = ret.data.map((can) => {
        return [
          can.CandidateId,
          can.ArticleId,
          this.statusListItem[can.Status].text,
          can.EmploymentStatus,
          can.Occupation,
          can.StoreId,
          can.Store?.StoreName,
          can.Name,
          can.Kana,
          can.Email,
          can.Tel,
          dayjs(can.Birth).format("YYYY-MM-DD"),
          can.Age,
          can.Pref,
          can.City,
          can.Note,
          can.Referral,
          can.Referrer,
          can.ApplyUrl,
          can.Memo,
          this.receivingMethodItem[can.ReceivingMethod].text,
          this.trafficSourceItem[can.TrafficSource].text,
          dayjs(can.ApplyAt).format("YYYY-MM-DD HH:mm:ss"),
          dayjs(can.UpdatedAt).format("YYYY-MM-DD HH:mm:ss"),
          can.UpdatedByName,
        ];
      });
      const csv = {
        fileName: "candidates_",
        csvData: papa.unparse(
          {
            data: Array(this.csvHeaderCandidates).concat(downloadData),
          },
          {
            delimiter: "\t",
          }
        ),
      };
      const blob = new Blob([csv.csvData], { type: "text/tsv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
        csv.fileName +
        this.$route.params.companyId +
        "_" +
        dayjs().format("YYYYMMDDHHmmss") +
        ".tsv";
      link.click();
    },

    updateTrafficSource(candidateId, trafficSource) {
      // ローディング状態をtrueに設定
      this.updateLoading = true;
      axios.put(`/candidates/${candidateId}/update-traffic-source`, {
        "TrafficSource": trafficSource,
      })
      .then(() => {
        this.openSnackbarSuccess("流入経路が更新されました");
        this.loadCandidates();
      })
      .catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data);
        }
      })
      .finally(() => {
        // ローディング状態をfalseに設定
        this.updateLoading = false;
      });
    },


    ...mapActions("snackbar", [
      "openSnackbarSuccess",
      "openSnackbarApiError",
      "openSnackbarValidationError",
    ]),
  },
  mounted() {
    this.ageAtApplication = this.$route.query.ageAtApplication ? Number(this.$route.query.ageAtApplication) : 0;
    this.employmentStatus = this.$route.query.employmentStatus || '';
    this.storeName = this.$route.query.storeName || '';
    this.selectedStatus = this.$route.query.selectedStatus ? Number(this.$route.query.selectedStatus) : 0;
    this.applyAtFrom = this.$route.query.applyAtFrom || null;
    this.applyAtTo = this.$route.query.applyAtTo || null;

    this.loadCandidates();
  },
  created() {
    this.loadStores();
    this.debouncedFetch = debounce(this.loadCandidates, 500);
  },
};

</script>

<style scoped>

::v-deep .articles td {
  white-space: nowrap;
}

::v-deep .articles th {
  white-space: nowrap;
}

.pointer-cursor {
  cursor: pointer;
}
.custom-style >>> .v-date-picker-table {
  height: min-content;
}

.v-data-table::v-deep .text-start:nth-child(1) {
  padding-left: 24px;
}

.v-data-table::v-deep td,
.v-data-table::v-deep th {
  white-space: nowrap;
  font-size: 12px !important;
  text-align: center;
  vertical-align: middle;
}

::v-deep .statusText {
  font-size: 12px !important;
  color: #525f7f !important;
  text-align: left !important;
}
::v-deep .font-size-select {
  font-size: 14px !important;
}

.v-select {
  min-width: 120px;
  max-width: 200px;
}

.select-width {
  width: 500px !important;
  max-width: 100% !important;
}

.icon-width {
  min-width: 20px !important;
  max-width: 20px !important;
}

</style>
