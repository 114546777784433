<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                <span v-show="!isUpdate">アルムナイ記事作成</span>
                <span v-show="isUpdate">アルムナイ記事更新</span>
              </div>
            </div>

            <v-card-text class="card-padding">
              <v-row>
                <v-col cols="12" md="8">
                  <p class="font-size-root text-body font-weight-thin" v-show="!isUpdate">
                    アルムナイ記事を新規登録します。<br>
                  </p>
                  <p class="font-size-root text-body font-weight-thin" v-show="isUpdate">
                    アルムナイ記事を更新します。<br>
                  </p>
                </v-col>
              </v-row>
              <div class="border-bottom my-6"></div>

            <v-form class="mt-13" @submit.prevent>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      記事ID
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="ArticleId"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>表示タイトル</label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="例）<トリプルスリー武蔵小杉店> 店長候補 募集中！"
                      v-model="DisplayTitle"
                      :error-messages="displayTitleErrors"
                      required
                      :counter="255"
                      @input="$v.DisplayTitle.$touch()"
                      @blur="$v.DisplayTitle.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>ステータス</label>
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="Status"
                      :items="statusItem"
                      item-text="text"
                      item-value="value"
                      persistent-hint
                      :error-messages="statusErrors"
                      @input="$v.Status.$touch()"
                      @blur="$v.Status.$touch()"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12  mb-6" md="6" class="py-0">
                    <div>
                    <label
                      class="label-color font-weight-600 d-block"
                    ><span class="red--text">* </span>トップ画像名</label>
                     <v-input
                      :error-messages="imageUrlErrors"
                    >
                    <v-file-input
                      type="file"
                      outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="(isUpdate || isDuplicate) && TopImageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'topImageFile')"
                      @input="$v.TopImageFileUrl.$touch()"
                      @blur="$v.TopImageFileUrl.$touch()"
                     >
                    <template v-if="(isUpdate || isDuplicate) && TopImageFilename && TopImageFile.entries().next().done" #prepend-inner>
                      {{TopImageFilename}}
                    </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center">
                      <img :src="TopImageFileUrl" alt="Uploaded Image" v-if="TopImageFileUrl" class="image-styles" />
                    </div>
                  </v-col>
                  <v-col cols="12 mb-6" md="6" class="py-0">
                    <div>
                      <label
                      class="label-color font-weight-600 d-block"
                    >動画ID(YouTube) または 動画ファイル名
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>動画IDを指定した場合は、トップ画像とYouTube動画がページに表示され、<br>
                        動画ファイルを指定した場合は、トップ画像の代わりに動画が設定されます。<br>
                        動画ファイルはMP4形式のみ利用可能です。</span>
                      </v-tooltip>
                    </label>
                       <v-input>
                    <v-file-input 
                      outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="(isUpdate || isDuplicate) && MovieId ? '' :'YouTubeのMovieID または 動画ファイル名 を設定'"
                      accept="video/mp4,video/x-m4v,video/*"
                      hide-details
                      @change="(e) => uploadFile(e, 'VideoFile')"
                     >
                      <template v-if="(isUpdate || isDuplicate) && MovieId && VideoFile.entries().next().done" #prepend-inner>
                        {{MovieId}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div v-if="VideoFileUrl" :key="VideoFileUrl">
                       <video controls width="100%"  height="300px" class="video-styles">
                        <source :src="VideoFileUrl" type="video/mp4" />
                      </video>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                      雇用形態
                    </label>
                    <v-input
                      :error-messages="employmentStatusErrors"
                      class="mb-6"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='例）正社員'
                        v-model="EmploymentStatus"
                        @input="$v.EmploymentStatus.$touch()"
                        @blur="$v.EmploymentStatus.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>職種</label>
                    <v-input
                      :error-messages="occupationErrors"
                      class="mb-6"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='例）店長候補'
                        v-model="Occupation"
                        @input="$v.Occupation.$touch()"
                        @blur="$v.Occupation.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      <span class="red--text">* </span>検索用職種
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-on="on"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <span>
                          記事に掲載される職種とは別に記事検索時に用いる職種を指定します。<br/>
                          `02_` のように職種名の前に数字とアンダーバーをつけることで検索窓に表示する際の順序を定義できます。<br/>
                          これらの数字はシステムのみで使用され、ページには表示されません。
                        </span>
                      </v-tooltip>
                    </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）00_運営スタッフ'
                      v-model="OccupationForFilter"
                      :counter="50"
                      :error-messages="occupationForFilterErrors"
                      required
                      @input="$v.OccupationForFilter.$touch()"
                      @blur="$v.OccupationForFilter.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>仕事内容</label>
                    <v-input
                      class="mb-6"
                      :error-messages="jobDescriptionErrors"
                      >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='お店の運営全般'
                        v-model="JobDescription"
                        @input="$v.JobDescription.$touch()"
                        @blur="$v.JobDescription.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>給与</label>
                    <v-input
                      class="mb-6"
                      :error-messages="salaryErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='例）月収370,000円〜450,000円'
                        v-model="Salary"
                        @input="$v.Salary.$touch()"
                        @blur="$v.Salary.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>給与詳細</label>
                    <v-input
                      class="mb-6"
                      :error-messages="salaryDescriptionErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        :placeholder="`※経験・能力・前職給与を考慮し決定いたします\n※飲食業界が全くの未経験の方は月給22万円～`"
                        v-model="SalaryDescription"
                        @input="$v.SalaryDescription.$touch()"
                        @blur="$v.SalaryDescription.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>福利厚生</label>
                    <v-input
                      class="mb-6"
                      :error-messages="welfareProgrammeErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="○交通費全支給
○昇給あり"
                        v-model="WelfareProgramme"
                        @input="$v.WelfareProgramme.$touch()"
                        @blur="$v.WelfareProgramme.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>勤務時間</label>
                    <v-input
                      class="mb-6"
                      :error-messages="workHoursErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='例）15:00-24:00'
                        v-model="WorkHours"
                        @input="$v.WorkHours.$touch()"
                        @blur="$v.WorkHours.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>勤務詳細</label>
                    <v-input
                      class="mb-6"
                      :error-messages="workDetailsErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="※上記時間内で実働8～9時間シフト制、休憩あり
※終電考慮します"
                        v-model="WorkDetails"
                        @input="$v.WorkDetails.$touch()"
                        @blur="$v.WorkDetails.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      応募資格
                    </label>
                    <v-input
                      class="mb-6"
                      :error-messages="applicationRequirementsErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="資格不要
飲食店勤務経験不問"
                        v-model="ApplicationRequirements"
                        @input="$v.ApplicationRequirements.$touch()"
                        @blur="$v.ApplicationRequirements.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      歓迎要件
                    </label>
                    <v-input
                      class="mb-6"
                      :error-messages="welcomeRequirementsErrors"
                    >
                    <vue-editor 
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="○成長意欲がある方
○チャレンジ精神が旺盛な方"
                        v-model="WelcomeRequirements"
                        @input="$v.WelcomeRequirements.$touch()"
                        @blur="$v.WelcomeRequirements.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>タググループ</label>
                    <TagGroupSelector
                      ref="tagGroup"
                      v-model="TagGroup"
                      :error-messages="tagGroupErrors"
                      @change="$v.TagGroup.$touch()"
                      @blur="$v.TagGroup.$touch()"
                    >
                    </TagGroupSelector>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>店舗</label>
                    <StoreSelector
                      ref="store"
                      v-model="Store"
                      :error-messages="storeErrors"
                      @change="$v.Store.$touch()"
                      @blur="$v.Store.$touch()"
                    >
                    </StoreSelector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0 my-1">
                    <v-text-field
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      placeholder='どんな会社で・どんな仕事を'
                      v-model="WhatKindTitle"
                      :error-messages="whatKindTitleErrors"
                      required
                      @input="$v.WhatKindTitle.$touch()"
                      @blur="$v.WhatKindTitle.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0 my-1">
                    <v-text-field
                      type="number"
                      hint="段落並び順"
                      persistent-hint
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      v-model.number="WhatKindOrder"
                      :error-messages="whatKindOrderErrors"
                      @input="$v.WhatKindOrder.$touch()"
                      @blur="$v.WhatKindOrder.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="mt-0 py-0">
                    <div v-if="WhatKinds && WhatKinds.length > 0">
                      <v-card class="card-shadow mb-6 pt-6" v-for="(whatKind, key) in $v.WhatKinds.$each.$iter" :key="key">
                        <v-card-text class="card-padding">
                          <v-row>
                            <v-col cols="12" md="6" class="py-0">                            <div>
                                <label class="label-color font-weight-600 mb-2 d-block">
                                  <span class="red--text">* </span>画像名</label>
                                <v-input
                                  :error-messages="whatKindImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="(isUpdate || isDuplicate) && WhatKinds[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'whatKindImage', key)"
                                  @input="whatKind.WhatKindImageUrl.$touch()"
                                  @blur="whatKind.WhatKindImageUrl.$touch()"
                                >
                                <template v-if="(isUpdate || isDuplicate) && WhatKinds[key].ImageFilename &&  WhatKinds[key].WhatKindImage.entries().next().done" #prepend-inner>
                                  {{ WhatKinds[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" v-if="WhatKinds[key].WhatKindImageUrl">
                                  <img :src="WhatKinds[key].WhatKindImageUrl" alt="Uploaded Image" v-if="WhatKinds[key].WhatKindImageUrl" class="image-styles" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              >
                                並び順
                              </label>
                              <v-text-field
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                v-model.number="whatKind.OrderNum.$model"
                                :error-messages="whatKindOrderNumErrors(key)"
                                @input="whatKind.OrderNum.$touch()"
                                @blur="whatKind.OrderNum.$touch()"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>タイトル</label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）求める社員像・人物像とは？'
                                  v-model="whatKind.Title.$model"
                                  :error-messages="whatKindDetailTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="whatKind.Title.$touch()"
                                  @blur="whatKind.Title.$touch()"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              ><span class="red--text">* </span>内容</label>
                              <v-input
                                class="mb-6"
                                :error-messages="whatKindContentsErrors(key)"
                              >
                                <vue-editor
                                  outlined
                                  :editorToolbar="customToolbarDetail"
                                  class="black--text"
                                  placeholder="内容を入力"
                                  v-model="whatKind.Contents.$model"
                                  @input="whatKind.Contents.$touch()"
                                  @blur="whatKind.Contents.$touch()"
                                ></vue-editor>
                              </v-input>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-btn
                                fab
                                x-small
                                absolute
                                dark
                                right
                                top
                                color="red"
                                @click="deleteWhatKinds(key)"
                              >
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <v-btn
                      tile
                      outlined
                      class="mb-5"
                      color="green"
                      @click="addWhatKinds"
                    >
                      <v-icon left>
                        mdi-table-row-plus-after
                      </v-icon>
                      入力欄の追加
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0 my-1">
                    <v-text-field
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      placeholder='代表挨拶'
                      v-model="TopMessageHeaderTitle"
                      :error-messages="topMessageHeaderTitleErrors"
                      required
                      @input="$v.TopMessageHeaderTitle.$touch()"
                      @blur="$v.TopMessageHeaderTitle.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0 my-1">
                    <v-text-field
                      type="number"
                      hint="段落並び順"
                      persistent-hint
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      v-model.number="TopMessageOrder"
                      :error-messages="topMessageOrderErrors"
                      @input="$v.TopMessageOrder.$touch()"
                      @blur="$v.TopMessageOrder.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="mt-3 py-0">

                    <div>
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>代表挨拶 画像名</label>
                     <v-input
                      :error-messages="topMessageImageUrlErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="(isUpdate || isDuplicate) && TopMessageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'topMessage')"
                      @input="$v.TopMessageImageUrl.$touch()"
                      @blur="$v.TopMessageImageUrl.$touch()"
                     >
                      <template v-if="(isUpdate || isDuplicate) && TopMessageFilename && TopMessageImage.entries().next().done" #prepend-inner>
                        {{TopMessageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center mb-2">
                      <img :src="TopMessageImageUrl" alt="Uploaded Image" v-if="TopMessageImageUrl" class="image-styles" />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    > 代表挨拶 タイトル </label>
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder='例）MIND COME TRUE！'
                      v-model="TopMessageTitle"
                      :error-messages="topMessageTitleErrors"
                      :counter="255"
                      @input="$v.TopMessageTitle.$touch()"
                      @blur="$v.TopMessageTitle.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>代表挨拶 内容</label>
                    <v-input
                      class="mb-6"
                      :error-messages="topMessageContentsErrors"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="株式会社トリプルスリーはコンサルティング・システム開発・メディア制作を軸とした企業ソリューションを行う会社として2018年に設立致しました。
既成概念にとらわれず、型にはまらないソリューションで、お客様を成功に導くお手伝いを致します。"
                        v-model="TopMessageContents"
                        @input="$v.TopMessageContents.$touch()"
                        @blur="$v.TopMessageContents.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0 my-1">
                    <v-text-field
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      placeholder='新人・先輩へのインタビュー'
                      v-model="InterviewTitle"
                      :error-messages="interviewTitleErrors"
                      required
                      @input="$v.InterviewTitle.$touch()"
                      @blur="$v.InterviewTitle.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0 my-1">
                    <v-text-field
                      type="number"
                      hint="段落並び順"
                      persistent-hint
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      v-model.number="InterviewOrder"
                      :error-messages="interviewOrderErrors"
                      @input="$v.InterviewOrder.$touch()"
                      @blur="$v.InterviewOrder.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <div v-if="ArticleInterviews && ArticleInterviews.length > 0">
                      <v-card class="card-shadow mb-6 pt-6 " v-for="(articleInterview, key) in $v.ArticleInterviews.$each.$iter" :key="key">
                        <v-card-text class="card-padding">
                          <v-row>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              ><span class="red--text">* </span>インタビュー</label>
                              <InterviewSelector
                                ref="articleInterview" 
                                :error-messages="interviewErrors(key)"
                                v-model="articleInterview.Interview.$model"
                              >
                              </InterviewSelector>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              >並び順</label
                              >
                              <v-text-field
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                v-model.number="articleInterview.OrderNum.$model"
                                :error-messages="articleInterviewOrderNumErrors(key)"
                                @input="articleInterview.OrderNum.$touch()"
                                @blur="articleInterview.OrderNum.$touch()"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
        
                          <v-row>
                            <v-col>
                              <v-btn
                                fab
                                x-small
                                absolute
                                dark
                                right
                                top
                                color="red"
                                @click="deleteArticleInterviews(key)"
                              >
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <v-btn
                      tile
                      outlined
                      class="mb-5"
                      color="green"
                      @click="addArticleInterviews"
                    >
                      <v-icon left>
                        mdi-table-row-plus-after
                      </v-icon>
                      入力欄の追加
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0 my-1">
                    <v-text-field
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      placeholder='人気の秘訣'
                      v-model="KeyToPopularTitle"
                      :error-messages="keyToPopularTitleErrors"
                      required
                      @input="$v.KeyToPopularTitle.$touch()"
                      @blur="$v.KeyToPopularTitle.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="py-0 my-1">
                    <v-text-field
                      type="number"
                      hint="段落並び順"
                      persistent-hint
                      class="my-0 py-0 font-weight-600 font-size-input-sm"
                      v-model.number="KeyToPopularOrder"
                      :error-messages="keyToPopularOrderErrors"
                      @input="$v.KeyToPopularOrder.$touch()"
                      @blur="$v.KeyToPopularOrder.$touch()"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <div v-if="KeyToPopulars && KeyToPopulars.length > 0">
                      <v-card class="card-shadow mb-6 pt-6" v-for="(keyToPopulars, key) in $v.KeyToPopulars.$each.$iter" :key="key">
                        <v-card-text class="card-padding">
                          <v-row>
                            <v-col cols="12" md="6" class="py-0">
                              <div>

                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              ><span class="red--text">* </span>画像名</label>
                                <v-input
                                  :error-messages="keyToPopularsImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="(isUpdate || isDuplicate) && KeyToPopulars[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'keyPopularImage', key)"
                                  @input="keyToPopulars.KeyPopularImageUrl.$touch()"
                                  @blur="keyToPopulars.KeyPopularImageUrl.$touch()"
                                >
                                <template v-if="(isUpdate || isDuplicate) && KeyToPopulars[key].ImageFilename &&  KeyToPopulars[key].KeyPopularImage.entries().next().done" #prepend-inner>
                                  {{ KeyToPopulars[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" v-if="KeyToPopulars[key].KeyPopularImageUrl">
                                  <img :src="KeyToPopulars[key].KeyPopularImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                class="label-color font-weight-600 mb-2 d-block"
                              >並び順</label
                              >
                              <v-text-field
                                outlined
                                class="input-style font-size-input text-light-input placeholder-light"
                                type="number"
                                v-model.number="keyToPopulars.OrderNum.$model"
                                :error-messages="keyToPopularsOrderNumErrors(key)"
                                @input="keyToPopulars.OrderNum.$touch()"
                                @blur="keyToPopulars.OrderNum.$touch()"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="6" class="py-0">
                              <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>タイトル</label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）キャリアアップができる！'
                                  v-model="keyToPopulars.Title.$model"
                                  :error-messages="keyToPopularsDetailTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="keyToPopulars.Title.$touch()"
                                  @blur="keyToPopulars.Title.$touch()"
                                >
                                </v-text-field>
                              </div>
                                <div>
                                <label
                                class="label-color font-weight-600 mb-2 d-block"
                              ><span class="red--text">* </span>ヘッダー</label>
                              <v-text-field
                                outlined
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder='例）人気の秘密'
                                v-model="keyToPopulars.Header.$model"
                                :error-messages="keyToPopularsDetailHeaderErrors(key)"
                                required
                                :counter="255"
                                @input="keyToPopulars.Header.$touch()"
                                @blur="keyToPopulars.Header.$touch()"
                              >
                              </v-text-field>
                              </div>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                              <label
                                  class="label-color font-weight-600 mb-2 d-block"
                              ><span class="red--text">* </span>内容</label
                              >
                              <v-input
                                class="mb-2"
                                :error-messages="keyToPopularsContentErrors(key)"
                              >
                                <vue-editor
                                  outlined
                                  :editorToolbar="customToolbarDetail"
                                  class="black--text"
                                  placeholder="内容を入力"
                                  v-model="keyToPopulars.Content.$model"
                                  @input="keyToPopulars.Content.$touch()"
                                  @blur="keyToPopulars.Content.$touch()"
                                ></vue-editor>
                              </v-input>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-btn
                                fab
                                x-small
                                absolute
                                dark
                                right
                                top
                                color="red"
                                @click="deleteKeyToPopulars(key)"
                              >
                                <v-icon>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <v-btn
                      tile
                      outlined
                      class="mb-5"
                      color="green"
                      @click="addKeyToPopulars"
                    >
                      <v-icon left>
                        mdi-table-row-plus-after
                      </v-icon>
                      入力欄の追加
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>連絡先</label>
                    <ContactSelector
                      ref="contact"
                      v-model="Contact"
                      :error-messages="contactErrors"
                      @change="$v.Contact.$touch()"
                      @blur="$v.Contact.$touch()"
                    >
                    </ContactSelector>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0" v-if="$route.params.articleId">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      コメント
                    </label>
                    <v-input
                      :error-messages="commentsErrors"
                      class="mb-6"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder='例）正社員'
                        v-model="ArticleComment"
                        @input="$v.ArticleComment.$touch()"
                        @blur="$v.ArticleComment.$touch()"
                      ></vue-editor>
                    </v-input>
                  </v-col>

                  
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text"> </span>電話で応募ボタン</label>
                    <v-checkbox
                      outlined
                      class="font-size-input text-light-input placeholder-light"
                      v-model="TelHiddenFlg"
                      label="非表示にする"
                    >

                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-show="isUpdate">
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                      最終更新日
                    </label>
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn v-show="!isUpdate"
                    @click="submitCreate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >作成</v-btn>
                  <v-btn v-show="isUpdate"
                    @click="submitUpdate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                    color="green"
                    style="z-index: 50; bottom: 120px; right: 40px;"
                    :loading="loading"
                    large
                    fixed
                    bottom
                  >更新</v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { VueEditor } from "vue2-editor";
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import axios from "axios";
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, numeric, email } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import StoreSelector from "../../../components/Selectors/StoreSelector";
import ContactSelector from "../../../components/Selectors/ContactSelector";
import InterviewSelector from "../../../components/Selectors/InterviewSelector";
import TagGroupSelector from "../../../components/Selectors/TagGroupSelector";
import Overlay from '../../../components/Overlay.vue';
import { getLength } from "@/util/maxLengthValidation"
import CUSTOM_TOOLBAR from "@/const/richTextToolsBar.js";

Vue.use(VueEditor);

export default {
  name: "ArticleDetail",
  components: {
    StoreSelector,
    ContactSelector,
    TagGroupSelector,
    InterviewSelector,
    HeaderTopDashboard,
    Overlay,
    VueEditor,
  },
  data() {
    return {
      statusItem: [
        { value: 1, text: "未公開" },
        { value: 2, text: "公開中"},
        { value: 3, text: "公開停止"},
      ],
      tagItem: [
        { TagKey: "都道府県記載", TagName: "都道府県記載", TagColor: "bac-c-sub" },
        { TagKey: "市町村記載", TagName: "市町村記載", TagColor: "bac-c-sub" },
        { TagKey: "店長", TagName: "店長", TagColor: "bac-c-main" },
        { TagKey: "フルタイム", TagName: "フルタイム", TagColor: "bac-c-sub2" },
        { TagKey: "社会保険完備", TagName: "社会保険完備", TagColor: "bac-c-sub2" },
        { TagKey: "制服貸与", TagName: "制服貸与", TagColor: "bac-c-sub2" },
        { TagKey: "賞与昇給あり", TagName: "賞与昇給あり", TagColor: "bac-c-sub2" },
        { TagKey: "交通費支給", TagName: "交通費支給", TagColor: "bac-c-sub2" },
        { TagKey: "産休・育休あり", TagName: "産休・育休あり", TagColor: "bac-c-sub2" },
        { TagKey: "食事補助あり", TagName: "食事補助あり", TagColor: "bac-c-sub2" },
        { TagKey: "独立支援あり", TagName: "独立支援あり", TagColor: "bac-c-sub2" },
        { TagKey: "第二新卒歓迎", TagName: "第二新卒歓迎", TagColor: "bac-c-sub2" },
        { TagKey: "無資格者歓迎", TagName: "無資格者歓迎", TagColor: "bac-c-sub2" },
        { TagKey: "家族休暇", TagName: "家族休暇", TagColor: "bac-c-sub2" },
        { TagKey: "新店", TagName: "新店", TagColor: "bac-c-sub2" },
        { TagKey: "オープニング", TagName: "オープニング", TagColor: "bac-c-sub2" },
        { TagKey: "シフト制", TagName: "シフト制", TagColor: "bac-c-sub2" },
        { TagKey: "経験者優遇", TagName: "経験者優遇", TagColor: "bac-c-sub2" },
        { TagKey: "新卒歓迎", TagName: "新卒歓迎", TagColor: "bac-c-sub2" },
        { TagKey: "資格が活かせる", TagName: "資格が活かせる", TagColor: "bac-c-sub2" },
        { TagKey: "調理スタッフ", TagName: "調理スタッフ", TagColor: "bac-c-sub2" },
        { TagKey: "ホールスタッフ", TagName: "ホールスタッフ", TagColor: "bac-c-sub2" },
        { TagKey: "店長候補", TagName: "店長候補", TagColor: "bac-c-sub2" },
        { TagKey: "技術が身に付く", TagName: "技術が身に付く", TagColor: "bac-c-sub2" },
        { TagKey: "在宅手当あり", TagName: "在宅手当あり", TagColor: "bac-c-sub2" },
        { TagKey: "夏期　冬季　休暇制度あり", TagName: "夏期　冬季　休暇制度あり", TagColor: "bac-c-sub2" },
      ],

      customToolbarDetail: CUSTOM_TOOLBAR.info,

      CompanyId: "",
      ArticleId: "",
      MovieId: "", //
      VideoFileUrl : "",
      VideoFile : new FormData(),
      DisplayTitle: "",
      imageUrl : "",
      Status: 1,
      TopImageFilename: "", //
      TopImageFileUrl : "",
      TopImageFile :  new FormData(),
      EmploymentStatus: "",
      ArticleComment: "",
      Occupation: "",
      OccupationForFilter: "",
      JobDescription: "",
      Salary: "",
      SalaryDescription: "",
      WelfareProgramme: "",
      WorkHours: "",
      WorkDetails: "",
      ApplicationRequirements: "",
      WelcomeRequirements: "",
      ArticleTags: [{
        TagKey: "",
        TagName: "",
        TagColor: "",
      }],
      Store: null,
      Contact: null,
      TagGroup: null,
      WhatKindTitle: "どんな会社で・どんな仕事を",
      WhatKindOrder: 1,
      WhatKinds: [{
        WhatKindsId: null,
        ArticleId: null,
        OrderNum: 1,
        ImageFilename: "", //
        WhatKindImage :  new FormData(),
        WhatKindImageUrl : "",
        Title: "",
        Contents: "",
      }],
      TopMessageHeaderTitle: "代表挨拶",
      TopMessageFilename: "", //
      TopMessageImage :  new FormData(),
      TopMessageImageUrl : "",
      WhatKindImageUrl : "",
      TopMessageTitle: "",
      TopMessageContents: "",
      TopMessageOrder: 2,
      ArticleInterviews: [{
        OrderNum: 1,
        ArticleInterviewId: "",
        InterviewId: "",    
        ArticleId: "",        
        Interview: [{
          InterviewId: "",
          Titile: "",
        }],
      }],
      Interviews: [{
        OrderNum: 1,
        ImageFilename: "", //
        InterviewImage: new FormData(),
        InterviewImageUrl : "",
        Title: "",
        QA: [{
          OrderNum: 1,
          Question: "",
          Answer: "",
        }],
      }],
      InterviewTitle: "新人・先輩へのインタビュー",
      InterviewOrder: 3,
      KeyToPopulars: [{
        OrderNum: 1,
        ImageFilename: "", //
        KeyPopularImage :  new FormData(),
        KeyPopularImageUrl : "",
        Header: "",
        Title: "",
        Content: "",
      }],
      KeyToPopularTitle: "会社の強み・入社のメリット・人気の秘訣",
      KeyToPopularOrder: 4,

      TelHiddenFlg: 0,
      Pref: "",
      City: "",

      RecommendArticles: [{
        RecommendArticleId: null,
        OrderNum: null,
      }],

      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
      isDuplicate : false,
    }
  },
  mixins: [validationMixin],
  methods: {
     uploadFile(file, type, key) {
      if (file && type == "topImageFile") {
          const topImageFileUrl = URL.createObjectURL(file);
          this.TopImageFileUrl = topImageFileUrl;
          this.TopImageFilename = file.name;
          this.TopImageFile = new FormData();
          this.TopImageFile.append('TopImageFile', file);
          }
      else if(!file && type == "topImageFile"){
          this.TopImageFileUrl = "";
          this.TopImageFilename = "",
          this.TopImageFile = "";
      }
      else if (file && type == "VideoFile") {
          const videoFileUrl = URL.createObjectURL(file);
          this.VideoFileUrl = videoFileUrl;
          this.MovieId = file.name;
          this.VideoFile = new FormData();
          this.VideoFile.append('VideoFile', file);
          }
      else if(!file && type == "VideoFile"){
          this.MovieId = "";
          this.VideoFileUrl = "";
          this.VideoFile = "";
      }
      else if (file && type == "whatKindImage") {
          const whatKindImageUrl = URL.createObjectURL(file);
          this.WhatKinds[key].ImageFilename = file.name;
          this.WhatKinds[key].WhatKindImage = new FormData();
          this.WhatKinds[key].WhatKindImage.append('WhatKindImage', file);
          this.WhatKinds[key].WhatKindImageUrl = whatKindImageUrl;
      }
      else if(!file && type == "whatKindImage"){
          this.WhatKinds[key].ImageFilename = "";
          this.WhatKinds[key].WhatKindImage = "";
          this.WhatKinds[key].WhatKindImageUrl = "";
      }
      else if (file && type == "topMessage") {
          this.TopMessageImageUrl = URL.createObjectURL(file);
          this.TopMessageFilename = file.name;
          this.TopMessageImage = new FormData();
          this.TopMessageImage.append('TopMessageImage', file);
          }
      else if(!file && type == "topMessage"){
          this.TopMessageFilename = "";
          this.TopMessageImageUrl = "";
          this.TopMessageImage = "";
      }
      else if (file && type == "interviewImage") {
          const interviewImageUrl = URL.createObjectURL(file);
          this.Interviews[key].ImageFilename = file.name;
          this.Interviews[key].InterviewImage = new FormData(); // Reset formData
          this.Interviews[key].InterviewImage.append('InterviewImage', file);
          this.Interviews[key].InterviewImageUrl = interviewImageUrl;
        }
      else if(!file && type == "interviewImage"){
          this.Interviews[key].ImageFilename = "";
          this.Interviews[key].InterviewImage = "";
          this.Interviews[key].InterviewImageUrl = "";
      }
      else if (file && type == "keyPopularImage") {
          const keyPopularImageUrl = URL.createObjectURL(file);
          this.KeyToPopulars[key].ImageFilename = file.name;
          this.KeyToPopulars[key].KeyPopularImage = new FormData(); // Reset formData
          this.KeyToPopulars[key].KeyPopularImage.append('KeyPopularImage', file);
          this.KeyToPopulars[key].KeyPopularImageUrl = keyPopularImageUrl;
        }
      else if(!file && type == "keyPopularImage"){
          this.KeyToPopulars[key].ImageFilename = "";
          this.KeyToPopulars[key].KeyPopularImage = "";
          this.KeyToPopulars[key].KeyPopularImageUrl = "";
      }
    },
    enable: function() {
      this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, true);
    },
    disable: function() {
      this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, false);
    },
    addWhatKinds() {
      this.WhatKinds.push(
          {
            OrderNum: this.WhatKinds.length + 1,
            ImageFilename : "",
            WhatKindImage :  new FormData(),
            WhatKindImageUrl : "",
            Title: "",
            Contents: "",
          }
      )
    },
    deleteWhatKinds(key) {
      this.WhatKinds.splice(key, 1)
    },
    addArticleInterviews() {
      const maxOrderNum = this.ArticleInterviews.length > 0 
        ? Math.max(...this.ArticleInterviews.map(interview => interview.OrderNum)) 
        : 0; // 既存のOrderNumの最大値を取得
      this.ArticleInterviews.push(
        {
          OrderNum: maxOrderNum + 1, // 最大値に1を加算
          ArticleInterviewId: "",
          InterviewId: "",    
          ArticleId: "",        
          Interview: [{
            InterviewId: "",
            Titile: "",
          }],
        }
      )
    },
    deleteArticleInterviews(key) {
      this.ArticleInterviews.splice(key, 1)
    },
    addInterviews() {
      this.Interviews.push(
          {
            OrderNum: this.Interviews.length + 1,
            ImageFilename : "",
            InterviewImage : new FormData(),
            InterviewImageUrl : "",
            Title: "",
            QA: [{
              OrderNum: 1,
              Question: "",
              Answer: "",
            }],
          }
      )
    },
    deleteInterviews(key) {
      this.Interviews.splice(key, 1)
    },
    addQa(key) {
      this.Interviews[key].QA.push({
        OrderNum: this.Interviews[key].QA.length + 1,
        Question: "",
        Answer: "",
      });
    },
    deleteQa(key, qKey) {
      this.Interviews[key].QA.splice(qKey, 1);
    },
    addKeyToPopulars() {
      this.KeyToPopulars.push({
        OrderNum: this.KeyToPopulars.length + 1,
        ImageFilename : "",
        KeyPopularImage : new FormData(),
        KeyPopularImageUrl : "",
        Header: "",
        Title: "",
        Content: "",
      });
    },
    deleteKeyToPopulars(key) {
      this.KeyToPopulars.splice(key, 1);
    },
    async submitCreate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$vuetify.goTo(0);
        this.openSnackbarError("入力エラーがあります。入力内容を確認してください。");
        return;
      }
      await this.uploadMedia();
      this.loading = true;
      axios.post("/articles?articleType=2", {
        MovieId: this.MovieId, //
        DisplayTitle: this.DisplayTitle,
        TopImageFilename : this.TopImageFilename, //
        Status: this.Status,
        EmploymentStatus: this.EmploymentStatus,
        Occupation: this.Occupation,
        OccupationForFilter: this.OccupationForFilter,
        JobDescription: this.JobDescription,
        Salary: this.Salary,
        SalaryDescription: this.SalaryDescription,
        WelfareProgramme: this.WelfareProgramme,
        WorkHours: this.WorkHours,
        WorkDetails: this.WorkDetails,
        ApplicationRequirements: this.ApplicationRequirements,
        WelcomeRequirements: this.WelcomeRequirements,
        ArticleTags: this.ArticleTags,
        StoreId: this.Store?.StoreId,
        ContactId: this.Contact?.ContactId,
        TagGroupId: this.TagGroup?.TagGroupId,
        WhatKindTitle: this.WhatKindTitle,
        WhatKindOrder: this.WhatKindOrder,
        WhatKinds: this.WhatKinds.map((whatKind) => {
          return {
              WhatKindsId: whatKind.WhatKindsId,
              ArticleId: whatKind.ArticleId,
              OrderNum: whatKind.OrderNum,
              ImageFilename : whatKind.ImageFilename,
              Title: whatKind.Title,
              Contents: whatKind.Contents
          }
        }),
        TopMessageHeaderTitle: this.TopMessageHeaderTitle,
        TopMessageFilename: this.TopMessageFilename,
        TopMessageTitle: this.TopMessageTitle,
        TopMessageContents: this.TopMessageContents,
        TopMessageOrder: this.TopMessageOrder,
        ArticleInterviews: this.ArticleInterviews.map((articleInterview) => {
          return {
            OrderNum: articleInterview.OrderNum,
            ArticleInterviewId: parseInt(articleInterview.ArticleInterviewId),
            InterviewId: parseInt(articleInterview.Interview.InterviewId),    
            ArticleId: parseInt(articleInterview.ArticleId),        
          }
        }),
        InterviewTitle: this.InterviewTitle,
        InterviewOrder: this.InterviewOrder,
        KeyToPopulars: this.KeyToPopulars.map((keyToPopular) => {
          return {
              OrderNum: keyToPopular.OrderNum,
              ImageFilename : keyToPopular.ImageFilename,
              Header: keyToPopular.Header,
              Title: keyToPopular.Title,
              Content: keyToPopular.Content,
          }
        }),
        KeyToPopularTitle: this.KeyToPopularTitle,
        KeyToPopularOrder: this.KeyToPopularOrder,
        TelHiddenFlg: this.TelHiddenFlg ? 1 : 0,
        Pref: this.Pref,
        City: this.City,
        ArticleType: 2,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: 'AlumniArticles' });
      }).catch(e => {
        const res = e.response;
        if (res && res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    async submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$vuetify.goTo(0);
        this.openSnackbarError("入力エラーがあります。入力内容を確認してください。");
        return;
      }
      this.loading = true;
      await this.uploadMedia();
      axios.put("/articles/" + this.ArticleId + "?articleType=2", {
        ArticleId: this.ArticleId,
        MovieId: this.MovieId, //
        DisplayTitle: this.DisplayTitle,
        TopImageFilename : this.TopImageFilename, //
        Status: this.Status,
        EmploymentStatus: this.EmploymentStatus,
        ArticleComment: this.ArticleComment,
        Occupation: this.Occupation,
        OccupationForFilter: this.OccupationForFilter,
        JobDescription: this.JobDescription,
        Salary: this.Salary,
        SalaryDescription: this.SalaryDescription,
        WelfareProgramme: this.WelfareProgramme,
        WorkHours: this.WorkHours,
        WorkDetails: this.WorkDetails,
        ApplicationRequirements: this.ApplicationRequirements,
        WelcomeRequirements: this.WelcomeRequirements,
        ArticleTags: this.ArticleTags,
        StoreId: this.Store?.StoreId,
        ContactId: this.Contact?.ContactId,
        TagGroupId: this.TagGroup?.TagGroupId,
        WhatKindTitle: this.WhatKindTitle,
        WhatKindOrder: this.WhatKindOrder,
        WhatKinds: this.WhatKinds.map((whatKind) => {
          return {
              WhatKindsId: whatKind.WhatKindsId,
              ArticleId: whatKind.ArticleId,
              OrderNum: whatKind.OrderNum,
              ImageFilename : whatKind.ImageFilename,
              Title: whatKind.Title,
              Contents: whatKind.Contents
          }
        }),
        TopMessageHeaderTitle: this.TopMessageHeaderTitle,
        TopMessageFilename: this.TopMessageFilename, //
        TopMessageTitle: this.TopMessageTitle,
        TopMessageContents: this.TopMessageContents,
        TopMessageOrder: this.TopMessageOrder,
        ArticleInterviews: this.ArticleInterviews.map((articleInterview) => {
          return {
            OrderNum: articleInterview.OrderNum,
            ArticleInterviewId: parseInt(articleInterview.ArticleInterviewId),
            InterviewId: parseInt(articleInterview.Interview.InterviewId),    
            ArticleId: parseInt(articleInterview.ArticleId),        
          }
        }),
        InterviewTitle: this.InterviewTitle,
        InterviewOrder: this.InterviewOrder,
        KeyToPopulars: this.KeyToPopulars.map((keyToPopular) => {
          return {
              OrderNum: keyToPopular.OrderNum,
              ImageFilename : keyToPopular.ImageFilename,
              Header: keyToPopular.Header,
              Title: keyToPopular.Title,
              Content: keyToPopular.Content,
          }
        }),
        KeyToPopularTitle: this.KeyToPopularTitle,
        KeyToPopularOrder: this.KeyToPopularOrder,
        TelHiddenFlg: this.TelHiddenFlg ? 1 : 0,
        Pref: this.Pref,
        City: this.City,
        UpdatedBy: 2,
        ArticleType: 2,
      }).then(() => {
        this.openSnackbarSuccess();
        this.$router.push({ name: 'AlumniArticles' });
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.loading = false;
      });
    },
     async uploadMedia() {
      try{
        if(this.WhatKinds && this.WhatKinds.length > 0){
          var combinedWhatKindsImages = new FormData();
          this.WhatKinds.forEach((whatKind) => {
            if(whatKind.WhatKindImage){
              const fileValue =  whatKind.WhatKindImage.get("WhatKindImage");
              combinedWhatKindsImages.append("WhatKindImage", fileValue);
            }
          });
        }
        if(this.Interviews && this.Interviews.length > 0){
          var combinedInterviewImages = new FormData();
          this.Interviews.forEach((interview) => {
              if(interview.InterviewImage){
                const fileValue =  interview.InterviewImage.get("InterviewImage");
                combinedInterviewImages.append("InterviewImage", fileValue);
              }
          });
        }
        if(this.KeyToPopulars && this.KeyToPopulars.length > 0){
          var combinedKeyToPopulars = new FormData();
          this.KeyToPopulars.forEach((keyToPopular) => {
              if(keyToPopular.KeyPopularImage){
                const fileValue =  keyToPopular.KeyPopularImage.get("KeyPopularImage");
                combinedKeyToPopulars.append("KeyPopularImage", fileValue);
              }
          });
        }
        const allAllMediaData = new FormData();
        allAllMediaData.append("CompanyId", this.CompanyId);
        allAllMediaData.append("CompanyDomain", this.CompanyDomain);
        if(this.VideoFile.get("VideoFile")){
          allAllMediaData.append("VideoFile", this.VideoFile.get("VideoFile"));
        }
        if(this.TopImageFile.get("TopImageFile")){
          allAllMediaData.append("TopImageFile", this.TopImageFile.get("TopImageFile"));
        }
        if(this.TopMessageImage.get("TopMessageImage")){
          allAllMediaData.append("TopMessageImage", this.TopMessageImage.get("TopMessageImage"));
        }
        if(this.WhatKinds && this.WhatKinds.length > 0){
          for (var pair of combinedWhatKindsImages.entries()) {
            allAllMediaData.append(pair[0], pair[1]);
          }
        }
        if(this.Interviews && this.Interviews.length > 0){
          for (var pair of combinedInterviewImages.entries()) {
            allAllMediaData.append(pair[0], pair[1]);
          }
        }
        if(this.KeyToPopulars && this.KeyToPopulars.length > 0){
          for (var pair of combinedKeyToPopulars.entries()) {
            allAllMediaData.append(pair[0], pair[1]);
          }
        }
        await axios.post("/media/upload" , allAllMediaData);
      }
      catch(e){
        console.log(e);
      }
      finally{}
    },
    whatKindOrderNumErrors(key) {
      const errors = [];
      if (!this.$v.WhatKinds.$each[key].OrderNum.$dirty) return errors;
      !this.$v.WhatKinds.$each[key].OrderNum.numeric && errors.push("数値を入力してください");
      return errors;
    },
    whatKindImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.WhatKinds.$each[key].WhatKindImageUrl.$dirty) return errors;
      !this.$v.WhatKinds.$each[key].WhatKindImageUrl.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    whatKindDetailTitleErrors(key) {
      const errors = [];
      if (!this.$v.WhatKinds.$each[key].Title.$dirty) return errors;
      !this.$v.WhatKinds.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.WhatKinds.$each[key].Title.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    whatKindContentsErrors(key) {
      const errors = [];
      if (!this.$v.WhatKinds.$each[key].Contents.$dirty) return errors;
      const content = this.$v.WhatKinds.$each[key].Contents.$model;
      const length = getLength(content);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.WhatKinds.$each[key].Contents.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    interviewImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.Interviews.$each[key].InterviewImageUrl.$dirty) return errors;
      !this.$v.Interviews.$each[key].InterviewImageUrl.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    interviewDetailTitleErrors(key) {
      const errors = [];
      if (!this.$v.Interviews.$each[key].Title.$dirty) return errors;
      !this.$v.Interviews.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.Interviews.$each[key].Title.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    articleInterviewOrderNumErrors(key){
      const errors = [];
      if (!this.$v.ArticleInterviews.$each[key].OrderNum.$dirty) return errors;
      !this.$v.ArticleInterviews.$each[key].OrderNum.numeric && errors.push("数値を入力してください");
      return errors;
    },
    interviewErrors(key){
      const errors = [];
      if (!this.$v.ArticleInterviews.$each[key].Interview.$dirty) return errors;
      !this.$v.ArticleInterviews.$each[key].Interview.required && errors.push("入力必須項目です");
      return errors;
    },
    interviewOrderNumErrors(key) {
      const errors = [];
      if (!this.$v.Interviews.$each[key].OrderNum.$dirty) return errors;
      !this.$v.Interviews.$each[key].OrderNum.numeric && errors.push("数値を入力してください");
      return errors;
    },
    keyToPopularsOrderNumErrors(key) {
      const errors = [];
      if (!this.$v.KeyToPopulars.$each[key].OrderNum.$dirty) return errors;
      !this.$v.KeyToPopulars.$each[key].OrderNum.numeric && errors.push("数値を入力してください");
      return errors;
    },
    keyToPopularsImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.KeyToPopulars.$each[key].KeyPopularImageUrl.$dirty) return errors;
      !this.$v.KeyToPopulars.$each[key].KeyPopularImageUrl.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    keyToPopularsDetailTitleErrors(key) {
      const errors = [];
      if (!this.$v.KeyToPopulars.$each[key].Title.$dirty) return errors;
      !this.$v.KeyToPopulars.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.KeyToPopulars.$each[key].Title.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    keyToPopularsDetailHeaderErrors(key) {
      const errors = [];
      if (!this.$v.KeyToPopulars.$each[key].Header.$dirty) return errors;
      !this.$v.KeyToPopulars.$each[key].Header.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.KeyToPopulars.$each[key].Header.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    keyToPopularsContentErrors(key) {
      const errors = [];
      if (!this.$v.KeyToPopulars.$each[key].Content.$dirty) return errors;
      const content = this.$v.KeyToPopulars.$each[key].Content.$model;
      const length = getLength(content);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.KeyToPopulars.$each[key].Content.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    isOpen() {
      return this.Status === 2 || this.Status === 3;
    },
    async getArticle(articleId) {
      try {
        const res = await axios.get("/articles/" + articleId + "?articleType=2");
        const s3BaseUrl = process.env.NODE_ENV === "production" ? 
          `https://${res.data.Store.Company.Domain}.tt-recruit.com/img/` :
          `${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Store.Company.Domain}/img/`;
        this.CompanyId = this.$route.params.companyId;
        this.CompanyDomain = res.data.Store.Company.Domain;
        this.ArticleId = res.data.ArticleId; 
        this.MovieId =  res.data.MovieId;
        this.VideoFileUrl = s3BaseUrl + res.data.MovieId;
        this.UpdatedByName = res.data.UpdatedByName;
        this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
        this.DisplayTitle = res.data.DisplayTitle;
        this.Status = res.data.Status;
        this.TopImageFilename = res.data.TopImageFilename;
        this.TopImageFileUrl = s3BaseUrl + res.data.TopImageFilename;
        this.EmploymentStatus = res.data.EmploymentStatus;
        this.ArticleComment = res.data.ArticleComment ?? "";
        this.Occupation = res.data.Occupation;
        this.OccupationForFilter = res.data.OccupationForFilter;
        this.JobDescription = res.data.JobDescription;
        this.Salary = res.data.Salary;
        this.SalaryDescription = res.data.SalaryDescription;
        this.WelfareProgramme = res.data.WelfareProgramme;
        this.WorkHours = res.data.WorkHours;
        this.WorkDetails = res.data.WorkDetails;
        this.ApplicationRequirements = res.data.ApplicationRequirements;
        this.WelcomeRequirements = res.data.WelcomeRequirements;
        this.ArticleTags = res.data.ArticleTags;
        this.StoreId = res.data.Store.StoreId;
        this.ContactId = res.data.ContactId;
        this.TagGroupId = res.data.TagGroupId;
        const promiseStore = this.$refs.store.setInitialValue(res.data.Store.StoreId);
        const promiseContact = this.$refs.contact.setInitialValue(res.data.ContactId);
        const promiseTagGroup = this.$refs.tagGroup.setInitialValue(res.data.TagGroupId);
        await Promise.all([promiseStore, promiseContact, promiseTagGroup]);
        this.WhatKindTitle = res.data.WhatKindTitle;
        this.WhatKindOrder = res.data.WhatKindOrder;
        this.WhatKinds = (res.data.WhatKinds && res.data.WhatKinds.length > 0) ?
        res.data.WhatKinds.map((whatKind) => {
          return {
              WhatKindsId: whatKind.WhatKindsId,
              ArticleId: whatKind.ArticleId,
              OrderNum: whatKind.OrderNum,
              ImageFilename : whatKind.ImageFilename,
              WhatKindImage : new FormData(),
              WhatKindImageUrl : s3BaseUrl + whatKind.ImageFilename,
              Title: whatKind.Title,
              Contents: whatKind.Contents
          }
        }) : [];
        this.TopMessageHeaderTitle = res.data.TopMessageHeaderTitle;
        this.TopMessageFilename = res.data.TopMessageFilename;
        this.TopMessageImageUrl = s3BaseUrl + res.data.TopMessageFilename;
        this.TopMessageTitle = res.data.TopMessageTitle;
        this.TopMessageContents = res.data.TopMessageContents;
        this.TopMessageOrder = res.data.TopMessageOrder;
        this.ArticleInterviews = (res.data.ArticleInterviews && res.data.ArticleInterviews.length > 0) ? 
        res.data.ArticleInterviews.map((articleInterview) => {
          return {
            OrderNum: articleInterview.OrderNum,
            ArticleInterviewId: parseInt(articleInterview.ArticleInterviewId),
            InterviewId: parseInt(articleInterview.InterviewId),    
            ArticleId: parseInt(articleInterview.ArticleId),        
            Interview: articleInterview.Interview,
          }
        }) : [];
        await this.$nextTick();
        const numArticleInterviewIdSelect = this.$refs.articleInterview.length
        let countArticleInterviewIdSelect = 0;
        this.ArticleInterviews.map((articleInterview, key) => {
            if (numArticleInterviewIdSelect > countArticleInterviewIdSelect) {
              Promise.all([this.$refs.articleInterview[countArticleInterviewIdSelect].setInitialValue(articleInterview.InterviewId)]);
              countArticleInterviewIdSelect = countArticleInterviewIdSelect + 1;
            }
        });
        this.InterviewTitle = res.data.InterviewTitle;
        this.InterviewOrder = res.data.InterviewOrder;
        this.KeyToPopulars = (res.data.KeyToPopulars && res.data.KeyToPopulars.length > 0) ?
        res.data.KeyToPopulars.map((keyToPopular) => {
          return {
              OrderNum: keyToPopular.OrderNum,
              ImageFilename : keyToPopular.ImageFilename,
              KeyPopularImage :  new FormData(),
              KeyPopularImageUrl : s3BaseUrl + keyToPopular.ImageFilename,
              Header: keyToPopular.Header,
              Title: keyToPopular.Title,
              Content: keyToPopular.Content,
          }
        }) : [];
        this.KeyToPopularTitle = res.data.KeyToPopularTitle;
        this.KeyToPopularOrder = res.data.KeyToPopularOrder;
        this.TelHiddenFlg = res.data.TelHiddenFlg;
        this.Pref = res.data.Pref;
        this.City = res.data.City;
        
      } catch (e) {
        console.log(e);
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        } else {
          console.log(e.response)
          this.openSnackbarApiError();
        }
      } finally {
        this.getLoading = false;
      }
    },
    ...mapActions("snackbar", ["openSnackbarError", "openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations() {
    if (this.Status === 2 || this.Status === 3) {
      return {
        DisplayTitle: {required, maxLength: maxLength(255)},
        Status: {required, numeric},
        TopImageFileUrl:  {required},
        EmploymentStatus: {required},
        ArticleComment: {},
        Occupation: {required},
        OccupationForFilter: {required, maxLength: maxLength(50)},
        JobDescription: {required},
        Salary: {required},
        SalaryDescription: {required},
        WelfareProgramme: {required},
        WorkHours: {required},
        WorkDetails: {required},
        ApplicationRequirements: {},
        WelcomeRequirements: {},
        Store: {required},
        Contact: {required},
        TagGroup: {required},
        WhatKindTitle: {required, maxLength: maxLength(255)},
        WhatKindOrder: {required, numeric},
        WhatKinds: {
          $each: {
            OrderNum: {numeric},
            WhatKindImageUrl :  {required},
            Title: {required, maxLength: maxLength(255)},
            Contents: {required},
          }
        },
        TopMessageHeaderTitle: {required, maxLength: maxLength(255)},
        TopMessageImageUrl : { required },
        TopMessageTitle: { maxLength: maxLength(255) },
        TopMessageContents: {required},
        TopMessageOrder: {required, numeric},
        InterviewTitle: {required, maxLength: maxLength(255)},
        InterviewOrder: {required, numeric},
        ArticleInterviews: {
            $each: {
              OrderNum: {numeric},
              Interview: {required}, 
            }
        },
        KeyToPopularTitle: {required, maxLength: maxLength(255)},
        KeyToPopularOrder: {required, numeric},
        KeyToPopulars: {
          $each: {
            OrderNum: {numeric},
            KeyPopularImageUrl : { required },
            Title: {required, maxLength: maxLength(255)},
            Header: {required, maxLength: maxLength(255)},
            Content: {required},
          }
        },
      }
    } else {
      return {
        DisplayTitle: {maxLength: maxLength(255)},
        Status: {required, numeric},
        TopImageFileUrl:  {},
        EmploymentStatus: {required},
        ArticleComment: {},
        Occupation: {required},
        OccupationForFilter: {required, maxLength: maxLength(50)},
        JobDescription: {required},
        Salary: {required},
        SalaryDescription: {required},
        WelfareProgramme: {required},
        WorkHours: {required},
        WorkDetails: {required},
        ApplicationRequirements: {},
        WelcomeRequirements: {},
        Store: {required},
        Contact: {required},
        TagGroup: {required},
        WhatKindTitle: {required, maxLength: maxLength(255)},
        WhatKindOrder: {required, numeric},
        WhatKinds: {
          $each: {
            OrderNum: {numeric},
            WhatKindImageUrl : { },
            Title: {maxLength: maxLength(255)},
            Contents: {},
          }
        },
        TopMessageHeaderTitle: {maxLength: maxLength(255)},
        TopMessageImageUrl : {},
        TopMessageTitle: {maxLength: maxLength(255)},
        TopMessageContents: {},
        TopMessageOrder: {required, numeric},
        InterviewTitle: {required, maxLength: maxLength(255)},
        InterviewOrder: {required, numeric},
        ArticleInterviews: {
            $each: {
              OrderNum: {numeric},
              Interview: {required}, 
            }
        },
        KeyToPopularTitle: {required, maxLength: maxLength(255)},
        KeyToPopularOrder: {required, numeric},
        KeyToPopulars: {
          $each: {
            OrderNum: {numeric},
            KeyPopularImageUrl : { required },
            Title: {maxLength: maxLength(255)},
            Header: {maxLength: maxLength(255)},
            Content: {},
          }
        },
      }
    }
  },
  computed: {
    displayTitleErrors() {
      
      const errors = [];
      if (!this.$v.DisplayTitle.$dirty) return errors;
      !this.$v.DisplayTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.DisplayTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    statusErrors() {
      
      const errors = [];
      if (!this.$v.Status.$dirty) return errors;
      !this.$v.Status.numeric && errors.push("数値を入力してください");
      !this.$v.Status.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    imageUrlErrors() {
      const errors = [];
      if (!this.$v.TopImageFileUrl.$dirty) return errors;
      !this.$v.TopImageFileUrl.required && this.isOpen() && errors.push("画像をアップロードしてください");
      return errors;
    },
    employmentStatusErrors() {
      const errors = [];  
      if (!this.$v.EmploymentStatus.$dirty) return errors;
      const length = getLength(this.$v.EmploymentStatus.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      !this.$v.EmploymentStatus.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.ArticleComment.$dirty) return errors;
      const length = getLength(this.$v.ArticleComment.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      return errors;
    },
    occupationErrors() {
      const errors = [];
      if (!this.$v.Occupation.$dirty) return errors;
      const length = getLength(this.$v.Occupation.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      !this.$v.Occupation.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    occupationForFilterErrors() {
      const errors = [];
      if (!this.$v.OccupationForFilter.$dirty) return errors;
      !this.$v.OccupationForFilter.maxLength && errors.push("50文字以内で入力してください");
      !this.$v.OccupationForFilter.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    jobDescriptionErrors() {
      const errors = [];
      if (!this.$v.JobDescription.$dirty) return errors;
      const length = getLength(this.$v.JobDescription.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.JobDescription.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    salaryErrors() {
      const errors = [];
      if (!this.$v.Salary.$dirty) return errors;
      const length = getLength(this.$v.Salary.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      !this.$v.Salary.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    salaryDescriptionErrors() {
      const errors = [];
      if (!this.$v.SalaryDescription.$dirty) return errors;
      const length = getLength(this.$v.SalaryDescription.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.SalaryDescription.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    welfareProgrammeErrors() {
      const errors = [];
      if (!this.$v.WelfareProgramme.$dirty) return errors;
      const length = getLength(this.$v.WelfareProgramme.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.WelfareProgramme.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    workHoursErrors() {
      const errors = [];
      if (!this.$v.WorkHours.$dirty) return errors;
      const length = getLength(this.$v.WorkHours.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      !this.$v.WorkHours.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    workDetailsErrors() {
      const errors = [];
      if (!this.$v.WorkDetails.$dirty) return errors;
      const length = getLength(this.$v.WorkDetails.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.WorkDetails.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    applicationRequirementsErrors() {
      const errors = [];
      if (!this.$v.ApplicationRequirements.$dirty) return errors;
      const length = getLength(this.$v.ApplicationRequirements.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      return errors;
    },
    welcomeRequirementsErrors() {
      const errors = [];
      if (!this.$v.WelcomeRequirements.$dirty) return errors;
      const length = getLength(this.$v.WelcomeRequirements.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      return errors;
    },
    storeErrors() {
      const errors = [];
      if (!this.$v.Store.$dirty) return errors;
      !this.$v.Store.required && errors.push("入力必須項目です");
      return errors;
    },
    tagGroupErrors() {
      const errors = [];
      if (!this.$v.TagGroup.$dirty) return errors;
      !this.$v.TagGroup.required && errors.push("入力必須項目です");
      return errors;
    },
    whatKindTitleErrors() {
      const errors = [];
      if (!this.$v.WhatKindTitle.$dirty) return errors;
      !this.$v.WhatKindTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.WhatKindTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    whatKindOrderErrors() {
      const errors = [];
      if (!this.$v.WhatKindOrder.$dirty) return errors;
      !this.$v.WhatKindOrder.numeric && errors.push("数値で入力してください");
      !this.$v.WhatKindOrder.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.Contact.$dirty) return errors;
      !this.$v.Contact.required && errors.push("入力必須項目です");
      return errors;
    },
    topMessageHeaderTitleErrors() {
      const errors = [];
      if (!this.$v.TopMessageHeaderTitle.$dirty) return errors;
      !this.$v.TopMessageHeaderTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.TopMessageHeaderTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    topMessageImageUrlErrors() {
      const errors = [];
      if (!this.$v.TopMessageImageUrl.$dirty) return errors;
      !this.$v.TopMessageImageUrl.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    topMessageTitleErrors() {
      const errors = [];
      if (!this.$v.TopMessageTitle.$dirty) return errors;
      !this.$v.TopMessageTitle.maxLength && errors.push("255文字以内で入力してください");
      //!this.$v.TopMessageTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    topMessageContentsErrors() {
      const errors = [];
      if (!this.$v.TopMessageContents.$dirty) return errors;
      const length = getLength(this.$v.TopMessageContents.$model);
      if (length > 4000) {
          errors.push("4000文字以内で入力してください");
      }
      !this.$v.TopMessageContents.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    topMessageOrderErrors() {
      const errors = [];
      if (!this.$v.TopMessageOrder.$dirty) return errors;
      !this.$v.TopMessageOrder.numeric && errors.push("数値で入力してください");
      !this.$v.TopMessageOrder.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    interviewTitleErrors() {
      const errors = [];
      if (!this.$v.InterviewTitle.$dirty) return errors;
      !this.$v.InterviewTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.InterviewTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    interviewOrderErrors() {
      const errors = [];
      if (!this.$v.InterviewOrder.$dirty) return errors;
      !this.$v.InterviewOrder.numeric && errors.push("数値で入力してください");
      !this.$v.InterviewOrder.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    keyToPopularTitleErrors() {
      const errors = [];
      if (!this.$v.KeyToPopularTitle.$dirty) return errors;
      !this.$v.KeyToPopularTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.KeyToPopularTitle.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
    keyToPopularOrderErrors() {
      const errors = [];
      if (!this.$v.KeyToPopularOrder.$dirty) return errors;
      !this.$v.KeyToPopularOrder.numeric && errors.push("数値で入力してください");
      !this.$v.KeyToPopularOrder.required && this.isOpen() && errors.push("入力必須項目です");
      return errors;
    },
  },
  async mounted() {
    if (this.$route.params.articleId) {
      this.getLoading = true;
      this.isUpdate = true;
      await this.getArticle(this.$route.params.articleId)
    } else if (this.$route.query.copyFromId) {
      this.getLoading = true;
      this.isDuplicate = true;
      await this.getArticle(this.$route.query.copyFromId)
      this.Status = 1;
      this.ArticleId = "";
      this.UpdatedByName = "";
      this.UpdatedAt = "";
      this.WhatKinds = this.WhatKinds.map((wk) => {
        wk.WhatKindsId = null;
        wk.ArticleId = null;
        return wk;
      });
      this.ArticleInterviews = this.ArticleInterviews.map((iv) => {
        iv.ArticleInterviewId = null;
        return iv;
      });
      this.KeyToPopulars = this.KeyToPopulars.map((kt) => {
        kt.KeyToPopularId = null;
        kt.ArticleId = null;
        return kt;
      });
      this.$v.$touch();
    }
    else if(!this.$route.params.articleId){
      this.CompanyId = this.$route.params.companyId;
      const res = await axios.get("/companies/" + this.$route.params.companyId);
      this.CompanyDomain = res.data.Domain;
    }
    document.title = "TP - " + this.Store.StoreName;
  }
}
</script>

<style scoped>
  .custom-file-input >>> .v-input__append-inner,
  .custom-file-input >>> .v-input__prepend-outer {
    margin: 10px 5px;
  }
  .image-styles {
    max-width : 100%;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
  .video-styles{
    object-fit: cover;
  }
</style>