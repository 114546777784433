<template>
  <v-autocomplete
    class="input-style"
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :readonly="readonly"
    :error-messages="errorMessages"
    hide-no-data
    no-filter
    item-text="Title"
    item-value="InterviewId"
    :placeholder="placeholder"
    return-object
    outlined
    append-icon="fa-sort-up"
    @change="change"
    @blur="blur"
  >
    <template slot="prepend-inner">
      <v-progress-circular
        v-show="isLoading"
        indeterminate
        :size="15"
        :width="1"
        color="green"
      ></v-progress-circular>
    </template>
    <template slot="selection" slot-scope="{ item }">
      {{ item.InterviewId }} - {{ item.Title }}
    </template>
    <template slot="item" slot-scope="{ item }">
      {{ item.InterviewId }} - {{ item.Title }}
    </template>
  </v-autocomplete>
</template>

<script>
import Axios from "axios";

export default {
  name: "InterviewSelector",
  props: {
    initialValue: {},
    readonly: {},
    placeholder: {
      type: String,
      default: "検索するインタビューを入力してください",
    },
    errorMessages: {},
    parentValue: {},
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    timerId: null,
  }),
  computed: {
    items() {
      return this.entries;
    },
  },
  methods: {
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
    setInitialValue(value) {
      if (value) {
        this.isLoading = true;
        Axios.get("/interviews/" + value)
          .then(
            function (response) {
              this.entries = [response.data];
              this.model = response.data;
            }.bind(this)
          )
          .finally(() => (this.isLoading = false));
      }
    },
  },
  mounted() {
    this.setInitialValue(this.initialValue);
  },
  watch: {
    initialValue(value) {
      this.setInitialValue(value);
    },
    model(value) {
      this.$emit("input", value);
    },
    parentValue(value) { 
      this.entries = [value];
      this.model = value;
    },
    search: function () {
      this.isLoading = true;

      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        const params = {
          query: this.search,
          companyId:  this.$route.params.companyId,
        };
        Axios.get("/interviews", {
          params: params,
          companyId:  this.$route.params.companyId,
        })
          .then(
            function (response) {
              this.entries = response.data;
            }.bind(this)
          ).catch(e => {
            const res = e.response;
            if (res && res.status === 502) {
              this.openSnackbarValidationError("エラーが発生しました。検索結果が多すぎる可能性があります。解消しない場合は、時間をおいて再度操作いただくか、システム管理者へご連絡ください。");
            }
          }).finally(() => (this.isLoading = false));
      }, 500);
    },
  },
};
</script>

<style scoped></style>
