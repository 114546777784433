<template>
  <div>
    <header-top-dashboard primary> </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <v-overlay :value="updateLoading" absolute>
        データを更新しています
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="card-shadow">
        <div class="card-header-padding card-border-bottom">
          <div class="font-weight-600 text-h3 text-typo mb-1">
            リファラル専用記事一覧
          </div>
          <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
            新しいリファラル専用記事を追加するには、画面の右側にあるの記事の追加ボタンをクリックします。
          </p>
        </div>

        <v-card-text class="px-0 py-0">
          <v-data-table
            :header-props="{ 'sort-icon': 'fa-sort-up' }"
            v-model="selected"
            :headers="headers"
            :items="articles"
            item-key="ArticleId"
            :search="search"
            :loading="loading"
            loading-text="データを取得しています... Please wait"
            class="table thead-light text-body articles"
            :page.sync="page"
            hide-default-footer
            @page-count="pageCount = $event"
            :items-per-page="itemsPerPage"
            mobile-breakpoint="0"
            show-select
          >
            <template v-slot:top>
              <v-toolbar flat height="80">
                <v-row>
                  <v-col cols="12" md="5">
                    <v-text-field
                      hide-details
                      class="input-style font-size-input text-light-input placeholder-light input-icon"
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      v-model="search"
                      placeholder="Search"
                    >
                      <template slot="prepend-inner">
                        <v-icon color="#adb5bd" size=".875rem"
                          >fas fa-search</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-spacer></v-spacer>

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
                  color="teal"
                  @click="$router.push({ path: 'referral-articles/create' })"
                >
                  <v-icon class="mr-2">fa-plus</v-icon>
                  記事を追加
                </v-btn>
              </v-toolbar>
            </template>

            <template v-slot:item.ArticleId="{ item }">
              {{ item.ArticleId }}
            </template>

            <template v-slot:item.Occupation="{ item }">
              {{ item.Occupation | stripHTML }}
            </template>

            <template v-slot:item.EmploymentStatus="{ item }">
              {{ item.EmploymentStatus | stripHTML }}
            </template>

            <template v-slot:item.Status="{ item }">
              <v-select
                dense
                outlined
                v-model="item.Status"
                item-text="text"
                item-value="value"
                :items="statusItem"
                hide-details
                @change="updateStatus(item.ArticleId, $event)"
              >
                <template #selection="{ item }">
                  <span class="statusText"
                    ><v-icon class="mr-1 icon-width" :color="item.color">{{
                      item.icon
                    }}</v-icon
                    >{{ item.text }}</span
                  >
                </template>
                <template #item="{ item }">
                  <span class="statusText"
                    ><v-icon class="mr-1 icon-width" :color="item.color">{{
                      item.icon
                    }}</v-icon
                    >{{ item.text }}</span
                  >
                </template>
              </v-select>
            </template>

            <template v-slot:item.DisplayTitle="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="text-truncate"
                    style="max-width: 200px"
                  >
                    {{ item.DisplayTitle }}
                  </div>
                </template>
                <span>{{ item.DisplayTitle }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.UpdatedAt="{ item }">
              {{ $dayjs(item.UpdatedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openLink(item.ArticleId)"
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                    outlined
                    v-on="on"
                    color="cyan"
                  >
                    <v-icon size="16">mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>編集画面を開きます</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="
                      $router.push({
                        path: 'referral-articles/create',
                        query: { copyFromId: item.ArticleId },
                      })
                    "
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="ml-4 font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                    outlined
                    v-on="on"
                    color="grey"
                  >
                    <v-icon size="16">mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>リファラル専用記事の内容をコピーし、作成画面に移動します</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="preview(item.ArticleId, item.Store.Company.Domain)"
                    :loading="previewLoading[item.ArticleId]"
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="ml-4 font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                    outlined
                    v-on="on"
                    color="grey"
                  >
                    <v-icon size="16">mdi-file-find</v-icon>
                  </v-btn>
                </template>
                <span>参照記事のプレビューを表示する</span>
              </v-tooltip>
            </template>

            <template v-slot:item.deleteAction="{ item }">
              <v-btn
                @click="openDeleteDialog(item.ArticleId, item.ArticleName)"
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                color="red"
                outlined
              >
                <v-icon size="16">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding">
          <v-row>
            <v-col cols="6" class="ml-auto d-flex">
              <v-btn
                v-if="selected.length > 0"
                elevation="1"
                height="43"
                class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3"
                plain
                @click="openCopyDialog()"
              >
                <v-icon class="mr-2">mdi-content-copy</v-icon>
                {{ selected.length }} 件の記事を複製
              </v-btn>
              <v-btn
                v-if="selected.length > 0"
                elevation="1"
                height="43"
                class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3 red lighten-1 font-weight-bold"
                plain
                @click="openBulkDeleteDialog()"
              >
                <v-icon class="mr-2">mdi-content-copy</v-icon>
                {{ selected.length }} 件の記事を削除
              </v-btn>
            </v-col>
            <v-col cols="6" class="ml-auto d-flex justify-end">
              <v-btn
                elevation="1"
                :ripple="false"
                height="43"
                class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3"
                plain
                @click="download"
              >
                <v-icon class="mr-2">fa-file-export</v-icon>
                エクスポート
              </v-btn>
              <v-pagination
                prev-icon="fa fa-angle-left"
                next-icon="fa fa-angle-right"
                class="pagination"
                color="#46f3c4"
                v-model="page"
                :length="pageCount"
                circle
              ></v-pagination>
            </v-col>
          </v-row>
        </v-card-actions>

        <bulk-delete-modal
          v-if="bulkDeleteDialog"
          :bulkDeleteDialog="bulkDeleteDialog"
          :bulkDeleteLoading="bulkDeleteLoading"
          :selected="selected"
          @bulkDeleteArticle="bulkDeleteArticle"
          @closeBulkDeleteDialog="closeBulkDeleteDialog"
        />

        <copy-modal
          v-if="copyDialog"
          :copyDialog="copyDialog"
          :copyLoading="copyLoading"
          :selected="selected"
          @copyArticle="copyArticle"
          @closeCopyDialog="closeCopyDialog"
        />

        <delete-modal
          v-if="deleteDialog"
          :deleteDialog="deleteDialog"
          :deleteLoading="deleteLoading"
          :deleteArticleId="deleteArticleId"
          :deleteArticleName="deleteArticleName"
          @deleteArticle="deleteArticle"
          @closeDeleteDialog="closeDeleteDialog"
        />
      </v-card>
    </v-container>
  </div>
</template>

<style scoped>
.v-data-table::v-deep .text-start:nth-child(1) {
  padding-left: 24px;
}

::v-deep .articles td {
  white-space: nowrap;
}
.v-data-table::v-deep td {
  font-size: 12px !important;
}

::v-deep .articles th {
  white-space: nowrap;
}
::v-deep .statusText {
  font-size: 12px !important;
  color: #525f7f !important;
}

.v-select {
  min-width: 120px;
  max-width: 200px;
}
.icon-width {
  min-width: 20px !important;
  max-width: 20px !important;
}
</style>

<script>
import HeaderTopDashboard from "../../../components/HeaderTopDashboard";
import BulkDeleteModal from "./components/BulkDeleteModal.vue";
import CopyModal from "./components/CopyModal.vue";
import DeleteModal from "./components/DeleteModal.vue";
import CSV_HEADER from "@/const/csvHeader.js";

import axios from "axios";
import papa from "papaparse";
import { mapActions } from "vuex";
import dayjs from "dayjs";

export default {
  name: "Articles",
  components: {
    HeaderTopDashboard,
    BulkDeleteModal,
    CopyModal,
    DeleteModal,
  },
  data() {
    return {
      selected: [],
      loading: false,
      updateLoading: false,
      copyDialog: false,
      copyLoading: false,
      bulkDeleteDialog: false,
      bulkDeleteLoading: false,
      previewLoading: [],
      deleteArticleId: 0,
      deleteArticleName: "",
      deleteDialog: false,
      deleteLoading: false,
      articles: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      checkboxModel: [],
      search: "",
      filteredData : "", 

      headers: [
        { text: "アクション", value: "actions", sortable: false },
        {
          text: "記事ID",
          align: "start",
          value: "ArticleId",
        },
        { text: "ステータス", value: "Status" },
        { text: "店舗名", value: "Store.StoreName" },
        { text: "表示タイトル", value: "DisplayTitle" },
        { text: "職種", value: "Occupation" },
        { text: "雇用形態", value: "EmploymentStatus" },
        { text: "最終更新者", value: "UpdatedByName" },
        { text: "更新日時", value: "UpdatedAt" },
        { text: "削除", value: "deleteAction", sortable: false },
      ],
      statusItem: [
        { text: "未公開", value: 1, icon: "fa-hourglass-start", color: "gray" },
        { text: "公開中", value: 2, icon: "fa-lock-open", color: "green" },
        { text: "公開停止", value: 3, icon: "fa-lock", color: "red" },
      ],
      csvHeaderArticle: CSV_HEADER.csvHeaderArticle,
      csvHeaderWhatKinds: [
        "ID",
        "記事ID",
        "並び順",
        "画像名",
        "タイトル",
        "内容",
      ],
      csvHeaderKeyToPopulars: [
        "ID",
        "記事ID",
        "並び順",
        "画像名",
        "タイトル",
        "内容",
      ],
      csvHeaderInterviews: [
        "ID",
        "インタビューID",
        "記事ID",
        "並び順",
      ],
      csvHeaderQAs: ["QA ID", "インタビューID", "並び順", "質問", "回答"],
    };
  },
  filters: {
    stripHTML: function (value) {
      const div = document.createElement("div");
      div.innerHTML = value;
      return div.textContent || div.innerText || "";
    },
  },
  watch: {
      search(val) {
        this.filteredData = { ...this.filteredData, search: val }
        this.$router.push({query: this.filteredData });
      },
      page(newPage) {
      this.filteredData = { ...this.filteredData, page: newPage }
      this.$router.push({query: this.filteredData });
    },
  },
  methods: {
    closeBulkDeleteDialog() {
      this.bulkDeleteDialog = false;
    },
    closeCopyDialog() {
      this.copyDialog = false;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    async download() {
      let downloadData = [];
      let downloadKeyToPopulars = [];
      let downloadInterviews = [];
      let downloadQAs = [];
      let downloadWhatKinds = [];
      const arts = (await this.getFullArticles()).data;

      downloadData = arts.map((art) => {
        return [
          art.ArticleId,
          art.DisplayTitle,
          art.Status,
          art.TopImageFilename,
          art.MovieId,
          art.EmploymentStatus,
          art.Occupation,
          art.OccupationForFilter,
          art.AreaForFilter,
          art.BusinessTypeForFilter,
          art.JobDescription,
          art.Salary,
          art.SalaryDescription,
          art.WelfareProgramme,
          art.WorkHours,
          art.WorkDetails,
          art.ApplicationRequirements,
          art.WelcomeRequirements,
          art.TagGroupId,
          art.StoreId,
          art.WhatKindTitle,
          art.WhatKindOrder,
          art.TopMessageHeaderTitle,
          art.TopMessageOrder,
          art.TopMessageFilename,
          art.TopMessageTitle,
          art.TopMessageContents,
          art.InterviewTitle,
          art.InterviewOrder,
          art.KeyToPopularTitle,
          art.KeyToPopularOrder,
          art.ContactId,
          art.TelHiddenFlg,
          art.ArticleComment,
          art.ArticleType,
          art.UpdatedByName,
          dayjs(art.UpdatedAt).format("YYYY-MM-DD HH:mm:ss"),
        ];
      });

      downloadWhatKinds = arts.map((art) => {
        return art.WhatKinds.map((wk) => {
          return [
            wk.WhatKindsId,
            wk.ArticleId,
            wk.OrderNum,
            wk.ImageFilename,
            wk.Title,
            wk.Contents,
          ];
        });
      });

      downloadKeyToPopulars = arts.map((art) => {
        return art.KeyToPopulars.map((ktp) => {
          return [
            ktp.KeyToPopularId,
            ktp.ArticleId,
            ktp.OrderNum,
            ktp.ImageFilename,
            ktp.Title,
            ktp.Content,
          ];
        });
      });

      downloadInterviews = arts.map(art => {
        return art.ArticleInterviews.map(iv => {
          return [
            iv.ArticleInterviewId,
            iv.InterviewId,
            iv.ArticleId,
            iv.OrderNum,
          ]
        });
      });

      let csvData = [];
      csvData.push({
        fileName: "referral_articles_",
        csvData: papa.unparse(
          {
            data: Array(this.csvHeaderArticle).concat(downloadData),
          },
          { delimiter: "\t" }
        ),
      });
      csvData.push({
        fileName: "what_kinds_",
        csvData: papa.unparse({
          "data": Array(this.csvHeaderWhatKinds).concat(...downloadWhatKinds),
        }, {"delimiter": "\t"})
      });
      csvData.push({
        fileName: "key_to_populars_",
        csvData: papa.unparse({
          "data": Array(this.csvHeaderKeyToPopulars).concat(...downloadKeyToPopulars),
        }, {"delimiter": "\t"})
      });
      csvData.push({
        fileName: "interviews_",
        csvData: papa.unparse({
          "data": Array(this.csvHeaderInterviews).concat(...downloadInterviews),
        }, {"delimiter": "\t"})
      });
      // csvData.push({
      //   fileName: "questions_",
      //   csvData: papa.unparse({
      //     "data": Array(this.csvHeaderQAs).concat(...downloadQAs),
      //   }, {"delimiter": "\t"})
      // });
      for (let csv of csvData) {
        const blob = new Blob([csv.csvData], { type: "text/tsv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =
          csv.fileName +
          this.$route.params.companyId +
          "_" +
          dayjs().format("YYYYMMDDHHmmss") +
          ".tsv";
        link.click();
      }
    },
    updateStatus(articleId, event) {
      this.updateLoading = true;
      axios
        .put("/articles/" + articleId + "/status?articleType=1", {
          Status: event,
        })
        .then(() => {
          this.openSnackbarSuccess();
          this.$router.push({ name: "ReferralArticles" });
        })
        .catch((e) => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          }
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
    openLink(articleId) {
      window.open("referral-articles/" + articleId, "_blank");
    },
    openCopyDialog() {
      this.copyDialog = true;
    },
    openBulkDeleteDialog() {
      this.bulkDeleteDialog = true;
    },
    openDeleteDialog(articleId, articleName) {
      this.deleteDialog = true;
      this.deleteArticleId = articleId;
      this.deleteArticleName = articleName;
    },
    deleteArticle() {
      this.deleteLoading = true;
      axios
        .delete("/articles/" + this.deleteArticleId + "?articleType=1")
        .then(() => {
          this.openSnackbarSuccess();
          this.loadArticles();
        })
        .catch((e) => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          }
        })
        .finally(() => {
          this.deleteLoading = false;
          this.deleteDialog = false;
          this.deleteArticleId = 0;
          this.deleteArticleName = "";
        });
    },
    bulkDeleteArticle() {
      this.bulkDeleteLoading = true;
      const fromIds = this.selected.map((a) => Number(a.ArticleId));
      axios
        .delete("/articles?articleType=1", {
          data: fromIds,
        })
        .then(() => {
          this.openSnackbarSuccess();
          this.loadArticles();
        })
        .catch((e) => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          }
        })
        .finally(() => {
          this.selected = [];
          this.bulkDeleteLoading = false;
          this.bulkDeleteDialog = false;
        });
    },
    copyArticle() {
      this.copyLoading = true;
      const fromIds = this.selected.map((a) => Number(a.ArticleId));
      axios
        .post("/articles/copy?articleType=1", fromIds)
        .then(() => {
          this.openSnackbarSuccess();
          this.loadArticles();
        })
        .catch((e) => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data);
          }
        })
        .finally(() => {
          this.selected = [];
          this.copyLoading = false;
          this.copyDialog = false;
        });
    },
    async getFullArticles() {
      this.loading = true;
      const ret = await axios.get(
        "/articles?companyId=" +
          this.$route.params.companyId +
          "&full=1&articleType=1"
      );
      const s3url = ret.data.S3SignedUrl;
      const result = await axios.get(s3url);
      this.loading = false;
      return result;
    },
    loadArticles() {
      this.loading = true;
      axios
        .get(
          "/articles?companyId=" +
            this.$route.params.companyId +
            "&articleType=1"
        )
        .then((res) => {
          const s3url = res.data.S3SignedUrl;
          axios.get(s3url).then(s3res => {
            this.articles = s3res.data;
            this.page = this.$route.query.page ? Number(this.$route.query.page) : this.page;
          })
        }).finally(() => {
          this.loading = false;
        });
    },
    preview(articleId, domain) {
      this.previewLoading[articleId] = true;
      axios.get("/articles/" + articleId + "/preview?articleType=1").then(() => {
        this.openSnackbarSuccess();
        if(process.env.NODE_ENV === "production"){
          window.open(`https://${domain}.tt-recruit.com/${articleId}-preview`);
        }
        else {
          window.open(`${process.env.VUE_APP_PREVIEW_ENDPOINT}/${domain}/${articleId}-preview/`);
        }
      }).catch(e => {
        const res = e.response;
        if (res.status === 400) {
          this.openSnackbarValidationError(res.data)
        }
      }).finally(() => {
        this.previewLoading = [];
      });
    },
    ...mapActions("snackbar", [
      "openSnackbarSuccess",
      "openSnackbarApiError",
      "openSnackbarValidationError",
    ]),
  },
  mounted() {
    this.search = this.$route.query.search || '';
  },
  created() {
    this.loadArticles();
  },
};
</script>

<style scoped></style>
