<template>
    <div>
      <header-top-dashboard primary> </header-top-dashboard>
      <v-container fluid class="pt-0 px-6 mt-n13">
        <v-card class="card-shadow">
          <div class="card-header-padding card-border-bottom">
            <div class="font-weight-600 text-h3 text-typo mb-1">インタビュー一覧</div>
            <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
              新しくインタビューを追加する場合は、画面右手のインタビューの追加ボタンをクリックしてください。
            </p>
          </div>
          <v-dialog width="538" v-model="deleteDialog">
            <template v-slot:default="dialog">
              <v-card class="card-shadow bg-gradient-danger">
                <div
                  class="card-header-padding d-flex align-center justify-space-between pb-10"
                >
                  <v-card-title class="pa-0 text-h3 font-weight-600 text-white"
                    >注意</v-card-title
                  >
                  <v-btn
                    icon
                    @click="dialog.value = false"
                    elevation="0"
                    :ripple="false"
                    height="38"
                    min-width="38"
                    width="38"
                    class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
                    color="rgba(255,255,255,.6)"
                  >
                    <v-icon size="13">fas fa-times</v-icon>
                  </v-btn>
                </div>
                <v-card-text class="card-padding pt-6 text-center">
                  <v-icon size="48" class="text-white">ni-bell-55</v-icon>
                  <h4
                    class="heading mt-6 text-white font-weight-600 text-uppercase"
                  >
                    インタビューID {{ deleteInterviewId }}
                    {{ deleteInterviewName }} を削除しようとしています
                  </h4>
                  <p class="text-white font-size-root font-weight-thin mt-3">
                    削除されたインタビューは元に戻せません。<br />
                    本当に削除してよろしいですか？
                  </p>
                </v-card-text>
                <div
                  class="card-padding d-flex align-center justify-space-between"
                >
                  <v-btn
                    elevation="0"
                    max-width="136"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
                    color="#fff"
                    @click="deleteInterview"
                    :loading="deleteLoading"
                    >はい、削除します</v-btn
                  >
                  <v-btn
                    @click="dialog.value = false"
                    elevation="0"
                    max-width="136"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
                    color="transparent"
                    >閉じる</v-btn
                  >
                </div>
              </v-card>
            </template>
          </v-dialog>

          <v-dialog width="538" v-model="bulkDeleteDialog">
            <template v-slot:default="dialog">
              <v-card class="card-shadow bg-gradient-danger">
                <div
                  class="card-header-padding d-flex align-center justify-space-between pb-10"
                >
                  <v-card-title
                    class="pa-0 text-h3 font-weight-600 text-white"
                  >
                    確認
                  </v-card-title>
                  <v-btn
                    icon
                    @click="dialog.value = false"
                    elevation="0"
                    :ripple="false"
                    height="38"
                    min-width="38"
                    width="38"
                    class="font-weight-600 text-capitalize btn-ls btn-hover rounded-sm"
                    color="rgba(255,255,255,.6)"
                  >
                    <v-icon size="13">fas fa-times</v-icon>
                  </v-btn>
                </div>
                <v-card-text class="card-padding pt-6 text-center">
                  <v-icon size="48" class="text-white">
                    ni-bell-55
                  </v-icon>
                  <h4
                    class="heading mt-6 text-white font-weight-600 text-uppercase"
                  >
                    インタビューID
                    <template v-for="(s, index) in selected">
                      <template v-if="index > 0">, </template>
                      {{s.InterviewId}}
                    </template>を削除しようとしています
                  </h4>
                  <p
                    class="text-white font-size-root font-weight-thin mt-3"
                  >
                    削除されたインタビューは元に戻せません。<br>
                    本当に削除してよろしいですか？
                  </p>
                </v-card-text>
                <div
                    class="card-padding d-flex align-center justify-space-between"
                >
                  <v-btn
                    elevation="0"
                    max-width="136"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm"
                    color="#fff"
                    @click="bulkDeleteInterview"
                    :loading="bulkDeleteLoading"
                  >
                    はい、削除します
                  </v-btn>
                  <v-btn
                    @click="dialog.value = false"
                    elevation="0"
                    max-width="136"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize text-white py-3 px-6 rounded-sm"
                    color="transparent"
                  >
                    閉じる
                  </v-btn>
                </div>
              </v-card>
            </template>
          </v-dialog>

          <v-card-text class="px-0 py-0">
            <v-data-table
              :header-props="{ 'sort-icon': 'fa-sort-up' }"
              v-model="selected"
              :headers="headers"
              :items="Interviews"
              item-key="InterviewId"
              :search="search"
              :loading="loading"
              loading-text="データを取得しています... Please wait"
              class="table thead-light text-body"
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
              show-select
            >
              <template v-slot:top>
                <v-toolbar flat height="80">
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        hide-details
                        class="input-style font-size-input text-light-input placeholder-light input-icon"
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        v-model="search"
                        placeholder="Search"
                      >
                        <template slot="prepend-inner">
                          <v-icon color="#adb5bd" size=".875rem"
                            >fas fa-search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
  
                  <v-spacer></v-spacer>
  
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="text-capitalize btn-primary py-2 px-4 rounded-sm ms-3"
                    color="teal"
                    @click="$router.push({ path: 'interviews/create' })"
                  >
                    <v-icon class="mr-2">fa-plus</v-icon>
                    インタビューを追加
                  </v-btn>
                </v-toolbar>
              </template>
  
              <template v-slot:item.InterviewId="{ item }">
                {{ item.InterviewId }}
              </template>
  
              <template v-slot:item.UpdatedAt="{ item }">
                {{ $dayjs(item.UpdatedAt).format("YYYY-MM-DD HH:mm:ss") }}
              </template>
  
              <template v-slot:item.WorkLocation="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="text-truncate"
                      style="max-width: 150px"
                    >
                      {{ getLocation(item) }}
                    </div>
                  </template>
                  <span>{{ getLocation(item) }}</span>
                </v-tooltip>
              </template>
  
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="$router.push({ path: 'interviews/' + item.InterviewId })"
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                      outlined
                      v-on="on"
                      color="cyan"
                    >
                      <v-icon size="16">mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>編集画面へ遷移します</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="preview(item.InterviewId)"
                      :loading="previewLoading[item.InterviewId]"
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="36"
                      class="ml-4 font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                      outlined
                      v-on="on"
                      color="grey"
                    >
                      <v-icon size="16">mdi-file-find</v-icon>
                    </v-btn>
                  </template>
                  <span>インタビューのプレビューを表示します</span>
                </v-tooltip>
              </template>
  
              <template v-slot:item.deleteAction="{ item }">
                <v-btn
                  @click="openDeleteDialog(item.InterviewId, item.InterviewName)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="red"
                  outlined
                >
                  <v-icon size="16">mdi-delete-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="card-padding">
            <v-row>
              <v-col cols="6" class="ml-auto d-flex">
                <v-btn
                  v-if="selected.length > 0"
                  elevation="1"
                  height="43"
                  class="text-capitalize py-2 px-4 mr-5 rounded-sm ms-3 red lighten-1 font-weight-bold"
                  plain
                  @click="openBulkDeleteDialog();"
                >
                  <v-icon class="mr-2">mdi-content-copy</v-icon>
                  {{selected.length}} 件のインタビューを削除
                </v-btn>
              </v-col>
              <v-col cols="6" class="ml-auto d-flex justify-end">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#46f3c4"
                  v-model="page"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import dayjs from "dayjs";
  import { mapActions, mapGetters } from "vuex";
  import HeaderTopDashboard from "@/components/HeaderTopDashboard";
  import { arrayToTsv } from "@/util/helpers/arrayToTsv";
  import { tsvToArray }  from "@/util/helpers/tsvToArray";
  
  export default {
    name: "Interviews",
    components: {
      HeaderTopDashboard,
    },
    data() {
      return {
        selected: [],
        loading: false,
        deleteInterviewId: 0,
        deleteInterviewName: "",
        deleteDialog: false,
        deleteLoading: false,
        bulkDeleteDialog: false,
        bulkDeleteLoading: false,
        previewLoading: [],
        importLoading: false,
        exportLoading: false,
        Interviews: [],
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        dialog: false,
        dialogDelete: false,
        checkboxModel: [],
        search: "",
        filteredData: "",
        headers: [
          { text: "アクション", value: "actions", sortable: false },
          {
            text: "インタビューID",
            align: "start",
            value: "InterviewId",
          },
          { text: "タイトル", value: "Title" },
          { text: "最終更新者", value: "UpdatedByName" },
          { text: "更新日時", value: "UpdatedAt" },
          { text: "削除", value: "deleteAction", sortable: false },
        ],
      };
    },
    watch: {
        search(val) {
          this.filteredData = { ...this.filteredData, search: val }
          this.$router.push({query: this.filteredData });
        },
        page(newPage) {
          this.filteredData = { ...this.filteredData, page: newPage }
          this.$router.push({query: this.filteredData });
      },
    },
    methods: {
      openDeleteDialog(InterviewId, InterviewName) {
        this.deleteDialog = true;
        this.deleteInterviewId = InterviewId;
        this.deleteInterviewName = InterviewName;
      },
      openBulkDeleteDialog() {
        this.bulkDeleteDialog = true;
      },
      loadInterviews() {
        this.loading = true;
        axios
          .get("/interviews?companyId=" + this.$route.params.companyId)
          .then((res) => {
            this.Interviews = res.data;
            this.page = this.$route.query.page ? Number(this.$route.query.page) : this.page;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      bulkDeleteInterview() {
        this.bulkDeleteLoading = true;
        const fromIds = this.selected.map((a) => Number(a.InterviewId));
        axios.delete("/interviews", {
          data: fromIds
        }).then(() => {
          this.openSnackbarSuccess();
          this.loadInterviews();
        }).catch(e => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data)
          }
        }).finally(() => {
          this.selected = [];
          this.bulkDeleteLoading = false;
          this.bulkDeleteDialog = false;
        });
      },
      deleteInterview() {
        this.deleteLoading = true;
        axios
          .delete("/interviews/"+ this.deleteInterviewId )
          .then(() => {
            this.openSnackbarSuccess();
            this.loadInterviews();
          })
          .catch((e) => {
            const res = e.response;
            if (res.status === 400) {
              this.openSnackbarValidationError(res.data);
            }
          })
          .finally(() => {
            this.deleteLoading = false;
            this.deleteDialog = false;
            this.deleteInterviewId = 0;
            this.deleteInterviewName = "";
          });
      },
      preview(interviewId) {
        const companyId = this.$route.params.companyId;   
        this.previewLoading[interviewId] = true;
        axios.get("/interviews/preview?interviewId=" + interviewId + "&companyId=" + companyId).then((res) => {
          this.openSnackbarSuccess();
          if(process.env.NODE_ENV === "production"){
            window.open(`https://${res.data.Domain}.tt-recruit.com/interview-preview/${interviewId}.html`);
          }
          else if(process.env.NODE_ENV === "staging") {
            window.open(`${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Domain}/interview-preview/${interviewId}.html`);
          } else {
            window.open(`http://localhost:4566/tpr-dev-static-resources/${res.data.Domain}/interview-preview/${interviewId}.html`);
          }
        }).catch(e => {
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data)
          }
        }).finally(() => {
          this.previewLoading = [];
        });
      },
      async getFullInterviews() {
        this.loading = true;
        const result = await axios.get(
          "/Interviews?companyId=" + this.$route.params.companyId + "&full=1"
        );
        this.loading = false;
        return result;
      },
  
      fileUploadHandler() {
        try {
          this.$refs.fileInput.click();
        } catch (e) {
          console.log(e);
        }
      },
      async fileUpload(event) {
      try {
        this.importLoading = true;
        const file = event?.target?.files?.[0] ?? null;
        const reader = new FileReader();
        let tsvDataArray = [];
        reader.onload = (e) => {
          tsvDataArray = tsvToArray(e.target.result, "\t"); 
          const finalTsvData = tsvDataArray.map((data) => {
            return {
              ...data,
              CompanyId: Number(data.CompanyId),
              InterviewId: Number(data.InterviewId),
            };
          });
          axios
            .put("/Interviews/batch?companyId=" + this.$route.params.companyId, finalTsvData)
            .then(() => {
              this.openSnackbarSuccess();
              this.loadInterviews();
            })
            .catch((e) => {
              const res = e.response;
              if (res?.status === 400) {
                this.openSnackbarValidationError(res.data);
              }
            })
            .finally(() => {
              this.importLoading = false;
            });
        };
        reader.readAsText(file);
      } catch (e) {
        console.log(e);
      }
    },
      async download() {
        try {
          this.exportLoading = true;
          let items = [];
          const arts = (await this.getFullInterviews()).data;
          items = arts.map((art) => {
            return {
              InterviewId: art.InterviewId,
              CompanyId: art.CompanyId,
              EmbedHtmlForGoogleMap: art.EmbedHtmlForGoogleMap,
              NearestStation: art.NearestStation,
              NumberOfSeats: art.NumberOfSeats,
              Pref: art.Pref,
              InterviewGroup: art.InterviewGroup,
              InterviewLogoFilename: art.InterviewLogoFilename,
              InterviewName: art.InterviewName,
              TypeOfBusiness: art.TypeOfBusiness,
              WorkLocation: art.WorkLocation,
            };
          });
          let tsvData = [this.tsvHeaderInterview, ...items];
          const tsv = arrayToTsv(tsvData);
          let link = document.createElement("a");
          link.id = "download-tsv";
          link.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(tsv)
          );
          link.download =
            "Interviews_" +
            this.$route.params.companyId +
            "_" +
            dayjs().format("YYYYMMDDHHmmss") +
            ".tsv";
          link.click();
        } catch (e) {
        } finally {
          this.exportLoading = false;
        }
      },
      getLocation(Interview) {
        return Interview.Pref
          ? Interview.Pref + Interview.WorkLocation.replace(Interview.Pref, "")
          : Interview.WorkLocation;
      },
      ...mapActions("snackbar", [
        "openSnackbarSuccess",
        "openSnackbarApiError",
        "openSnackbarValidationError",
      ]),
    },
    mounted() {
      this.search = this.$route.query.search || '';
    },
    created() {
      this.loadInterviews();
    },
  };
  </script>
  
  <style scoped></style>
  