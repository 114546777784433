// triple-page-recruit-frontend/src/const/trafficSource.js
//候補者詳細画面
const TRAFFIC_SOURCE = [
    { value: 0, text: "未入力" },
    { value: 1, text: "リンク直接" },
    { value: 2, text: "Indeed" },
    { value: 3, text: "求人ボックス" },
    { value: 4, text: "Googleしごと検索" },
    { value: 5, text: "店舗LINE" },
    { value: 6, text: "Instagram" },
    { value: 7, text: "企業HP" },
    { value: 8, text: "standby" },
    { value: 9, text: "検索エンジン" },
    { value: 10, text: "その他" },
    { value: 11, text: "紹介" },
];

export default TRAFFIC_SOURCE;
