<template>
<v-container>
    <v-row>
        <v-col cols="12" md="6" class="py-0">
            <label class="label-color font-weight-600 mb-2 d-block">
                <span class="red--text">* </span> トップ画像タイトル
            </label>
            <v-input :error-messages="topImageTitleErrors()" class="mb-6">
                <v-text-field
                    outlined
                    class="input-style font-size-input text-light-input placeholder-light"
                    placeholder="例）すべてのお客様を笑顔にする"
                    v-model="v3.TopImageTitle"
                    @input="$v.V3.TopImageTitle.$touch()"
                    @blur="$v.V3.TopImageTitle.$touch()"
                ></v-text-field>
            </v-input>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="6" class="mt-0 py-0">
            <label class="label-color font-weight-600 mb-2 d-block">
                <span class="red--text">* </span> トップ画像
            </label>
            <div v-if="v3.TopImageItems && v3.TopImageItems.length > 0">
                <v-card class="card-shadow mb-6 pt-6" v-for="(topImage, key) in $v.V3.TopImageItems.$each.$iter" :key="key">
                    <v-card-text class="card-padding">
                        <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <label class="label-color font-weight-600 mb-2 d-block">
                                    <span class="red--text">* </span> 画像名
                                </label>
                                <v-input :error-messages="topImageItemErrors(key)">
                                    <v-file-input
                                        type="file"
                                        outlined
                                        class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                        :placeholder="isUpdate && v3.TopImageItems[key].ImageFilename ? '' :'例）image'"
                                        accept="image/*"
                                        hide-details
                                        required
                                        @change="(e) => uploadFile(e, 'topImage', key)"
                                        @input="topImage.TopImageUrl.$touch()"
                                        @blur="topImage.TopImageUrl.$touch()"
                                    >
                                        <template v-if="isUpdate && v3.TopImageItems[key].ImageFilename && v3.TopImageItems[key].TopImage.entries().next().done" #prepend-inner>
                                            {{ v3.TopImageItems[key].ImageFilename }}
                                        </template>
                                    </v-file-input>
                                </v-input>
                                <div class="d-flex justify-center" v-if="v3.TopImageItems[key].TopImageUrl">
                                    <img :src="v3.TopImageItems[key].TopImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="key > 0">
                                <v-btn fab x-small absolute dark center top color="primary" @click="swapTopImageItem(key)">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn fab x-small absolute dark right top color="red" @click="deleteTopImageItem(key)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
            <v-btn tile outlined class="mb-5" color="green" @click="addTopImageItem">
                <v-icon left>mdi-table-row-plus-after</v-icon>
                入力欄の追加
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-2 py-0">
            <label
            class="label-color font-weight-600 mb-2 d-block text-h2"
            >
            Introduction
            </label>
            <v-row>
                <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >
                        <span class="red--text">* </span> Introduction タイトル
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="人気の秘訣"
                        v-model="v3.IntroductionTitle"
                        :error-messages="introductionTitleErrors()"
                        :counter="255"
                        required
                        @input="$v.V3.IntroductionTitle.$touch()"
                        @blur="$v.V3.IntroductionTitle.$touch()"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >
                        <span class="red--text">* </span> Introduction 本文
                    </label>
                    <v-input
                      class="mb-6"
                      :error-messages="introductionDescriptionErrors()"
                      >
                        <vue-editor
                            outlined
                            class="input-style font-size-input text-light-input placeholder-light"
                            placeholder="人気の秘訣"
                            v-model="v3.IntroductionDescription"
                            :editorToolbar="customToolbarDetail"
                            :counter="255"
                            required
                            @input="$v.V3.IntroductionDescription.$touch()"
                            @blur="$v.V3.IntroductionDescription.$touch()"
                        ></vue-editor>
                    </v-input>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-2 py-0">
        <v-row>
            <v-col cols="12" md="6" class="py-0">
            <div>
                <label
                class="label-color font-weight-600 mb-2 d-block"
                >
                <span class="red--text">* </span>Introduction 画像・動画ファイル名
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                    >
                        mdi-help-circle-outline
                    </v-icon>
                    </template>
                    <span>
                    トップに表示される画像または動画ファイルを指定します。<br/>
                    動画ファイルはMP4形式のみ利用可能です。
                    </span>
                </v-tooltip>
                </label>
            <v-input
                :error-messages="introductionImageErrors()"
            >
            <v-file-input
                type="file"
                outlined
                class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                :placeholder="isUpdate && v3.IntroductionFileName ? '' :'例）image'"
                accept="image/*,video/mp4,video/x-m4v,video/*"
                hide-details
                required
                @change="(e) => uploadFile(e, 'introductionFile')"
                @input="$v.V3.IntroductionFileUrl.$touch()"
                @blur="$v.V3.IntroductionFileUrl.$touch()"
                >
                <template v-if="isUpdate && v3.IntroductionFileName && v3.IntroductionFile.entries().next().done" #prepend-inner>
                    {{v3.IntroductionFileName}}
                </template>
            </v-file-input>
            </v-input>
            </div>
                <div v-if="v3.IntroductionFileUrl && v3.IntroductionFileType === 'video'" :key="v3.IntroductionFileUrl">
                <video controls width="100%"  height="300px" class="video-styles">
                    <source :src="v3.IntroductionFileUrl" type="video/mp4" />
                </video>
                </div>
                <div v-if="v3.IntroductionFileUrl && v3.IntroductionFileType === 'image'" class="d-flex justify-center mb-2">
                    <img :src="v3.IntroductionFileUrl" alt="Uploaded Image" class="image-styles" />
            </div>                    
            </v-col>
        </v-row>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-0 py-0">
            <label
            class="label-color font-weight-600 mb-2 d-block text-h2"
            >
            インタビュー
            </label>
            <div v-if="v3.InterviewItems && v3.InterviewItems.length > 0">
            <v-card class="card-shadow mb-6 pt-6" v-for="(interview, key) in $v.V3.InterviewItems.$each.$iter" :key="key">
                <v-card-text class="card-padding">
                <v-row>
                    <v-col cols="12" md="6" class="py-0">

                        <div>
                <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        画像、動画
                    </label>
                    <v-input
                        :error-messages="interviewImageFilenameErrors(key)"
                    >
                    <v-file-input
                        type="file"
                        outlined
                        class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                        :placeholder="isUpdate && v3.InterviewItems[key].FileName ? '' :'例）image'"
                        accept="image/*,video/mp4,video/x-m4v,video/*"
                        hide-details
                        required
                        @change="(e) => uploadFile(e, 'interviewFile', key)"
                        @input="interview.FileUrl.$touch()"
                        @blur="interview.FileUrl.$touch()"
                    >
                    <template v-if="isUpdate && v3.InterviewItems[key].FileName &&  v3.InterviewItems[key].File.entries().next().done" #prepend-inner>
                        {{ v3.InterviewItems[key].FileName }}
                    </template>
                    </v-file-input>
                    </v-input>
                    </div>
                        <div v-if="v3.InterviewItems[key].FileName && v3.InterviewItems[key].FileType === 'video'" :key="v3.InterviewItems[key].File">
                            <video controls width="100%"  height="300px" class="video-styles">
                                <source :src="v3.InterviewItems[key].FileUrl" type="video/mp4" />
                            </video>
                        </div>
                        <div v-if="v3.InterviewItems[key].FileName && v3.InterviewItems[key].FileType === 'image'" class="d-flex justify-center mb-2">
                            <img :src="v3.InterviewItems[key].FileUrl" alt="Uploaded Image" class="image-styles" />
                        </div>                    
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        タイトル
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）企業の雰囲気を肌で感じ、入社を決めました。'
                        v-model="v3.InterviewItems[key].Title"
                        :error-messages="interviewTitleErrors(key)"
                        required
                        :counter="255"
                        @input="interview.Title.$touch()"
                        @blur="interview.Title.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        内容
                    </label>
                    <v-input
                        class="mb-2"
                        :error-messages="interviewContentsErrors(key)"
                    >
                        <vue-editor
                            outlined
                            class="input-style font-size-input text-light-input placeholder-light"
                            placeholder='例) K.S'
                            v-model="v3.InterviewItems[key].Contents"
                            required
                            :editorToolbar="customToolbarDetail"
                            @input="interview.Contents.$touch()"
                            @blur="interview.Contents.$touch()"
                        >
                        </vue-editor>
                    </v-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="key > 0">
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        center
                        top
                        color="primary"
                        @click="swapInterviewItem(key)"
                    >
                        <v-icon>
                        mdi-swap-vertical
                        </v-icon>
                    </v-btn>
                    </v-col>
                    <v-col>
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        right
                        top
                        color="red"
                        @click="deleteInterviewItem(key)"
                    >
                        <v-icon>
                        mdi-delete
                        </v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </div>
            <v-btn
            tile
            outlined
            class="mb-5"
            color="green"
            @click="addInterviewItem"
            >
            <v-icon left>
                mdi-table-row-plus-after
            </v-icon>
            入力欄の追加
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-2 py-0">
            <label
                class="label-color font-weight-600 mb-2 d-block text-h2"
            >
            チェックポイント
            </label>
            <div v-if="v3.CheckPointItems && v3.CheckPointItems.length > 0">
                <v-card class="card-shadow mb-6 pt-6" v-for="(checkPoint, key) in $v.V3.CheckPointItems.$each.$iter" :key="key">
                    <v-card-text class="card-padding">
                    <v-row>
                        <v-col cols="12" md="6" class="py-0">

                            <div>
                        <label
                            class="label-color font-weight-600 mb-2 d-block"
                        ><span class="red--text">* </span>
                            画像、動画
                        </label>
                        <v-input
                            :error-messages="checkPointImageFilenameErrors(key)"
                        >
                        <v-file-input
                            type="file"
                            outlined
                            class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                            :placeholder="isUpdate && v3.CheckPointItems[key].FileName ? '' :'例）image'"
                            accept="image/*"
                            hide-details
                            required
                            @change="(e) => uploadFile(e, 'checkPointFile', key)"
                            @input="checkPoint.FileUrl.$touch()"
                            @blur="checkPoint.FileUrl.$touch()"
                        >
                        <template v-if="isUpdate && v3.CheckPointItems[key].FileName &&  v3.CheckPointItems[key].File.entries().next().done" #prepend-inner>
                            {{ v3.CheckPointItems[key].FileName }}
                        </template>
                        </v-file-input>
                        </v-input>
                        </div>
                        <div class="d-flex justify-center" v-if="v3.CheckPointItems[key].FileUrl">
                            <img :src="v3.CheckPointItems[key].FileUrl" alt="Uploaded Image" class="image-styles" />
                        </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        タイトル
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='例）福利厚生が充実'
                        v-model="v3.CheckPointItems[key].Title"
                        :error-messages="checkPointTitleErrors(key)"
                        required
                        :counter="255"
                        @input="checkPoint.Title.$touch()"
                        @blur="checkPoint.Title.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>本文</label
                    >
                    <v-input
                        :error-messages="checkPointContentsErrors(key)"
                    >
                        <vue-editor
                            outlined
                            class="input-style font-size-input text-light-input placeholder-light"
                            placeholder="内容を入力"
                            v-model="v3.CheckPointItems[key].Contents"
                            required
                            @input="checkPoint.Contents.$touch()"
                            @blur="checkPoint.Contents.$touch()"
                        >
                        </vue-editor>
                    </v-input>    
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="key > 0">
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        center
                        top
                        color="primary"
                        @click="swapCheckPointItem(key)"
                    >
                        <v-icon>
                        mdi-swap-vertical
                        </v-icon>
                    </v-btn>
                    </v-col>
                    <v-col>
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        right
                        top
                        color="red"
                        @click="deleteCheckPointItem(key)"
                    >
                        <v-icon>
                        mdi-delete
                        </v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </div>
            <v-btn
            tile
            outlined
            class="mb-5"
            color="green"
            @click="addCheckPointItem"
            >
            <v-icon left>
                mdi-table-row-plus-after
            </v-icon>
            入力欄の追加
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="6" class="py-0">
            <label class="label-color font-weight-600 mb-2 d-block">
                <span class="red--text">* </span> 料理写真
            </label>
            <div v-if="v3.FoodImageItems && v3.FoodImageItems.length > 0">
                <v-card class="card-shadow mb-6 pt-6" v-for="(foodImage, key) in $v.V3.FoodImageItems.$each.$iter" :key="key">
                    <v-card-text class="card-padding">
                        <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <label class="label-color font-weight-600 mb-2 d-block">
                                    <span class="red--text">* </span> 画像名
                                </label>
                                <v-input :error-messages="foodImageFilenameErrors(key)">
                                    <v-file-input
                                        type="file"
                                        outlined
                                        class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                        :placeholder="isUpdate && v3.FoodImageItems[key].FoodImageName ? '' :'例）image'"
                                        accept="image/*" 
                                        hide-details
                                        required
                                        @change="(e) => uploadFile(e, 'foodImage', key)"
                                        @input="foodImage.FoodImageUrl.$touch()"
                                        @blur="foodImage.FoodImageUrl.$touch()"
                                    >
                                        <template v-if="isUpdate && v3.FoodImageItems[key].FoodImageName && v3.FoodImageItems[key].FoodImage.entries().next().done" #prepend-inner>
                                            {{ v3.FoodImageItems[key].FoodImageName }}
                                        </template>
                                    </v-file-input>
                                </v-input>
                                <div class="d-flex justify-center" v-if="v3.FoodImageItems[key].FoodImageUrl">
                                    <img :src="v3.FoodImageItems[key].FoodImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="key > 0">
                                <v-btn fab x-small absolute dark center top color="primary" @click="swapFoodImageItem(key)">
                                    <v-icon>mdi-swap-vertical</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn fab x-small absolute dark right top color="red" @click="deleteFoodImageItem(key)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
            <v-btn tile outlined class="mb-5" color="green" @click="addFoodImageItem">
                <v-icon left>mdi-table-row-plus-after</v-icon>
                入力欄の追加
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-2 py-0">
            <label
            class="label-color font-weight-600 mb-2 d-block text-h2"
            >
            店舗一覧
            </label>
            <div v-if="v3.StoreItems && v3.StoreItems.length > 0">
            <v-card class="card-shadow mb-6 pt-6" v-for="(store, key) in $v.V3.StoreItems.$each.$iter" :key="key">
                <v-card-text class="card-padding">
                <v-row>
                    <v-col cols="12" md="6" class="py-0">
                    <div>
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>画像名</label>
                    <v-input
                        :error-messages="storeFileErrors(key)"
                    >
                    <v-file-input
                        type="file"
                        outlined
                        class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                        :placeholder="isUpdate && v3.StoreItems[key].FileName ? '' :'例）image'"
                        accept="image/*" 
                        hide-details
                        required
                        @change="(e) => uploadFile(e, 'storeImage', key)"
                        @input="store.FileUrl.$touch()"
                        @blur="store.FileUrl.$touch()"
                    >
                    <template v-if="isUpdate && v3.StoreItems[key].FileName &&  v3.StoreItems[key].File.entries().next().done" #prepend-inner>
                        {{ v3.StoreItems[key].FileName }}
                    </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center mb-2" >
                        <img :src="v3.StoreItems[key].FileUrl" alt="Uploaded Image" v-if="v3.StoreItems[key].FileUrl" class="image-styles" />
                    </div>
                    </v-col>                    
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        店舗名
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='店舗名'
                        v-model="v3.StoreItems[key].StoreName"
                        :error-messages="storeStoreNameErrors(key)"
                        required
                        :counter="255"
                        @input="store.StoreName.$touch()"
                        @blur="store.StoreName.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        住所
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='住所'
                        v-model="v3.StoreItems[key].Address"
                        :error-messages="storeAddressErrors(key)"
                        required
                        :counter="255"
                        @input="store.Address.$touch()"
                        @blur="store.Address.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                        電話番号
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='00000000000'
                        v-model="v3.StoreItems[key].Tel"
                        :error-messages="storeTelErrors(key)"
                        required
                        :counter="20"
                        @input="store.Tel.$touch()"
                        @blur="store.Tel.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    ><span class="red--text">* </span>
                       営業時間 
                    </label>
                    <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder='9:00~22:00'
                        v-model="v3.StoreItems[key].OpeningHours"
                        :error-messages="storeOpeningHoursErrors(key)"
                        required
                        :counter="255"
                        @input="store.OpeningHours.$touch()"
                        @blur="store.OpeningHours.$touch()"
                    >
                    </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="key > 0">
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        center
                        top
                        color="primary"
                        @click="swapStoreItem(key)"
                    >
                        <v-icon>
                        mdi-swap-vertical
                        </v-icon>
                    </v-btn>
                    </v-col>
                    <v-col>
                    <v-btn
                        fab
                        x-small
                        absolute
                        dark
                        right
                        top
                        color="red"
                        @click="deleteStoreItem(key)"
                    >
                        <v-icon>
                        mdi-delete
                        </v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
                </v-card-text>
            </v-card>
            </div>
            <v-btn
            tile
            outlined
            class="mb-5"
            color="green"
            @click="addStoreItem"
            >
            <v-icon left>
                mdi-table-row-plus-after
            </v-icon>
            入力欄の追加
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="12" class="mt-2 py-0">
            <label
            class="label-color font-weight-600 mb-2 d-block text-h2"
            >
            採用情報
            </label>
            <v-row>
                <v-col cols="12" md="6" class="py-0">
                <div>
                    <label
                    class="label-color font-weight-600 mb-2 d-block"
                    >
                    <span class="red--text">* </span>採用情報 画像
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                        >
                            mdi-help-circle-outline
                        </v-icon>
                        </template>
                        <span>
                            採用情報に表示される画像または動画ファイルを指定します。<br/>
                        </span>
                    </v-tooltip>
                    </label>
                    <v-input
                    :error-messages="recruitImageErrors()"
                >
                <v-file-input
                type="file"
                    outlined
                    class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                    :placeholder="isUpdate && v3.RecruitImageFile ? '' :'例）image'"
                    accept="image/*,video/mp4,video/x-m4v,video/*"
                    hide-details
                    required
                    @change="(e) => uploadFile(e, 'recruitImage')"
                    @input="$v.V3.RecruitImageFileUrl.$touch()"
                    @blur="$v.V3.RecruitImageFileUrl.$touch()"
                    >
                    <template v-if="isUpdate && v3.RecruitImageFile && v3.RecruitImage.entries().next().done" #prepend-inner>
                    {{v3.RecruitImageFile}}
                    </template>
                </v-file-input>
                </v-input>
                </div>
                    <div class="d-flex justify-center" v-if="v3.RecruitImageFileUrl">
                        <img :src="v3.RecruitImageFileUrl" alt="Uploaded Image" class="image-styles" />
                    </div>            
                </v-col>
                
                <v-col cols="12" md="6" class="py-0">
                    <label
                        class="label-color font-weight-600 mb-2 d-block"
                    >
                        <span class="red--text">* </span> 採用情報 本文
                    </label>
                <v-input
                    :error-messages="recruitDescriptionErrors()"
                >
                    <vue-editor
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="人気の秘訣"
                        v-model="v3.RecruitDescription"
                        :editorToolbar="customToolbarDetail"
                        required
                        @input="$v.V3.RecruitDescription.$touch()"
                        @blur="$v.V3.RecruitDescription.$touch()"
                    ></vue-editor>
                </v-input>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</v-container>

</template>

<script>

    import Vue from "vue";
    import axios from "axios";
    import dayjs from "dayjs";
    import { mapActions } from "vuex";
    import { VueEditor } from "vue2-editor";
    import { validationMixin } from "vuelidate";
    import { required, maxLength } from "vuelidate/lib/validators";
    import { getLength } from "@/util/maxLengthValidation"
    import Overlay from '../../components/Overlay.vue';
    import CUSTOM_TOOLBAR from "@/const/richTextToolsBar.js";

    Vue.use(VueEditor);

    export default {
        name: "V3",
        components: {
            VueEditor,
            Overlay,
        },
        props: {
            V3: {
                type: Object,
                default: () => ({
                    TopImageTitle: "",
                    TopImageItems: [{
                        ImageFilename: "",
                        TopImageUrl : "",
                        TopImage : new FormData(),
                    }],
                    IntroductionTitle: "",
                    IntroductionDescription: "",
                    IntroductionFileName: "",
                    IntroductionFileUrl: "",
                    IntroductionFile: new FormData(),
                    IntroductionFileType: "image",
                    InterviewItems: [{
                        FileName: "",
                        FileUrl : "",
                        File : new FormData(),
                        FileType: "image",
                        Title: "",
                        Contents: "",
                    }],
                    CheckPointItems: [{
                        FileName: "",
                        FileUrl : "",
                        File : new FormData(),
                        Title: "",
                        Contents: "",
                    }],
                    FoodImageItems: [{
                        FoodImageName: "",
                        FoodImageUrl : "",
                        FoodImage : new FormData(),
                    }],
                    StoreItems: [{
                        FileName: "",
                        FileUrl : "",
                        File : new FormData(),
                        StoreName: "",
                        Address: "",
                        Tel: "",
                        OpeningHours: "",
                    }],
                    RecruitImage:  new FormData(),
                    RecruitImageFile:  "",
                    RecruitImageFileUrl: "",
                    RecruitDescription: "",
                })
            },
            isUpdate: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                customToolbarDetail: CUSTOM_TOOLBAR.info,
            }
        },
        mixins: [validationMixin],
        methods: {
            topImageTitleErrors() {
                const errors = [];
                if (!this.$v.V3.TopImageTitle.$dirty) return errors;
                !this.$v.V3.TopImageTitle.required && errors.push("入力必須項目です");
                !this.$v.V3.TopImageTitle.maxLength && errors.push("255文字以内で入力してください");
                return errors;
            },
            topImageItemErrors(key) {
                const errors = [];
                if (!this.$v.V3.TopImageItems.$each[key].TopImageUrl.$dirty) return errors;
                !this.$v.V3.TopImageItems.$each[key].TopImageUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            introductionTitleErrors() {
                const errors = [];
                if (!this.$v.V3.IntroductionTitle.$dirty) return errors;
                !this.$v.V3.IntroductionTitle.required && errors.push("入力必須項目です");
                !this.$v.V3.IntroductionTitle.maxLength && errors.push("255文字以内で入力してください");
                return errors;
            },
            introductionDescriptionErrors() {
                const errors = [];
                if (!this.$v.V3.IntroductionDescription.$dirty) return errors;
                !this.$v.V3.IntroductionDescription.required && errors.push("入力必須項目です");
                const length = getLength(this.V3.IntroductionDescription);
                length > 30000 && errors.push("30000文字以内で入力してください");
                return errors;
            },
            introductionImageErrors() {
                const errors = [];
                if (!this.$v.V3.IntroductionFileUrl.$dirty) return errors;
                !this.$v.V3.IntroductionFileUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            foodImageItemErrors(key) {
                const errors = [];
                if (!this.$v.V3.FoodImageItems.$each[key].FoodImageUrl.$dirty) return errors;
                !this.$v.V3.FoodImageItems.$each[key].FoodImageUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            storeFileErrors(key) {
                const errors = [];
                if (!this.$v.V3.StoreItems.$each[key].FileUrl.$dirty) return errors;
                !this.$v.V3.StoreItems.$each[key].FileUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            storeStoreNameErrors(key) {
                const errors = [];
                if (!this.$v.V3.StoreItems.$each[key].StoreName.$dirty) return errors;
                !this.$v.V3.StoreItems.$each[key].StoreName.required && errors.push("店舗名は必須項目です");
                !this.$v.V3.StoreItems.$each[key].StoreName.maxLength && errors.push("店舗名は255文字以内で入力してください");
                return errors;
            },
            storeAddressErrors(key) {
                const errors = [];
                if (!this.$v.V3.StoreItems.$each[key].Address.$dirty) return errors;
                !this.$v.V3.StoreItems.$each[key].Address.required && errors.push("住所は必須項目です");
                !this.$v.V3.StoreItems.$each[key].Address.maxLength && errors.push("住所は255文字以内で入力してください");
                return errors;
            },
            storeTelErrors(key) {
                const errors = [];
                if (!this.$v.V3.StoreItems.$each[key].Tel.$dirty) return errors;
                !this.$v.V3.StoreItems.$each[key].Tel.required && errors.push("電話番号は必須項目です");
                !this.$v.V3.StoreItems.$each[key].Tel.maxLength && errors.push("電話番号は20文字以内で入力してください");
                return errors;
            },
            storeOpeningHoursErrors(key) {
                const errors = [];
                if (!this.$v.V3.StoreItems.$each[key].OpeningHours.$dirty) return errors;
                !this.$v.V3.StoreItems.$each[key].OpeningHours.required && errors.push("営業時間は必須項目です");
                !this.$v.V3.StoreItems.$each[key].OpeningHours.maxLength && errors.push("営業時間は255文字以内で入力してください");
                return errors;
            },
            recruitImageErrors() {
                const errors = [];
                if (!this.$v.V3.RecruitImageFileUrl.$dirty) return errors;
                !this.$v.V3.RecruitImageFileUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            recruitDescriptionErrors() {
                const errors = [];
                if (!this.$v.V3.RecruitDescription.$dirty) return errors;
                !this.$v.V3.RecruitDescription.required && errors.push("採用情報は必須項目です");
                const length = getLength(this.V3.RecruitDescription);
                length > 30000 && errors.push("採用情報は30000文字以内で入力してください");
                return errors;
            },
            interviewImageFilenameErrors(key) {
                const errors = [];
                if (!this.$v.V3.InterviewItems.$each[key].FileUrl.$dirty) return errors;
                !this.$v.V3.InterviewItems.$each[key].FileUrl.required && errors.push("画像または動画を選択してください");
                return errors;
            },

            interviewTitleErrors(key) {
                const errors = [];
                if (!this.$v.V3.InterviewItems.$each[key].Title.$dirty) return errors;
                !this.$v.V3.InterviewItems.$each[key].Title.required && errors.push("タイトルは必須項目です");
                !this.$v.V3.InterviewItems.$each[key].Title.maxLength && errors.push("タイトルは255文字以内で入力してください");
                return errors;
            },

            interviewContentsErrors(key) {
                const errors = [];
                if (!this.$v.V3.InterviewItems.$each[key].Contents.$dirty) return errors;
                !this.$v.V3.InterviewItems.$each[key].Contents.required && errors.push("内容は必須項目です");
                const contentLength = getLength(this.V3.InterviewItems[key].Contents);
                contentLength > 255 && errors.push("内容は255文字以内で入力してください");
                return errors;
            },
            checkPointImageFilenameErrors(key) {
                const errors = [];
                if (!this.$v.V3.CheckPointItems.$each[key].FileUrl.$dirty) return errors;
                !this.$v.V3.CheckPointItems.$each[key].FileUrl.required && errors.push("画像を選択してください");
                return errors;
            },
            checkPointTitleErrors(key) {
                const errors = [];
                if (!this.$v.V3.CheckPointItems.$each[key].Title.$dirty) return errors;
                !this.$v.V3.CheckPointItems.$each[key].Title.required && errors.push("タイトルは必須項目です");
                !this.$v.V3.CheckPointItems.$each[key].Title.maxLength && errors.push("タイトルは255文字以内で入力してください");
                return errors;
            },
            checkPointContentsErrors(key) {
                const errors = [];
                if (!this.$v.V3.CheckPointItems.$each[key].Contents.$dirty) return errors;
                !this.$v.V3.CheckPointItems.$each[key].Contents.required && errors.push("内容は必須項目です");
                const contentLength = getLength(this.V3.CheckPointItems[key].Contents);
                contentLength > 30000 && errors.push("内容は30000文字以内で入力してください");
                return errors;
            },
            foodImageFilenameErrors(key) {
                const errors = [];
                if (!this.$v.V3.FoodImageItems.$each[key].FoodImageUrl.$dirty) return errors;
                !this.$v.V3.FoodImageItems.$each[key].FoodImageUrl.required && errors.push("画像を選択してください");
                return errors;
            },

            uploadFile(file, type, key) {
                if (file && type == "introductionFile") {
                    if (file.type.indexOf("image") > -1) {
                        this.v3.IntroductionFileType = 'image';
                    } else if (file.type.indexOf("video") > -1) {
                        this.v3.IntroductionFileType = 'video';
                    }
                    const introductionImageFileUrl = URL.createObjectURL(file);
                    this.v3.IntroductionFileName = file.name;
                    this.v3.IntroductionFile = new FormData();
                    this.v3.IntroductionFile.append('IntroductionFile', file);
                    this.v3.IntroductionFileUrl = introductionImageFileUrl;
                } else if(!file && type == "introductionFile"){
                    this.v3.IntroductionFileName = "";
                    this.v3.IntroductionFileUrl = "";
                    this.v3.IntroductionFile = new FormData();
                } else if (file && type == "topImage") {
                    const topImageUrl = URL.createObjectURL(file);
                    this.v3.TopImageItems[key].ImageFilename = file.name;
                    this.v3.TopImageItems[key].TopImage = new FormData();
                    this.v3.TopImageItems[key].TopImage.append('TopImage', file);
                    this.v3.TopImageItems[key].TopImageUrl = topImageUrl;
                } else if (!file && type == "topImage") {
                    this.v3.TopImageItems[key].ImageFilename = "";
                    this.v3.TopImageItems[key].TopImage = new FormData();
                    this.v3.TopImageItems[key].TopImageUrl = "";
                } else if (file && type == "interviewFile") {
                    const FileUrl = URL.createObjectURL(file);
                    this.v3.InterviewItems[key].FileName = file.name;
                    this.v3.InterviewItems[key].File = new FormData();
                    this.v3.InterviewItems[key].File.append('InterviewFile', file);
                    this.v3.InterviewItems[key].FileUrl = FileUrl;
                } else if (!file && type == "interviewFile") {
                    this.v3.InterviewItems[key].FileName = "";
                    this.v3.InterviewItems[key].File = new FormData();
                    this.v3.InterviewItems[key].FileUrl = "";
                } else if (file && type == "checkPointFile") {
                    const FileUrl = URL.createObjectURL(file);
                    this.v3.CheckPointItems[key].FileName = file.name;
                    this.v3.CheckPointItems[key].File = new FormData();
                    this.v3.CheckPointItems[key].File.append('CheckPointFile', file);
                    this.v3.CheckPointItems[key].FileUrl = FileUrl;
                } else if (!file && type == "checkPointFile") {
                    this.v3.CheckPointItems[key].FileName = "";
                    this.v3.CheckPointItems[key].File = "";
                    this.v3.CheckPointItems[key].FileUrl = "";
                } else if (file && type == "foodImage") {
                    const FileUrl = URL.createObjectURL(file);
                    this.v3.FoodImageItems[key].FoodImageName = file.name;
                    this.v3.FoodImageItems[key].FoodImage = new FormData();
                    this.v3.FoodImageItems[key].FoodImage.append('FoodImage', file);
                    this.v3.FoodImageItems[key].FoodImageUrl = FileUrl;
                } else if (!file && type == "foodImage") {
                    this.v3.FoodImageItems[key].FoodImageName = "";
                    this.v3.FoodImageItems[key].FoodImage = "";
                    this.v3.FoodImageItems[key].FoodImageUrl = "";
                } else if (file && type == "storeImage") {
                    const FileUrl = URL.createObjectURL(file);
                    this.v3.StoreItems[key].FileName = file.name;
                    this.v3.StoreItems[key].File = new FormData();
                    this.v3.StoreItems[key].File.append('StoreImage', file);
                    this.v3.StoreItems[key].FileUrl = FileUrl;
                } else if (!file && type == "storeImage") {
                    this.v3.StoreItems[key].FileName = "";
                    this.v3.StoreItems[key].File = new FormData();
                    this.v3.StoreItems[key].FileUrl = "";
                } else if (file && type == "recruitImage") {
                    const FileUrl = URL.createObjectURL(file);
                    this.v3.RecruitImageFile = file.name;
                    this.v3.RecruitImage = new FormData();
                    this.v3.RecruitImage.append('RecruitImage', file);
                    this.v3.RecruitImageFileUrl = FileUrl;
                } else if (!file && type == "recruitImage") {
                    this.v3.RecruitImageFile = "";
                    this.v3.RecruitImage = new FormData();
                    this.v3.RecruitImageFileUrl = "";
                }
            },
            enable: function() {
                this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, true);
            },
            disable: function() {
                this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, false);
            },
            swapCheckPointItem(key) {
                const arr = [this.v3.CheckPointItems[key - 1], this.v3.CheckPointItems[key]];
                this.v3.CheckPointItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteCheckPointItem(key) {
                this.v3.CheckPointItems.splice(key, 1);
            },
            addCheckPointItem() {
                this.v3.CheckPointItems.push({
                    FileName: "",
                    FileUrl : "",
                    File : new FormData(),
                    Title: "",
                    Contents: "",
                });
            },
            swapSalaryModelItem(key) {
            const arr = [this.SalaryModelItems[key - 1], this.SalaryModelItems[key]];
            this.SalaryModelItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteSalaryModelItem(key) {
            this.SalaryModelItems.splice(key, 1);
            },
            addSalaryModelItem() {
            this.SalaryModelItems.push({
                ImageFilename: "", //
                SalaryImageUrl: "",
                SalaryImage: new FormData(),
                Title: "",
                TotalAmount: "",
                SalaryBreakdownItems: [{
                Title: "",
                Amount: "",
                }],
            });
            },
            swapBreakdownItem(key, breakdownKey) {
            const arr = [this.SalaryModelItems[key].SalaryBreakdownItems[breakdownKey - 1], this.SalaryModelItems[key].SalaryBreakdownItems[breakdownKey]];
            this.SalaryModelItems[key].SalaryBreakdownItems.splice(breakdownKey - 1, 2, arr[1], arr[0]);
            },
            deleteBreakdownItem(key, breakdownKey) {
                this.SalaryModelItems[key].SalaryBreakdownItems.splice(breakdownKey, 1);
            },
            addBreakdownItem(key) {
                this.SalaryModelItems[key].SalaryBreakdownItems.push({
                    Title: "",
                    Amount: "",
                });
            },
            swapCareerStepItem(key) {
                const arr = [this.CareerStepItems[key - 1], this.CareerStepItems[key]];
                this.CareerStepItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteCareerStepItem(key) {
                this.CareerStepItems.splice(key, 1);
            },
            addCareerStepItem() {
                this.CareerStepItems.push({
                    Title: "",
                    Description: "",
                });
            },
            swapDailyScheduleItem(key) {
                const arr = [this.DailyScheduleItems[key - 1], this.DailyScheduleItems[key]];
                this.DailyScheduleItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteDailyScheduleItem(key) {
                this.DailyScheduleItems.splice(key, 1);
            },
            addDailyScheduleItem() {
                this.DailyScheduleItems.push({
                    Title: "",
                    Time: "",
                });
            },
            swapInterviewItem(key) {
                const arr = [this.v3.InterviewItems[key - 1], this.v3.InterviewItems[key]];
                this.v3.InterviewItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteInterviewItem(key) {
                this.v3.InterviewItems.splice(key, 1);
            },
            addInterviewItem() {
                this.v3.InterviewItems.push({
                    FileName: "",
                    FileUrl : "",
                    File : new FormData(),
                    FileType: "image",
                    Title: "",
                    Contents: "",
                });
            },
            swapTopImageItem(key) {
                const arr = [this.v3.TopImageItems[key - 1], this.v3.TopImageItems[key]];
                this.v3.TopImageItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteTopImageItem(key) {
                this.v3.TopImageItems.splice(key, 1);
            },
            addTopImageItem() {
                this.v3.TopImageItems.push({
                    ImageFilename: "",
                    TopImageUrl : "",
                    TopImage : new FormData(),
                });
            },
            swapFoodImageItem(key) {
                const arr = [this.v3.FoodImageItems[key - 1], this.v3.FoodImageItems[key]];
                this.v3.FoodImageItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteFoodImageItem(key) {
                this.v3.FoodImageItems.splice(key, 1);
            },
            addFoodImageItem() {
                this.v3.FoodImageItems.push({
                    FoodImageName: "",
                    FoodImageUrl : "",
                    FoodImage : new FormData(),
                });
            },

            swapStoreItem(key) {
                const arr = [this.v3.StoreItems[key - 1], this.v3.StoreItems[key]];
                this.v3.StoreItems.splice(key - 1, 2, arr[1], arr[0]);
            },
            deleteStoreItem(key) {
                this.v3.StoreItems.splice(key, 1);
            },
            addStoreItem(){
                this.v3.StoreItems.push({
                    FileName: "",
                    FileUrl : "",
                    File : new FormData(),
                    StoreName: "",
                    Address: "",
                    Tel: "",
                    OpeningHours: "",
                });
            },
            ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
        },
        validations() {
            const commonRules = {
                // TopImageFilename: { required, maxLength: maxLength(255) },
                TopImageFileUrl:  {required, maxLength: maxLength(255)},
                TopTitle: { required, maxLength: maxLength(255)},
                // CompanyImageFilename: { required, maxLength: maxLength(255) },
                CompanyImageFileUrl : { required, maxLength: maxLength(255) },
                ReasonTitle: { required, maxLength: maxLength(255) },
                MetaTags: {
                    $each: {
                        Name: { maxLength: maxLength(255) },
                        Property: { maxLength: maxLength(255) },
                        Content: { maxLength: maxLength(255) },
                    }
                },
                GalleryItems: {
                    $each: {
                        TopImageUrl: { required, maxLength: maxLength(255) },
                        // ImageFilename: { required, maxLength: maxLength(50) },
                    }
                },
                InterviewItems: {
                    $each: {
                        // ImageFilename: { required, maxLength: maxLength(50) },
                        InterviewImageUrl: { required, maxLength: maxLength(255) },
                        Title: { required, maxLength: maxLength(255) },
                        NameInitial: { required, maxLength: maxLength(255) },
                    }
                },
                V3: { 
                    TopImageTitle: { required, maxLength: maxLength(255) },
                    TopDescription: { required, maxLength: maxLength(30000) },
                    TopImageItems: { 
                        $each: { 
                            TopImageUrl: { required, maxLength: maxLength(255) },
                        }
                    },
                    IntroductionTitle: { required, maxLength: maxLength(255) },
                    IntroductionDescription: { required, maxLength: maxLength(255) },
                    IntroductionFileUrl: { required, maxLength: maxLength(255) },
                    InterviewItems: { 
                        $each: { 
                            FileUrl: { required, maxLength: maxLength(255) },
                            Title:{ required, maxLength: maxLength(255)},
                            Contents:{ required, maxLength: maxLength(255)},
                        }
                    },
                    CheckPointItems: { 
                        $each: { 
                            FileUrl: { required, maxLength: maxLength(255) },
                            Title:{ required, maxLength: maxLength(255)},
                            Contents:{ required, maxLength: maxLength(255)},
                        }
                    },
                    FoodImageItems: { 
                        $each: { 
                            FoodImageUrl: { required, maxLength: maxLength(255) },
                        }
                    },
                    StoreItems: { 
                        $each: { 
                            StoreName: { required, maxLength: maxLength(255)},
                            FileUrl: { required, maxLength: maxLength(255)},
                            Address: { required, maxLength: maxLength(255)},
                            Tel: { required, maxLength: maxLength(20) },
                            OpeningHours: { required, maxLength: maxLength(255)},
                        }
                    },
                    RecruitImageFileUrl: { required },
                    RecruitDescription: { required, maxLength: maxLength(255)},
                },
            };

            return {
            ...commonRules,
            };
        },
        computed: {
            v3: {
                get() {
                    return this.V3 || {};
                },
                set(v) {
                    this.$emit("change", v);
                },
            },
            imageUrlErrors() {
                const errors = [];
                if (!this.$v.TopImageFileUrl.$dirty) return errors;
                !this.$v.TopImageFileUrl.required && errors.push("入力必須項目です");
                return errors;
            },
            
            topDescriptionErrors() {
                const errors = [];
                if (!this.$v.TopDescription || !this.$v.TopDescription.$dirty) return errors;
                !this.$v.TopDescription.maxLength && errors.push("30000文字以内で入力してください");
                return errors;
            },
            eyecatchImageFilenameErrors() {
                const errors = [];
                if (!this.$v.EyecatchImageFileUrl || !this.$v.EyecatchImageFileUrl.$dirty) return errors;
                !this.$v.EyecatchImageFileUrl.required && errors.push("入力必須項目です");
                return errors;
            },
            eyecatchTitleErrors() {
                const errors = [];
                if (!this.$v.EyecatchTitle || !this.$v.EyecatchTitle.$dirty) return errors;
                !this.$v.EyecatchTitle.maxLength && errors.push("255文字以内で入力してください");
                !this.$v.EyecatchTitle.required && errors.push("入力必須項目です");
                return errors;
            },
            companyImageFilenameErrors() {
                const errors = [];
                if (!this.$v.CompanyImageFileUrl.$dirty) return errors;
                !this.$v.CompanyImageFileUrl.required && errors.push("入力必須項目です");
                return errors;
            },
            companyTitleErrors() {
                const errors = [];
                if (!this.$v.CompanyTitle || !this.$v.CompanyTitle.$dirty) return errors;
                !this.$v.CompanyTitle.maxLength && errors.push("255文字以内で入力してください");
                !this.$v.CompanyTitle.required && errors.push("入力必須項目です");
                return errors;
            },
            companyDescriptionErrors() {
                const errors = [];
                if (!this.$v.CompanyDescription || !this.$v.CompanyDescription.$dirty) return errors;
                !this.$v.CompanyDescription.maxLength && errors.push("30000文字以内で入力してください");
                !this.$v.CompanyDescription.required && errors.push("入力必須項目です");
                return errors;
            },
            greetingImageFilenameErrors() {
                const errors = [];
                if (!this.$v.GreetingImageFileUrl || !this.$v.GreetingImageFileUrl.$dirty) return errors;
                !this.$v.GreetingImageFileUrl.required && errors.push("入力必須項目です");
                return errors;
            },
            greetingTitleErrors() {
                const errors = [];
                if (!this.$v.GreetingTitle || !this.$v.GreetingTitle.$dirty) return errors;
                !this.$v.GreetingTitle.maxLength && errors.push("255文字以内で入力してください");
                !this.$v.GreetingTitle.required && errors.push("入力必須項目です");
                return errors;
            },
            greetingNameErrors() {
                const errors = [];
                if (!this.$v.GreetingName || !this.$v.GreetingName.$dirty) return errors;
                !this.$v.GreetingName.maxLength && errors.push("255文字以内で入力してください");
                !this.$v.GreetingName.required && errors.push("入力必須項目です");
                return errors;
            },
            greetingDescriptionErrors() {
                const errors = [];
                if (!this.$v.GreetingDescription || !this.$v.GreetingDescription.$dirty) return errors;
                !this.$v.GreetingDescription.maxLength && errors.push("30000文字以内で入力してください");
                !this.$v.GreetingDescription.required && errors.push("入力必須項目です");
                return errors;
            },
            topCompanyNameErrors() {
                const errors = [];
                if (!this.$v.TopCompanyName || !this.$v.TopCompanyName.$dirty) return errors;
                !this.$v.TopCompanyName.maxLength && errors.push("255文字以内で入力してください");
                return errors;
            },
            aboutEntireTitleErrors() {
                const errors = [];
                if (!this.$v.AboutTitle || !this.$v.AboutTitle.$dirty) return errors;
                !this.$v.AboutTitle.maxLength && errors.push("255文字以内で入力してください");
                !this.$v.AboutTitle.required && errors.push("入力必須項目です");
                return errors;
            },
        },
        mounted() {

        },

    }
</script>

<style>
  .custom-file-input >>> .v-input__append-inner,
  .custom-file-input >>> .v-input__prepend-outer {
    margin: 10px 5px;
  }
  .image-styles {
    max-width : 100%;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
</style>
