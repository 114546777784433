<template>
  <div>
    <header-top-dashboard primary>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n13">
      <Overlay :loading="getLoading" />
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                求人トップ更新
              </div>
              <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
                求人トップを更新します。
              </p>
            </div>

            <v-card-text class="card-padding">
              <v-form class="mt-13" ref="topForm">
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >
                    <span class="red--text">* </span>テンプレートバージョン
                    </label>
                    <v-select
                      v-model="TemplateVersion"
                      :items="templateVersions"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="d-flex justify-end">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="preview()"
                            elevation="0"
                            :ripple="false"
                            height="42"
                            min-width="36"
                            width="42"
                            class="font-weight-600 text-capitalize btn-ls btn-warningrounded-sm"
                            outlined
                            v-on="on"
                            color="grey"
                          >
                            <v-icon size="28">mdi-file-find</v-icon>
                          </v-btn>
                        </template>
                      <span>トップ記事のプレビューを表示する</span>
                    </v-tooltip>
                  </div>
                  </v-col>
                </v-row>
                <template
                  v-if="TemplateVersion === 'v1'"
                >
                  <v-row>
                    <v-col cols="12" md="6" class="py-0">

                    <div>
                     <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span>トップ画像・動画ファイル名
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            トップに表示される画像または動画ファイルを指定します。<br/>
                            動画ファイルはMP4形式のみ利用可能です。
                          </span>
                        </v-tooltip>
                      </label>
                     <v-input
                      :error-messages="imageUrlErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="isUpdate && TopImageFilename ? '' :'例）image'"
                      accept="image/*,video/mp4,video/x-m4v,video/*"
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'topImageFile')"
                      @input="$v.TopImageFileUrl.$touch()"
                      @blur="$v.TopImageFileUrl.$touch()"
                     >
                      <template v-if="isUpdate && TopImageFilename && TopImageFile.entries().next().done" #prepend-inner>
                        {{TopImageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                      <div v-if="TopImageFileUrl && TopImageFileType === 'video'" :key="TopImageFileUrl">
                        <video controls width="100%"  height="300px" class="video-styles">
                          <source :src="TopImageFileUrl" type="video/mp4" />
                        </video>
                      </div>
                      <div v-if="TopImageFileUrl && TopImageFileType === 'image'" class="d-flex justify-center mb-2">
                        <img :src="TopImageFileUrl" alt="Uploaded Image" class="image-styles" />
                      </div>                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> トップタイトル
                      </label>
                    <v-input
                      :error-messages="topTitleErrors"
                      class="mb-6"
                    >
                      <vue-editor
                        outlined
                        :editorToolbar="customToolbarDetail"
                        class="black--text"
                        placeholder="例）すべてのお客様を笑顔にするあ"
                        v-model="TopTitle"
                        @input="$v.TopTitle.$touch()"
                        @blur="$v.TopTitle.$touch()"
                      ></vue-editor>
                    </v-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <div>
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      ><span class="red--text">* </span>アイキャッチ画像ファイル名</label
                      >
                     <v-input
                      :error-messages="eyecatchImageFilenameErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                       :placeholder="isUpdate && EyecatchImageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'eyecatchImageFile')"
                      @input="$v.EyecatchImageFileUrl.$touch()"
                      @blur="$v.EyecatchImageFileUrl.$touch()"
                     >
                      <template v-if="isUpdate && EyecatchImageFilename && EyecatchImageFile.entries().next().done" #prepend-inner>
                       {{EyecatchImageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center">
                      <img :src="EyecatchImageFileUrl" alt="Uploaded Image" v-if="EyecatchImageFileUrl" class="image-styles" />
                    </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> アイキャッチタイトル
                      </label>
                      <v-textarea
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="例）明るく働きやすい環境が魅力です。"
                        v-model="EyecatchTitle"
                        :error-messages="eyecatchTitleErrors"
                        :counter="255"
                        required
                        @input="$v.EyecatchTitle.$touch()"
                        @blur="$v.EyecatchTitle.$touch()"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        アイキャッチタイトルのハイライトカラー
                      </label>
                      <v-color-picker
                        v-model="EyecatchTitleColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <div>
                        <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 会社情報の画像ファイル名
                      </label>
                     <v-input
                      :error-messages="companyImageFilenameErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="isUpdate && CompanyImageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'companyImageFile')"
                      @input="$v.CompanyImageFileUrl.$touch()"
                      @blur="$v.CompanyImageFileUrl.$touch()"
                     >
                     <template v-if="isUpdate && CompanyImageFilename && CompanyImageFile.entries().next().done" #prepend-inner>
                      {{CompanyImageFilename}}
                    </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center" v-if="CompanyImageFileUrl">
                      <img :src="CompanyImageFileUrl" alt="Uploaded Image"  class="image-styles" />
                    </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >トップ会社名</label>
                      <v-input
                        class="mb-6"
                        :error-messages="topCompanyNameErrors"
                        >
                        <vue-editor
                          outlined
                          class="black--text"
                          :editorToolbar="customToolbarDetail"
                          placeholder='株式会社〇〇'
                          v-model="TopCompanyName"
                          @input="$v.TopCompanyName.$touch()"
                          @blur="$v.TopCompanyName.$touch()"
                        ></vue-editor>
                      </v-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      株式会社○○とは？
                      </label>
                      <v-col cols="12" md="6" class="py-0">
                        <label
                          class="label-color font-weight-600 mb-2 d-block"
                        >
                          <span class="red--text">* </span> タイトル
                        </label>
                        <v-text-field
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="株式会社トリプルスリーとは？"
                          v-model="AboutTitle"
                          :error-messages="aboutEntireTitleErrors"
                          :counter="255"
                          required
                          @input="$v.AboutTitle.$touch()"
                          @blur="$v.AboutTitle.$touch()"
                        >
                        </v-text-field>
                      </v-col>
                      <div v-if="AboutItems && AboutItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(about, key) in $v.AboutItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                              <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>画像名</label>
                                <v-input
                                  :error-messages="aboutImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && AboutItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'aboutImage', key)"
                                  @input="about.AboutImageUrl.$touch()"
                                  @blur="about.AboutImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && AboutItems[key].ImageFilename &&  AboutItems[key].AboutImage.entries().next().done" #prepend-inner>
                                  {{ AboutItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2">
                                  <img :src="AboutItems[key].AboutImageUrl" alt="Uploaded Image"  v-if="AboutItems[key].AboutImageUrl" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>タイトル</label
                                >
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）スタッフ募集中'
                                  v-model="about.Title.$model"
                                  :error-messages="aboutTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="about.Title.$touch()"
                                  @blur="about.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  内容
                                </label>
                                <v-input
                                  :error-messages="aboutContentsErrors(key)"
                                  class="mb-6"
                                >
                                  <vue-editor
                                    outlined
                                    required
                                    class="black--text"
                                    placeholder='内容を入力'
                                    :editorToolbar="customToolbarDetail"
                                    :counter="30000"
                                    v-model="about.Contents.$model"
                                    @input="about.Contents.$touch()"
                                    @blur="about.Contents.$touch()"
                                  ></vue-editor>
                                </v-input>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapAboutItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteAboutItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addAboutItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      会社の強み・入社のメリット・人気の秘訣
                      </label>
                      <v-col cols="12" md="6" class="py-0">
                        <label
                          class="label-color font-weight-600 mb-2 d-block"
                        >
                          <span class="red--text">* </span> タイトル
                        </label>
                        <v-text-field
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="人気の秘訣"
                          v-model="ReasonTitle"
                          :error-messages="reasonEntireTitleErrors"
                          :counter="255"
                          required
                          @input="$v.ReasonTitle.$touch()"
                          @blur="$v.ReasonTitle.$touch()"
                        >
                        </v-text-field>
                      </v-col>
                      <div v-if="ReasonItems && ReasonItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(reason, key) in $v.ReasonItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>画像名</label>
                                <v-input
                                  :error-messages="reasonImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && ReasonItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'reasonImage', key)"
                                  @input="reason.ReasonImageUrl.$touch()"
                                  @blur="reason.ReasonImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && ReasonItems[key].ImageFilename &&  ReasonItems[key].ReasonImage.entries().next().done" #prepend-inner>
                                  {{ ReasonItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" >
                                  <img :src="ReasonItems[key].ReasonImageUrl" alt="Uploaded Image" v-if="ReasonItems[key].ReasonImageUrl" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  タイトル
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）スタッフ募集中'
                                  v-model="reason.Title.$model"
                                  :error-messages="reasonTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="reason.Title.$touch()"
                                  @blur="reason.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <div>
                                  <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >ヘッダー</label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）人気の秘密'
                                  v-model="reason.Header.$model"
                                  :error-messages="reasonHeaderErrors(key)"
                                  required
                                  :counter="255"
                                  @input="reason.Header.$touch()"
                                  @blur="reason.Header.$touch()"
                                >
                                  </v-text-field>
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">

                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  内容
                                </label>
                                <v-input
                                    :error-messages="reasonContentsErrors(key)"
                                    class="mb-6"
                                >
                                <vue-editor
                                      outlined
                                      required
                                      :editorToolbar="customToolbarDetail"
                                      class="black--text"
                                      placeholder='内容を入力'
                                      :counter="30000"
                                      v-model="reason.Contents.$model"
                                      @input="reason.Contents.$touch()"
                                      @blur="reason.Contents.$touch()"
                                  ></vue-editor>
                                </v-input>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapReasonItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteReasonItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addReasonItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      メタデータ
                      </label>
                      <div v-if="MetaTags && MetaTags.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(tag, key) in $v.MetaTags.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  name
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="description"
                                  v-model="tag.Name.$model"
                                  :error-messages="metaTagNameErrors(key)"
                                  :counter="255"
                                  @input="tag.Name.$touch()"
                                  @blur="tag.Name.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  property
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="og:image"
                                  v-model="tag.Property.$model"
                                  :error-messages="metaTagPropertyErrors(key)"
                                  :counter="255"
                                  @input="tag.Property.$touch()"
                                  @blur="tag.Property.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  content
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="株式会社トリプルスリー"
                                  v-model="tag.Content.$model"
                                  :error-messages="metaTagContentErrors(key)"
                                  :counter="255"
                                  @input="tag.Content.$touch()"
                                  @blur="tag.Content.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteMetaTag(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addMetaTag"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        正社員求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="FulltimeButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        パート・アルバイト求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="ParttimeButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        契約社員求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="ContractButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        新卒採用求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="NewgraduateButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        中途採用求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="CareerButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        キャリア採用求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="CareerRecruitmentButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                      すべての求人リンクカラー
                      </label>
                      <v-color-picker
                        v-model="AllButtonColor"
                        hide-canvas
                        mode="rgba"
                        show-swatches
                        swatches-max-height="151"
                      ></v-color-picker>
                    </v-col>
                  </v-row>
                </template>
                <template
                  v-if="TemplateVersion === 'v2'"
                >
                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <div>
                         <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span>トップ画像・動画ファイル名
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            トップに表示される画像または動画ファイルを指定します。<br/>
                            動画ファイルはMP4形式のみ利用可能です。
                          </span>
                        </v-tooltip>
                      </label>
                      <v-input
                      :error-messages="imageUrlErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="isUpdate && TopImageFilename ? '' :'例）image'"
                      accept="image/*,video/mp4,video/x-m4v,video/*"
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'topImageFile')"
                      @input="$v.TopImageFileUrl.$touch()"
                      @blur="$v.TopImageFileUrl.$touch()"
                     >
                      <template v-if="isUpdate && TopImageFilename && TopImageFile.entries().next().done" #prepend-inner>
                        {{TopImageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div v-if="TopImageFileUrl && TopImageFileType === 'video'" :key="TopImageFileUrl">
                      <video controls width="100%"  height="300px" class="video-styles">
                        <source :src="TopImageFileUrl" type="video/mp4" />
                      </video>
                    </div>
                    <div v-if="TopImageFileUrl && TopImageFileType === 'image'" class="d-flex justify-center mb-2">
                      <img :src="TopImageFileUrl" alt="Uploaded Image" class="image-styles" />
                    </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> トップタイトル
                      </label>
                      <v-input
                        :error-messages="topTitleErrors"
                        class="mb-6"
                      >
                      <vue-editor
                          outlined
                          :editorToolbar="customToolbarDetail"
                          class="black--text"
                          placeholder="例）すべてのお客様を笑顔にする"
                          v-model="TopTitle"
                          @input="$v.TopTitle.$touch()"
                          @blur="$v.TopTitle.$touch()"
                        ></vue-editor>
                      </v-input>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        トップ説明文
                      </label>
                      <vue-editor
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="トップに表出される説明文"
                        v-model="TopDescription"
                        :error-messages="topDescriptionErrors"
                        :counter="30000"
                        @input="$v.TopDescription.$touch()"
                        @blur="$v.TopDescription.$touch()"
                      >
                      </vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-2 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      人気の秘訣
                      </label>
                      <v-col cols="12" md="6" class="py-0">
                        <label
                          class="label-color font-weight-600 mb-2 d-block"
                        >
                          <span class="red--text">* </span> タイトル
                        </label>
                        <v-text-field
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="人気の秘訣"
                          v-model="ReasonTitle"
                          :error-messages="reasonEntireTitleErrors"
                          :counter="255"
                          required
                          @input="$v.ReasonTitle.$touch()"
                          @blur="$v.ReasonTitle.$touch()"
                        >
                        </v-text-field>
                      </v-col>
                      <div v-if="ReasonItems && ReasonItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(reason, key) in $v.ReasonItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>画像名</label>
                                <v-input
                                  :error-messages="reasonImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && ReasonItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  v-model="reason.ReasonImage"
                                  @change="(e) => uploadFile(e, 'reasonImage', key)"
                                  @input="reason.ReasonImageUrl.$touch()"
                                  @blur="reason.ReasonImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && ReasonItems[key].ImageFilename &&  ReasonItems[key].ReasonImage.entries().next().done" #prepend-inner>
                                  {{ ReasonItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" >
                                  <img :src="ReasonItems[key].ReasonImageUrl" alt="Uploaded Image" v-if="ReasonItems[key].ReasonImageUrl" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  タイトル
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）福利厚生が充実'
                                  v-model="reason.Title.$model"
                                  :error-messages="reasonTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="reason.Title.$touch()"
                                  @blur="reason.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>内容</label
                                >
                                <v-textarea
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="内容を入力"
                                  v-model="reason.Contents.$model"
                                  :counter="30000"
                                  :error-messages="reasonContentsErrors(key)"
                                  required
                                  @input="reason.Contents.$touch()"
                                  @blur="reason.Contents.$touch()"
                                >
                                </v-textarea>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapReasonItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteReasonItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addReasonItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      年収モデル
                      </label>
                      <div v-if="SalaryModelItems && SalaryModelItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(salary, key) in $v.SalaryModelItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                              <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  画像名
                                </label>
                                <v-input
                                  :error-messages="salaryImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && SalaryModelItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'salaryImage', key)"
                                  @input="salary.SalaryImageUrl.$touch()"
                                  @blur="salary.SalaryImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && SalaryModelItems[key].ImageFilename &&  SalaryModelItems[key].SalaryImage.entries().next().done" #prepend-inner>
                                  {{ SalaryModelItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" v-if="SalaryModelItems[key].SalaryImageUrl">
                                  <img :src="SalaryModelItems[key].SalaryImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  タイトル
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）入社1年目'
                                  v-model="salary.Title.$model"
                                  :error-messages="salaryTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="salary.Title.$touch()"
                                  @blur="salary.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  年収
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）400(単位なし)'
                                  v-model="salary.TotalAmount.$model"
                                  :error-messages="salaryTotalAmountErrors(key)"
                                  required
                                  :counter="255"
                                  @input="salary.TotalAmount.$touch()"
                                  @blur="salary.TotalAmount.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  内訳
                                </label>
                                <div v-if="SalaryModelItems[key].SalaryBreakdownItems && SalaryModelItems[key].SalaryBreakdownItems.length > 0">
                                  <v-card class="card-shadow mb-6 pt-6" v-for="(breakdown, breakdownKey) in salary.SalaryBreakdownItems.$each.$iter" :key="key + '_' + breakdownKey">
                                    <v-card-text class="card-padding">
                                      <v-row>
                                        <v-col cols="12" md="6" class="py-0">
                                          <label
                                            class="label-color font-weight-600 mb-2 d-block"
                                          ><span class="red--text">* </span>
                                            内訳名
                                          </label>
                                          <v-text-field
                                            outlined
                                            class="input-style font-size-input text-light-input placeholder-light"
                                            placeholder='例）月給'
                                            v-model="breakdown.Title.$model"
                                            :error-messages="breakdownTitleErrors(key, breakdownKey)"
                                            required
                                            :counter="255"
                                            @input="breakdown.Title.$touch()"
                                            @blur="breakdown.Title.$touch()"
                                          >
                                          </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" class="py-0">
                                          <label
                                            class="label-color font-weight-600 mb-2 d-block"
                                          ><span class="red--text">* </span>
                                            金額
                                          </label>
                                          <v-text-field
                                            outlined
                                            class="input-style font-size-input text-light-input placeholder-light"
                                            placeholder='例）30(単位なし)'
                                            v-model="breakdown.Amount.$model"
                                            :error-messages="breakdownAmountErrors(key, breakdownKey)"
                                            required
                                            :counter="255"
                                            @input="breakdown.Amount.$touch()"
                                            @blur="breakdown.Amount.$touch()"
                                          >
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col v-if="breakdownKey > 0">
                                          <v-btn
                                            fab
                                            x-small
                                            absolute
                                            dark
                                            center
                                            top
                                            color="primary"
                                            @click="swapBreakdownItem(key, breakdownKey)"
                                          >
                                            <v-icon>
                                              mdi-swap-vertical
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                        <v-col>
                                          <v-btn
                                            fab
                                            x-small
                                            absolute
                                            dark
                                            right
                                            top
                                            color="red"
                                            @click="deleteBreakdownItem(key, breakdownKey)"
                                          >
                                            <v-icon>
                                              mdi-delete
                                            </v-icon>
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </div>
                                <v-btn
                                  tile
                                  outlined
                                  class="mb-5"
                                  color="green"
                                  @click="addBreakdownItem(key)"
                                >
                                  <v-icon left>
                                    mdi-table-row-plus-after
                                  </v-icon>
                                  入力欄の追加
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapSalaryModelItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteSalaryModelItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addSalaryModelItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      キャリアステップ
                      </label>
                      <div v-if="CareerStepItems && CareerStepItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(step, key) in $v.CareerStepItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>役職名</label
                                >
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）店長'
                                  v-model="step.Title.$model"
                                  :error-messages="stepTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="step.Title.$touch()"
                                  @blur="step.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  内容
                                </label>
                                <v-textarea
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="内容を入力"
                                  v-model="step.Description.$model"
                                  :counter="30000"
                                  :error-messages="stepDescriptionErrors(key)"
                                  required
                                  @input="step.Description.$touch()"
                                  @blur="step.Description.$touch()"
                                >
                                </v-textarea>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapCareerStepItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteCareerStepItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addCareerStepItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      一日の流れ
                      </label>
                      <div v-if="DailyScheduleItems && DailyScheduleItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(schedule, key) in $v.DailyScheduleItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  時刻
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）10:00'
                                  v-model="schedule.Time.$model"
                                  :error-messages="scheduleTimeErrors(key)"
                                  required
                                  :counter="255"
                                  @input="schedule.Time.$touch()"
                                  @blur="schedule.Time.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  予定
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）仕込み'
                                  v-model="schedule.Title.$model"
                                  :error-messages="scheduleTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="schedule.Title.$touch()"
                                  @blur="schedule.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapDailyScheduleItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteDailyScheduleItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addDailyScheduleItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      インタビュー
                      </label>
                      <div v-if="InterviewItems && InterviewItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(interview, key) in $v.InterviewItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">

                                  <div>
                            <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  画像名
                                </label>
                                <v-input
                                  :error-messages="interviewImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && InterviewItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'interviewImage', key)"
                                  @input="interview.InterviewImageUrl.$touch()"
                                  @blur="interview.InterviewImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && InterviewItems[key].ImageFilename &&  InterviewItems[key].InterviewImage.entries().next().done" #prepend-inner>
                                  {{ InterviewItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center mb-2" v-if="InterviewItems[key].InterviewImageUrl">
                                  <img :src="InterviewItems[key].InterviewImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  タイトル
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）企業の雰囲気を肌で感じ、入社を決めました。'
                                  v-model="interview.Title.$model"
                                  :error-messages="interviewTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="interview.Title.$touch()"
                                  @blur="interview.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  名前
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例) K.S'
                                  v-model="interview.NameInitial.$model"
                                  :error-messages="interviewNameInitialErrors(key)"
                                  required
                                  :counter="255"
                                  @input="interview.NameInitial.$touch()"
                                  @blur="interview.NameInitial.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapInterviewItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteInterviewItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addInterviewItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                        ギャラリー
                      </label>
                      <div v-if="GalleryItems && GalleryItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(gallery, key) in $v.GalleryItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  画像名
                                </label>
                                <v-input
                                  :error-messages="galleryImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && GalleryItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  @change="(e) => uploadFile(e, 'galleryImage', key)"
                                  @input="gallery.GalleryImageUrl.$touch()"
                                  @blur="gallery.GalleryImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && GalleryItems[key].ImageFilename &&  GalleryItems[key].GalleryImage.entries().next().done" #prepend-inner>
                                  {{ GalleryItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center" v-if="GalleryItems[key].GalleryImageUrl">
                                  <img :src="GalleryItems[key].GalleryImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapGalleryItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteGalleryItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addGalleryItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <div>
                        <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 代表挨拶画像ファイル名
                      </label>
                       <v-input
                      :error-messages="greetingImageFilenameErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                       :placeholder="isUpdate && GreetingImageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'greetingImageFile')"
                      @input="$v.GreetingImageFileUrl.$touch()"
                      @blur="$v.GreetingImageFileUrl.$touch()"
                     >
                       <template v-if="isUpdate && GreetingImageFilename && GreetingImageFile.entries().next().done" #prepend-inner>
                        {{GreetingImageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                      </div>
                      <div class="d-flex justify-center">
                      <img :src="GreetingImageFileUrl" alt="Uploaded Image" v-if="GreetingImageFileUrl" class="image-styles" />
                    </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 代表挨拶タイトル
                      </label>
                      <v-textarea
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="GreetingTitle"
                        :error-messages="greetingTitleErrors"
                        :counter="255"
                        required
                        @input="$v.GreetingTitle.$touch()"
                        @blur="$v.GreetingTitle.$touch()"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 代表名
                      </label>
                      <v-text-field
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="GreetingName"
                        :error-messages="greetingNameErrors"
                        :counter="255"
                        required
                        @input="$v.GreetingName.$touch()"
                        @blur="$v.GreetingName.$touch()"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 代表挨拶文
                      </label>
                      <vue-editor
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="代表の挨拶文"
                        v-model="GreetingDescription"
                        :error-messages="greetingDescriptionErrors"
                        :counter="30000"
                        required
                        @input="$v.GreetingDescription.$touch()"
                        @blur="$v.GreetingDescription.$touch()"
                      >
                      </vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" class="py-0 my-3">
                       <div>
                        <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 会社情報の画像ファイル名
                      </label>
                     <v-input
                      :error-messages="companyImageFilenameErrors"
                    >
                    <v-file-input
                    type="file"
                     outlined
                      class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                      :placeholder="isUpdate && CompanyImageFilename ? '' :'例）image'"
                      accept="image/*" 
                      hide-details
                      required
                      @change="(e) => uploadFile(e, 'companyImageFile')"
                      @input="$v.CompanyImageFileUrl.$touch()"
                      @blur="$v.CompanyImageFileUrl.$touch()"
                     >
                      <template v-if="isUpdate && CompanyImageFilename && CompanyImageFile.entries().next().done" #prepend-inner>
                        {{CompanyImageFilename}}
                      </template>
                    </v-file-input>
                    </v-input>
                    </div>
                    <div class="d-flex justify-center" v-if="CompanyImageFileUrl">
                      <img :src="CompanyImageFileUrl" alt="Uploaded Image"  class="image-styles" />
                    </div>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 会社紹介タイトル
                      </label>
                      <v-textarea
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="CompanyTitle"
                        :error-messages="companyTitleErrors"
                        :counter="255"
                        required
                        @input="$v.CompanyTitle.$touch()"
                        @blur="$v.CompanyTitle.$touch()"
                      >
                      </v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-3">
                      <label
                        class="label-color font-weight-600 mb-2 d-block"
                      >
                        <span class="red--text">* </span> 会社紹介文
                      </label>
                      <vue-editor
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        placeholder="会社について説明する紹介文"
                        v-model="CompanyDescription"
                        :error-messages="companyDescriptionErrors"
                        :counter="30000"
                        required
                        @input="$v.CompanyDescription.$touch()"
                        @blur="$v.CompanyDescription.$touch()"
                      >
                      </vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      選考フロー
                      </label>
                      <div v-if="HiringProcessItems && HiringProcessItems.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(process, key) in $v.HiringProcessItems.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">

                                <div>
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>画像名</label
                                >
                                <v-input
                                  :error-messages="processImageFilenameErrors(key)"
                                >
                                <v-file-input
                                type="file"
                                outlined
                                  class="custom-file-input input-style font-size-input text-light-input placeholder-light"
                                  :placeholder="isUpdate && HiringProcessItems[key].ImageFilename ? '' :'例）image'"
                                  accept="image/*" 
                                  hide-details
                                  required
                                  
                                  @change="(e) => uploadFile(e, 'processImage', key)"
                                  @input="process.ProcessImageUrl.$touch()"
                                  @blur="process.ProcessImageUrl.$touch()"
                                >
                                <template v-if="isUpdate && HiringProcessItems[key].ImageFilename &&  HiringProcessItems[key].ProcessImage.entries().next().done" #prepend-inner>
                                  {{ HiringProcessItems[key].ImageFilename }}
                                </template>
                                </v-file-input>
                                </v-input>
                                </div>
                                <div class="d-flex justify-center" v-if="HiringProcessItems[key].ProcessImageUrl">
                                  <img :src="HiringProcessItems[key].ProcessImageUrl" alt="Uploaded Image" class="image-styles" />
                                </div>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                ><span class="red--text">* </span>
                                  タイトル
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder='例）一次面接'
                                  v-model="process.Title.$model"
                                  :error-messages="processTitleErrors(key)"
                                  required
                                  :counter="255"
                                  @input="process.Title.$touch()"
                                  @blur="process.Title.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col v-if="key > 0">
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  center
                                  top
                                  color="primary"
                                  @click="swapHiringProcessItem(key)"
                                >
                                  <v-icon>
                                    mdi-swap-vertical
                                  </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteHiringProcessItem(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addHiringProcessItem"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-0 py-0">
                      <label
                        class="label-color font-weight-600 mb-2 d-block text-h2"
                      >
                      メタデータ
                      </label>
                      <div v-if="MetaTags && MetaTags.length > 0">
                        <v-card class="card-shadow mb-6 pt-6" v-for="(tag, key) in $v.MetaTags.$each.$iter" :key="key">
                          <v-card-text class="card-padding">
                            <v-row>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  name
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="description"
                                  v-model="tag.Name.$model"
                                  :error-messages="metaTagNameErrors(key)"
                                  :counter="255"
                                  @input="tag.Name.$touch()"
                                  @blur="tag.Name.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  property
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="og:image"
                                  v-model="tag.Property.$model"
                                  :error-messages="metaTagPropertyErrors(key)"
                                  :counter="255"
                                  @input="tag.Property.$touch()"
                                  @blur="tag.Property.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6" class="py-0">
                                <label
                                  class="label-color font-weight-600 mb-2 d-block"
                                >
                                  content
                                </label>
                                <v-text-field
                                  outlined
                                  class="input-style font-size-input text-light-input placeholder-light"
                                  placeholder="株式会社トリプルスリー"
                                  v-model="tag.Content.$model"
                                  :error-messages="metaTagContentErrors(key)"
                                  :counter="255"
                                  @input="tag.Content.$touch()"
                                  @blur="tag.Content.$touch()"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <v-btn
                                  fab
                                  x-small
                                  absolute
                                  dark
                                  right
                                  top
                                  color="red"
                                  @click="deleteMetaTag(key)"
                                >
                                  <v-icon>
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </div>
                      <v-btn
                        tile
                        outlined
                        class="mb-5"
                        color="green"
                        @click="addMetaTag"
                      >
                        <v-icon left>
                          mdi-table-row-plus-after
                        </v-icon>
                        入力欄の追加
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>

                <v-row>
                  <template
                    v-if="TemplateVersion === 'v3'"
                  >
                    <V3 
                      :V3 = "V3" :isUpdate = "isUpdate"> 
                    </V3>
                  </template>
                </v-row>

                <v-row>
                  <template
                    v-if="TemplateVersion === 'v4'"
                  >
                    <V4 
                      :V4 = "V4" :isUpdate = "isUpdate"> 
                    </V4>
                  </template>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0 my-3">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >最終更新者</label
                    >
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedByName"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0 my-3">
                    <label
                      class="label-color font-weight-600 mb-2 d-block"
                    >最終更新日</label
                    >
                    <v-text-field
                      outlined
                      class="font-size-input"
                      v-model="UpdatedAt"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <div class="text-right">
                  <v-btn
                    @click="submitUpdate"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary mt-6 py-3 px-6 rounded-sm me-2"
                    color="green"
                    :loading="loading"
                    large
                  >
                    更新
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import dayjs from "dayjs";
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { getLength } from "@/util/maxLengthValidation"
import Overlay from '../../../components/Overlay.vue';
import CUSTOM_TOOLBAR from "@/const/richTextToolsBar.js";
import V3 from '../../../components/TopArticleTemplate/V3.vue';
import V4 from '../../../components/TopArticleTemplate/V4.vue';

import HeaderTopDashboard from "@/components/HeaderTopDashboard";

Vue.use(VueEditor);

export default {
  name: "TopArticleDetail",
  components: {
    HeaderTopDashboard,
    Overlay,
    VueEditor,
    V3,
    V4,
  },
  data() {
    return {
      customToolbarDetail: CUSTOM_TOOLBAR.info,
      
      CompanyDomain: "",
      TopArticleId: "",
      CompanyId: "",
      TemplateVersion: "v1",
      TopImageFilename: "", //
      TopImageFileUrl : "",
      TopImageFile :  new FormData(),
      TopImageFileType : "",
      TopTitle: "",
      TopDescription: "",
      EyecatchImageFilename: "", //
      EyecatchImageFileUrl : "",
      EyecatchImageFile :  new FormData(),
      EyecatchTitle: "",
      EyecatchTitleColor: "#FFFFFF",
      CompanyImageFilename: "", //
      CompanyImageFileUrl : "",
      CompanyImageFile :  new FormData(),
      CompanyTitle: "",
      CompanyDescription: "",
      GreetingImageFilename: "", //
      GreetingImageFileUrl : "",
      GreetingImageFile :  new FormData(),
      GreetingTitle: "",
      GreetingName: "",
      GreetingDescription: "",
      TopCompanyName: "",
      AboutTitle: "",
      AboutItems: [{
        ImageFilename: "", //
        AboutImage: new FormData(),
        AboutImageUrl: "",
        Title: "",
        Contents: "",
      }],
      ReasonTitle: "",
      ReasonItems: [{
        ImageFilename: "", //
        ReasonImageUrl: "",
        ReasonImage: new FormData(),
        Title: "",
        Contents: "",
        Header: "",
      }],
      SalaryModelItems: [{
        ImageFilename: "", //
        SalaryImageUrl: "",
        SalaryImage: new FormData(),
        Title: "",
        TotalAmount: "",
        SalaryBreakdownItems: [{
          Title: "",
          Amount: "",
        }],
      }],
      CareerStepItems: [{
        Title: "",
        Description: "",
      }],
      DailyScheduleItems: [{
        Time: "",
        Title: "",
      }],
      InterviewItems: [{
        ImageFilename: "", //
        InterviewImageUrl : "",
        InterviewImage : new FormData(),
        Title: "",
        NameInitial: "",
      }],
      GalleryItems: [{
        ImageFilename: "", //
        GalleryImageUrl : "",
        GalleryImage : new FormData(),
      }],
      HiringProcessItems: [{
        ImageFilename: "", //
        ProcessImageUrl : "",
        ProcessImage : new FormData(),
        Title: "",
      }],
      MetaTags: [{
        Name: "",
        Property: "",
        Content: "",
      }],
      V3: {
          TopImageTitle: "",
          TopImageItems: [{
              ImageFilename: "",
              TopImageUrl : "",
              TopImage : new FormData(),
          }],
          IntroductionTitle: "",
          IntroductionDescription: "",
          IntroductionFileName: "",
          IntroductionFileUrl: "",
          IntroductionFile: new FormData(),
          IntroductionFileType: "image",
          InterviewItems: [{
              FileName: "",
              FileUrl : "",
              File : new FormData(),
              FileType: "image",
              Title: "",
              Contents: "",
          }],
          CheckPointItems: [{
              FileName: "",
              FileUrl : "",
              File : new FormData(),
              Title: "",
              Contents: "",
          }],
          FoodImageItems: [{
            FoodImageName: "",
            FoodImageUrl : "",
            FoodImage : new FormData(),
          }],
          StoreItems: [{
              FileName: "",
              FileUrl : "",
              File : new FormData(),
              StoreName: "",
              Address: "",
              Tel: "",
              OpeningHours: "",
          }],
          RecruitImage:  new FormData(),
          RecruitImageFile:  "",
          RecruitImageFileUrl: "",
          RecruitDescription: "",
      },
      V4: {
          TopImageTitle: "",
          TopImageItems: [{
              ImageFilename: "",
              TopImageUrl : "",
              TopImage : new FormData(),
          }],
          RepresentativeGreetingFileName: "",
          RepresentativeGreetingFileUrl: "",
          RepresentativeGreetingFile: new FormData(),            
          RepresentativeGreetingTitle: "",       
          RepresentativeGreetingSubTitle: "", 
          RepresentativeGreetingDescription: "", 
          ThoughtItems: [{
              FileName: "",
              FileUrl : "",
              File : new FormData(),
              Title: "",
              Description: "",
          }],
          FoodImageItems: [{
            FoodImageName: "",
            FoodImageUrl : "",
            FoodImage : new FormData(),
          }],
          StoreItems: [{
              FileName: "",
              FileUrl : "",
              File : new FormData(),
              StoreName: "",
              Address: "",
              Tel: "",
              OpeningHours: "",
          }],
          RecruitImage:  new FormData(),
          RecruitImageFile:  "",
          RecruitImageFileUrl: "",
          RecruitTitle: "",
          RecruitDescription: "",
      },
      FulltimeButtonColor: "#FFFFFF",
      ParttimeButtonColor: "#FFFFFF",
      ContractButtonColor: "#FFFFFF",
      NewgraduateButtonColor: "#FFFFFF",
      CareerButtonColor: "#FFFFFF",
      AllButtonColor: "#FFFFFF",
      CareerRecruitmentButtonColor: "#FFFFFF",
      UpdatedByName: "",
      UpdatedAt: "",
      loading: false,
      getLoading: false,
      isUpdate: false,
    }
  },
  mixins: [validationMixin],
  methods: {
    uploadFile(file, type, key) {
      if (file && type == "topImageFile") {
        if (file.type.indexOf("image") > -1) {
          this.TopImageFileType = 'image';
        } else if (file.type.indexOf("video") > -1) {
          this.TopImageFileType = 'video';
        }
        const topImageFileUrl = URL.createObjectURL(file);
        this.TopImageFileUrl = topImageFileUrl;
        this.TopImageFilename = file.name;
        this.TopImageFile = new FormData();
        this.TopImageFile.append('TopImageFile', file);
      } else if(!file && type == "topImageFile"){
          this.TopImageFilename = "";
          this.TopImageFileUrl = "";
        this.TopImageFile = "";
      } else if (file && type == "eyecatchImageFile") {
          const eyecatchImageFileUrl = URL.createObjectURL(file);
          this.EyecatchImageFileUrl = eyecatchImageFileUrl;
          this.EyecatchImageFilename = file.name;
          this.EyecatchImageFile = new FormData();
          this.EyecatchImageFile.append('EyecatchImage', file);
      } else if (!file && type == "eyecatchImageFile") {
        this.EyecatchImageFilename = file.name;
        this.EyecatchImageFileUrl = "";
        this.EyecatchImageFile = "";
      } else if (file && type == "companyImageFile") {
        const companyImageFileUrl = URL.createObjectURL(file);
        this.CompanyImageFileUrl = companyImageFileUrl;
        this.CompanyImageFilename = file.name;
        this.CompanyImageFile = new FormData();
        this.CompanyImageFile.append('CompanyImage', file);
      } else if (!file && type == "companyImageFile") {
        this.CompanyImageFilename = "";
        this.CompanyImageFileUrl = "";
        this.CompanyImageFile = "";
      } else if (file && type == "aboutImage") {
        const aboutItemsImageUrl = URL.createObjectURL(file);
        this.AboutItems[key].ImageFilename = file.name;
        this.AboutItems[key].AboutImage = new FormData();
        this.AboutItems[key].AboutImage.append('AboutImage', file);
        this.AboutItems[key].AboutImageUrl = aboutItemsImageUrl;
      } else if (!file && type == "aboutImage") {
        this.AboutItems[key].ImageFilename = "";
        this.AboutItems[key].AboutImage = "";
        this.AboutItems[key].AboutImageUrl = "";
      } else if (file && type == "reasonImage") {
        const reasonImageUrl = URL.createObjectURL(file);
        this.ReasonItems[key].ImageFilename = file.name;
        this.ReasonItems[key].ReasonImage = new FormData();
        this.ReasonItems[key].ReasonImage.append('ReasonImage', file);
        this.ReasonItems[key].ReasonImageUrl = reasonImageUrl;
      } else if (!file && type == "reasonImage") {
        this.ReasonItems[key].ImageFilename = "";
        this.ReasonItems[key].ReasonImage = "";
        this.ReasonItems[key].ReasonImageUrl = "";
      } else if (file && type == "salaryImage") {
        const salaryImageUrl = URL.createObjectURL(file);
        this.SalaryModelItems[key].ImageFilename = file.name;
        this.SalaryModelItems[key].SalaryImage = new FormData();
        this.SalaryModelItems[key].SalaryImage.append('SalaryImage', file);
        this.SalaryModelItems[key].SalaryImageUrl = salaryImageUrl;
      } else if (!file && type == "salaryImage") {
        this.SalaryModelItems[key].ImageFilename = "";
        this.SalaryModelItems[key].SalaryImage = "";
        this.SalaryModelItems[key].SalaryImageUrl = "";
      } else if (file && type == "interviewImage") {
        const interviewImageUrl = URL.createObjectURL(file);
        this.InterviewItems[key].ImageFilename = file.name;
        this.InterviewItems[key].InterviewImage = new FormData();
        this.InterviewItems[key].InterviewImage.append('InterviewImage', file);
        this.InterviewItems[key].InterviewImageUrl = interviewImageUrl;
      } else if (!file && type == "interviewImage") {
        this.InterviewItems[key].ImageFilename = "";
        this.InterviewItems[key].InterviewImage = "";
        this.InterviewItems[key].InterviewImageUrl = "";
      } else if (file && type == "galleryImage") {
        const galleryImageUrl = URL.createObjectURL(file);
        this.GalleryItems[key].ImageFilename = file.name;
        this.GalleryItems[key].GalleryImage = new FormData();
        this.GalleryItems[key].GalleryImage.append('GalleryImage', file);
        this.GalleryItems[key].GalleryImageUrl = galleryImageUrl;
      } else if (!file && type == "galleryImage") {
        this.GalleryItems[key].ImageFilename = "";
        this.GalleryItems[key].GalleryImage = "";
        this.GalleryItems[key].GalleryImageUrl = "";
      } else if (file && type == "greetingImageFile") {
        const greetingImageFileUrl = URL.createObjectURL(file);
        this.GreetingImageFileUrl = greetingImageFileUrl;
        this.GreetingImageFilename = file.name;
        this.GreetingImageFile = new FormData();
        this.GreetingImageFile.append('GreetingImageFile', file);
      } else if (!file && type == "greetingImageFile") {
        this.GreetingImageFilename = "";
        this.GreetingImageFileUrl = "";
        this.GreetingImageFile = "";
      } else if (file && type == "processImage") {
        const processImageUrl = URL.createObjectURL(file);
        this.HiringProcessItems[key].ImageFilename = file.name;
        this.HiringProcessItems[key].ProcessImage = new FormData();
        this.HiringProcessItems[key].ProcessImage.append('ProcessImage', file);
        this.HiringProcessItems[key].ProcessImageUrl = processImageUrl;
      } else if (!file && type == "processImage") {
        this.HiringProcessItems[key].ImageFilename = "";
        this.HiringProcessItems[key].AboutImage = "";
        this.HiringProcessItems[key].ProcessImageUrl = "";
      }
    },
    enable: function() {
      this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, true);
    },
    disable: function() {
      this.$refs.rteInstance.ej2Instances.toolbarModule.baseToolbar.toolbarObj.enableItems(13, false);
    },
    swapAboutItem(key) {
      const arr = [this.AboutItems[key - 1], this.AboutItems[key]];
      this.AboutItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteAboutItem(key) {
      this.AboutItems.splice(key, 1);
    },
    addAboutItem() {
      this.AboutItems.push({
        ImageFilename: "", //
        AboutImage: new FormData(),
        AboutImageUrl: "",
        Title: "",
        Contents: "",
      });
    },
    swapReasonItem(key) {
      const arr = [this.ReasonItems[key - 1], this.ReasonItems[key]];
      this.ReasonItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteReasonItem(key) {
      this.ReasonItems.splice(key, 1);
    },
    addReasonItem() {
      this.ReasonItems.push({
        ImageFilename: "", //
        ReasonImageUrl: "",
        ReasonImage: new FormData(),
        Title: "",
        Contents: "",
        Header: "",
      });
    },
    swapSalaryModelItem(key) {
      const arr = [this.SalaryModelItems[key - 1], this.SalaryModelItems[key]];
      this.SalaryModelItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteSalaryModelItem(key) {
      this.SalaryModelItems.splice(key, 1);
    },
    addSalaryModelItem() {
      this.SalaryModelItems.push({
        ImageFilename: "", //
        SalaryImageUrl: "",
        SalaryImage: new FormData(),
        Title: "",
        TotalAmount: "",
        SalaryBreakdownItems: [{
          Title: "",
          Amount: "",
        }],
      });
    },
    swapBreakdownItem(key, breakdownKey) {
      const arr = [this.SalaryModelItems[key].SalaryBreakdownItems[breakdownKey - 1], this.SalaryModelItems[key].SalaryBreakdownItems[breakdownKey]];
      this.SalaryModelItems[key].SalaryBreakdownItems.splice(breakdownKey - 1, 2, arr[1], arr[0]);
    },
    deleteBreakdownItem(key, breakdownKey) {
      this.SalaryModelItems[key].SalaryBreakdownItems.splice(breakdownKey, 1);
    },
    addBreakdownItem(key) {
      this.SalaryModelItems[key].SalaryBreakdownItems.push({
        Title: "",
        Amount: "",
      });
    },
    swapCareerStepItem(key) {
      const arr = [this.CareerStepItems[key - 1], this.CareerStepItems[key]];
      this.CareerStepItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteCareerStepItem(key) {
      this.CareerStepItems.splice(key, 1);
    },
    addCareerStepItem() {
      this.CareerStepItems.push({
        Title: "",
        Description: "",
      });
    },
    swapDailyScheduleItem(key) {
      const arr = [this.DailyScheduleItems[key - 1], this.DailyScheduleItems[key]];
      this.DailyScheduleItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteDailyScheduleItem(key) {
      this.DailyScheduleItems.splice(key, 1);
    },
    addDailyScheduleItem() {
      this.DailyScheduleItems.push({
        Title: "",
        Time: "",
      });
    },
    swapInterviewItem(key) {
      const arr = [this.InterviewItems[key - 1], this.InterviewItems[key]];
      this.InterviewItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteInterviewItem(key) {
      this.InterviewItems.splice(key, 1);
    },
    addInterviewItem() {
      this.InterviewItems.push({
        ImageFilename: "", //
        InterviewImageUrl : "",
        InterviewImage : new FormData(),
        Title: "",
        NameInitial: "",
      });
    },
    swapGalleryItem(key) {
      const arr = [this.GalleryItems[key - 1], this.GalleryItems[key]];
      this.GalleryItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteGalleryItem(key) {
      this.GalleryItems.splice(key, 1);
    },
    addGalleryItem() {
      this.GalleryItems.push({
        ImageFilename: "", //
        GalleryImageUrl : "",
        GalleryImage : new FormData(),
      });
    },
    swapHiringProcessItem(key) {
      const arr = [this.HiringProcessItems[key - 1], this.HiringProcessItems[key]];
      this.HiringProcessItems.splice(key - 1, 2, arr[1], arr[0]);
    },
    deleteHiringProcessItem(key) {
      this.HiringProcessItems.splice(key, 1);
    },
    addHiringProcessItem() {
      this.HiringProcessItems.push({
        ImageFilename: "", //
        ProcessImageUrl : "",
        ProcessImage : new FormData(),
        Title: "",
      });
    },
    deleteMetaTag(key) {
      this.MetaTags.splice(key, 1);
    },
    addMetaTag() {
      this.MetaTags.push({
        Name: "",
        Property: "",
        Content: "",
      });
    },
    async submitUpdate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      await this.uploadMedia();
      if (this.isUpdate) {
        axios.put("/top-articles/" + this.TopArticleId, {
          TopArticleId: this.TopArticleId,
          CompanyId: this.CompanyId,
          TemplateVersion: this.TemplateVersion,
          TopImageFilename: this.TopImageFilename,
          TopTitle: this.TopTitle,
          TopDescription: this.TopDescription,
          GreetingImageFilename: this.GreetingImageFilename,
          GreetingTitle: this.GreetingTitle,
          GreetingName: this.GreetingName,
          GreetingDescription: this.GreetingDescription,
          EyecatchImageFilename: this.EyecatchImageFilename,
          EyecatchTitle: this.EyecatchTitle,
          EyecatchTitleColor: this.EyecatchTitleColor,
          CompanyImageFilename: this.CompanyImageFilename,
          CompanyTitle: this.CompanyTitle,
          CompanyDescription: this.CompanyDescription,
          AboutTitle: this.AboutTitle,
          TopCompanyName: this.TopCompanyName,
          AboutItems: this.AboutItems.map((aboutItem) => {
            return {
              ImageFilename : aboutItem.ImageFilename,
              Title: aboutItem.Title,
              Contents: aboutItem.Contents
            }
          }),
          ReasonTitle: this.ReasonTitle,
          ReasonItems: this.ReasonItems.map((reasonItem) => {
            return {
              ImageFilename : reasonItem.ImageFilename,
              Title: reasonItem.Title,
              Contents: reasonItem.Contents,
              Header: reasonItem.Header,
            }
          }),
          SalaryModelItems: this.SalaryModelItems.map((salaryItem) => {
            return {
              ImageFilename : salaryItem.ImageFilename,
              Title: salaryItem.Title,
              TotalAmount: salaryItem.TotalAmount,
              SalaryBreakdownItems: salaryItem.SalaryBreakdownItems
            }
          }),
          CareerStepItems: this.CareerStepItems,
          DailyScheduleItems: this.DailyScheduleItems,
          InterviewItems: this.InterviewItems.map((interviewItem) => {
            return {
              ImageFilename : interviewItem.ImageFilename,
              Title: interviewItem.Title,
              NameInitial: interviewItem.NameInitial
            }
          }),
          GalleryItems: this.GalleryItems.map((galleryItem) => {
            return {
              ImageFilename : galleryItem.ImageFilename,
            }
          }),
          HiringProcessItems: this.HiringProcessItems.map((hiringItem) => {
            return {
              ImageFilename : hiringItem.ImageFilename,
              Title: hiringItem.Title
            }
          }),
          MetaTags: this.MetaTags,
          FulltimeButtonColor: this.FulltimeButtonColor,
          ParttimeButtonColor: this.ParttimeButtonColor,
          ContractButtonColor: this.ContractButtonColor,
          NewgraduateButtonColor: this.NewgraduateButtonColor,
          CareerButtonColor: this.CareerButtonColor,
          CareerRecruitmentButtonColor: this.CareerRecruitmentButtonColor,
          AllButtonColor: this.AllButtonColor,
          V3: {
            TopImageTitle: this.V3.TopImageTitle,
            TopImageItems: this.V3.TopImageItems.map((topImageItem) => {
              return {
                ImageFilename: topImageItem.ImageFilename,
              }
            }),
            IntroductionTitle: this.V3.IntroductionTitle,
            IntroductionDescription: this.V3.IntroductionDescription,
            IntroductionFileName: this.V3.IntroductionFileName,
            InterviewItems: this.V3.InterviewItems.map((interviewItem) => {
              return {
                FileName: interviewItem.FileName,
                Title: interviewItem.Title,
                Contents: interviewItem.Contents,
              }
            }),
            CheckPointItems: this.V3.CheckPointItems.map((checkPointItem) => {
              return {
                FileName: checkPointItem.FileName,
                Title: checkPointItem.Title,
                Contents: checkPointItem.Contents,
              }
            }),
            FoodImageItems: this.V3.FoodImageItems.map((foodImageItem) => {
              return {
                FoodImageName: foodImageItem.FoodImageName,
              }
            }),
            StoreItems: this.V3.StoreItems.map((storeItem) => {
              return {
                FileName: storeItem.FileName,
                StoreName: storeItem.StoreName,
                Address: storeItem.Address,
                Tel: storeItem.Tel,
                OpeningHours: storeItem.OpeningHours,
              }
            }),
            RecruitImageFile: this.V3.RecruitImageFile,
            RecruitDescription: this.V3.RecruitDescription,
          },
          V4: {
              TopImageTitle: this.V4.TopImageTitle,
              TopImageItems: this.V4.TopImageItems.map((topImageItem) => {
                  return {
                      ImageFilename: topImageItem.ImageFilename,
                  }
              }),
              RepresentativeGreetingFileName: this.V4.RepresentativeGreetingFileName,
              RepresentativeGreetingTitle: this.V4.RepresentativeGreetingTitle,
              RepresentativeGreetingSubTitle: this.V4.RepresentativeGreetingSubTitle,
              RepresentativeGreetingDescription: this.V4.RepresentativeGreetingDescription,
              ThoughtItems: this.V4.ThoughtItems.map((thoughtItem) => {
                  return {
                      FileName: thoughtItem.FileName,
                      Title: thoughtItem.Title,
                      Description: thoughtItem.Description,
                  }
              }),
              FoodImageItems: this.V4.FoodImageItems.map((foodImageItem) => {
                  return {
                      FoodImageName: foodImageItem.FoodImageName,
                  }
              }),
              StoreItems: this.V4.StoreItems.map((storeItem) => {
                  return {
                      FileName: storeItem.FileName,
                      StoreName: storeItem.StoreName,
                      Address: storeItem.Address,
                      Tel: storeItem.Tel,
                      OpeningHours: storeItem.OpeningHours,
                  }
              }),
              RecruitImageFile: this.V4.RecruitImageFile,
              RecruitTitle: this.V4.RecruitTitle,
              RecruitDescription: this.V4.RecruitDescription,
            },
        }).then((res) => {
          this.openSnackbarSuccess();
          this.$refs.topForm.reset();
          this.getData();
        }).catch(e => {
          console.log(e);
          const res = e.response;
          if (res.status === 400) {
            this.openSnackbarValidationError(res.data)
          }
        }).finally(() => {
          this.loading = false;
        });
      } else {
        axios.post("/top-articles", {
          CompanyId: Number(this.$route.params.companyId),
          TemplateVersion: this.TemplateVersion,
          TopImageFilename: this.TopImageFilename,
          TopTitle: this.TopTitle,
          TopDescription: this.TopDescription,
          GreetingImageFilename: this.GreetingImageFilename,
          GreetingTitle: this.GreetingTitle,
          GreetingName: this.GreetingName,
          GreetingDescription: this.GreetingDescription,
          EyecatchImageFilename: this.EyecatchImageFilename,
          EyecatchTitle: this.EyecatchTitle,
          EyecatchTitleColor: this.EyecatchTitleColor,
          CompanyImageFilename: this.CompanyImageFilename,
          CompanyTitle: this.CompanyTitle,
          CompanyDescription: this.CompanyDescription,
          AboutTitle: this.AboutTitle,
          TopCompanyName: this.TopCompanyName,
          AboutItems: this.AboutItems.map((aboutItem) => {
            return {
              ImageFilename : aboutItem.ImageFilename,
              Title: aboutItem.Title,
              Contents: aboutItem.Contents
            }
          }),
          ReasonTitle: this.ReasonTitle,
          ReasonItems: this.ReasonItems.map((reasonItem) => {
            return {
              ImageFilename : reasonItem.ImageFilename,
              Title: reasonItem.Title,
              Header: reasonItem.Header,
              Contents: reasonItem.Contents
            }
          }),
          SalaryModelItems: this.SalaryModelItems.map((salaryItem) => {
            return {
              ImageFilename : salaryItem.ImageFilename,
              Title: salaryItem.Title,
              TotalAmount: salaryItem.TotalAmount,
              SalaryBreakdownItems: salaryItem.SalaryBreakdownItems
            }
          }),
          CareerStepItems: this.CareerStepItems,
          DailyScheduleItems: this.DailyScheduleItems,
          InterviewItems: this.InterviewItems.map((interviewItem) => {
            return {
              ImageFilename : interviewItem.ImageFilename,
              Title: interviewItem.Title,
              NameInitial: interviewItem.NameInitial
            }
          }),
          GalleryItems: this.GalleryItems.map((galleryItem) => {
            return {
              ImageFilename : galleryItem.ImageFilename,
            }
          }),
          HiringProcessItems: this.HiringProcessItems.map((hiringItem) => {
            return {
              ImageFilename : hiringItem.ImageFilename,
              Title: hiringItem.Title
            }
          }),
          MetaTags: this.MetaTags,
          V3: {
            TopImageTitle: this.V3.TopImageTitle,
            TopImageItems: this.V3.TopImageItems.map((topImageItem) => {
              return {
                ImageFilename: topImageItem.ImageFilename,
              }
            }),
            IntroductionTitle: this.V3.IntroductionTitle,
            IntroductionDescription: this.V3.IntroductionDescription,
            IntroductionFileName: this.V3.IntroductionFileName,
            InterviewItems: this.V3.InterviewItems.map((interviewItem) => {
              return {
                FileName: interviewItem.FileName,
                Title: interviewItem.Title,
                Contents: interviewItem.Contents,
              }
            }),
            CheckPointItems: this.V3.CheckPointItems.map((checkPointItem) => {
              return {
                FileName: checkPointItem.FileName,
                Title: checkPointItem.Title,
                Contents: checkPointItem.Contents,
              }
            }),
            FoodImageItems: this.V3.FoodImageItems.map((foodImageItem) => {
              return {
                FoodImageName: foodImageItem.FoodImageName,
              }
            }),
            StoreItems: this.V3.StoreItems.map((storeItem) => {
              return {
                FileName: storeItem.FileName,
                StoreName: storeItem.StoreName,
                Address: storeItem.Address,
                Tel: storeItem.Tel,
                OpeningHours: storeItem.OpeningHours,
              }
            }),
            RecruitImageFile: this.V3.RecruitImageFile,
            RecruitDescription: this.V3.RecruitDescription,
          },
          V4: {
            TopImageTitle: this.V4.TopImageTitle,
            TopImageItems: this.V4.TopImageItems.map((topImageItem) => {
                return {
                    ImageFilename: topImageItem.ImageFilename,
                }
            }),
            RepresentativeGreetingFileName: this.V4.RepresentativeGreetingFileName,
            RepresentativeGreetingTitle: this.V4.RepresentativeGreetingTitle,
            RepresentativeGreetingSubTitle: this.V4.RepresentativeGreetingSubTitle,
            RepresentativeGreetingDescription: this.V4.RepresentativeGreetingDescription,
            ThoughtItems: this.V4.ThoughtItems.map((thoughtItem) => {
                return {
                    FileName: thoughtItem.FileName,
                    Title: thoughtItem.Title,
                    Description: thoughtItem.Description,
                }
            }),
            FoodImageItems: this.V4.FoodImageItems.map((foodImageItem) => {
                return {
                    FoodImageName: foodImageItem.FoodImageName,
                }
            }),
            StoreItems: this.V4.StoreItems.map((storeItem) => {
                return {
                    FileName: storeItem.FileName,
                    StoreName: storeItem.StoreName,
                    Address: storeItem.Address,
                    Tel: storeItem.Tel,
                    OpeningHours: storeItem.OpeningHours,
                }
            }),
            RecruitImageFile: this.V4.RecruitImageFile,
            RecruitTitle: this.V4.RecruitTitle,
            RecruitDescription: this.V4.RecruitDescription,
          },
          FulltimeButtonColor: this.FulltimeButtonColor,
          ParttimeButtonColor: this.ParttimeButtonColor,
          ContractButtonColor: this.ContractButtonColor,
          NewgraduateButtonColor: this.NewgraduateButtonColor,
          CareerButtonColor: this.CareerButtonColor,
          CareerRecruitmentButtonColor: this.CareerRecruitmentButtonColor,
          AllButtonColor: this.AllButtonColor,
        }).then(() => {
          this.openSnackbarSuccess();
          this.getData();
        }).catch(e => {
          const res = e.response;
          if (res && res.status === 400) {
            this.openSnackbarValidationError(res.data)
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    getData() {
      if (this.$route.params.companyId) {
        this.getLoading = true;
        this.isUpdate = true;
        axios.get("/top-articles?companyId=" + this.$route.params.companyId).then(res => {
          const s3BaseUrl = process.env.NODE_ENV === "production" ? 
            `https://${res.data.Company.Domain}.tt-recruit.com/img/` :
            `${process.env.VUE_APP_PREVIEW_ENDPOINT}/${res.data.Company.Domain}/img/`;
          this.CompanyDomain = res.data.Company.Domain;
          this.TopArticleId = res.data.TopArticleId;
          this.CompanyId = res.data.CompanyId;
          this.TemplateVersion = res.data.TemplateVersion;
          this.TopImageFilename = res.data.TopImageFilename;
          this.TopImageFileUrl = s3BaseUrl + res.data.TopImageFilename;
          const fType = res.data.TopImageFilename.split('.').pop();
          if (fType.match(/mov|mp4/i)) {
            this.TopImageFileType = 'video';
          } else {
            this.TopImageFileType = 'image';
          }
          this.TopImageFile = new FormData();
          this.EyecatchImageFile = new FormData();
          this.CompanyImageFile = new FormData();
          this.GreetingImageFile = new FormData();
          this.TopTitle = res.data.TopTitle;
          this.TopDescription = res.data.TopDescription;
          this.EyecatchImageFilename = res.data.EyecatchImageFilename;
          this.EyecatchImageFileUrl = s3BaseUrl + res.data.EyecatchImageFilename;
          this.EyecatchTitle = res.data.EyecatchTitle;
          this.EyecatchTitleColor = res.data.EyecatchTitleColor;
          this.CompanyImageFilename = res.data.CompanyImageFilename;
          this.CompanyImageFileUrl = s3BaseUrl + res.data.CompanyImageFilename;
          this.CompanyTitle = res.data.CompanyTitle;
          this.CompanyDescription = res.data.CompanyDescription;
          this.GreetingImageFilename = res.data.GreetingImageFilename;
          this.GreetingImageFileUrl = s3BaseUrl + res.data.GreetingImageFilename;
          this.GreetingTitle = res.data.GreetingTitle;
          this.GreetingName = res.data.GreetingName;
          this.GreetingDescription = res.data.GreetingDescription;
          this.AboutTitle = res.data.AboutTitle;
          this.TopCompanyName = res.data.TopCompanyName;
          this.AboutItems = (res.data.AboutItems && res.data.AboutItems.length > 0) ? 
          res.data.AboutItems.map((aboutItem) => {
            return {
                ImageFilename : aboutItem.ImageFilename,
                AboutImage: new FormData(),
                AboutImageUrl : s3BaseUrl + aboutItem.ImageFilename,
                Title: aboutItem.Title,
                Contents: aboutItem.Contents,
            }
          }) : [];
          this.ReasonTitle = res.data.ReasonTitle;
          this.ReasonItems =  (res.data.ReasonItems && res.data.ReasonItems.length > 0) ?
          res.data.ReasonItems.map((reasonItem) => {
            return {
                ImageFilename : reasonItem.ImageFilename,
                ReasonImageUrl: s3BaseUrl + reasonItem.ImageFilename,
                ReasonImage: new FormData(),
                Header: reasonItem.Header,
                Title: reasonItem.Title,
                Contents: reasonItem.Contents,
            }
          }) : [];
          this.SalaryModelItems = (res.data.SalaryModelItems && res.data.SalaryModelItems.length > 0) ?
          res.data.SalaryModelItems.map((salaryModelItem) => {
            return {
                ImageFilename : salaryModelItem.ImageFilename,
                SalaryImageUrl: s3BaseUrl + salaryModelItem.ImageFilename,
                SalaryImage: new FormData(),
                Title: salaryModelItem.Title,
                TotalAmount: salaryModelItem.TotalAmount,
                SalaryBreakdownItems: salaryModelItem.SalaryBreakdownItems
            }
          }) : [];
          this.CareerStepItems = res.data.CareerStepItems || [];
          this.DailyScheduleItems = res.data.DailyScheduleItems || [];
          this.InterviewItems =  (res.data.InterviewItems && res.data.InterviewItems.length > 0) ?
          res.data.InterviewItems.map((interviewItems) => {
            return {
              ImageFilename : interviewItems.ImageFilename,
              InterviewImageUrl : s3BaseUrl + interviewItems.ImageFilename,
              InterviewImage : new FormData(),
              Title: interviewItems.Title,
              NameInitial: interviewItems.NameInitial,
            }
          }) : [];
          this.GalleryItems = (res.data.GalleryItems && res.data.GalleryItems.length > 0) ?
          res.data.GalleryItems.map((galleryItems) => {
            return {
              ImageFilename : galleryItems.ImageFilename,
              GalleryImageUrl : s3BaseUrl + galleryItems.ImageFilename,
              GalleryImage : new FormData(),
            }
          }) : [];
          this.HiringProcessItems = (res.data.HiringProcessItems && res.data.HiringProcessItems) ?
          res.data.HiringProcessItems.map((hiringProcessItem) => {
            return {
              ImageFilename : hiringProcessItem.ImageFilename,
              ProcessImageUrl : s3BaseUrl + hiringProcessItem.ImageFilename,
              ProcessImage : new FormData(),
              Title: hiringProcessItem.Title,
            }
          }) : [];
          if (res.data.v3) {
            this.V3 = {
              TopImageTitle: res.data.v3.TopImageTitle || "",
              TopImageItems: res.data.v3.TopImageItems ? res.data.v3.TopImageItems.map(item => {
                const fType = item.ImageFilename ? item.ImageFilename.split('.').pop() : '';
                return {
                  ImageFilename: item.ImageFilename || "",
                  TopImageUrl: item.ImageFilename ? s3BaseUrl + item.ImageFilename : "",
                  TopImage: new FormData(),
                  FileType: fType && fType.match(/mov|mp4/i) ? 'video' : 'image'
                };
              }) : [],
              IntroductionTitle: res.data.v3.IntroductionTitle || "",
              IntroductionDescription: res.data.v3.IntroductionDescription || "",
              IntroductionFileName: res.data.v3.IntroductionFileName || "",
              IntroductionFileUrl: res.data.v3.IntroductionFileName ? s3BaseUrl + res.data.v3.IntroductionFileName : "",
              IntroductionFile: new FormData(),
              IntroductionFileType: (() => {
                const fType = res.data.v3.IntroductionFileName ? res.data.v3.IntroductionFileName.split('.').pop() : '';
                return fType && fType.match(/mov|mp4/i) ? 'video' : 'image';
              })(),
              InterviewItems: res.data.v3.InterviewItems ? res.data.v3.InterviewItems.map(item => {
                const fType = item.FileName ? item.FileName.split('.').pop() : '';
                return {
                  FileName: item.FileName || "",
                  FileUrl: item.FileName ? s3BaseUrl + item.FileName : "",
                  File: new FormData(),
                  FileType: fType && fType.match(/mov|mp4/i) ? 'video' : 'image',
                  Title: item.Title || "",
                  Contents: item.Contents || "",
                };
              }) : [],
              CheckPointItems: res.data.v3.CheckPointItems ? res.data.v3.CheckPointItems.map(item => {
                const fType = item.FileName ? item.FileName.split('.').pop() : '';
                return {
                  FileName: item.FileName || "",
                  FileUrl: item.FileName ? s3BaseUrl + item.FileName : "",
                  File: new FormData(),
                  FileType: fType && fType.match(/mov|mp4/i) ? 'video' : 'image',
                  Title: item.Title || "",
                  Contents: item.Contents || "",
                };
              }) : [],
              FoodImageItems: res.data.v3.FoodImageItems ? res.data.v3.FoodImageItems.map(item => ({
                FoodImageName: item.FoodImageName || "",
                FoodImageUrl: item.FoodImageName ? s3BaseUrl + item.FoodImageName : "",
                FoodImage: new FormData(),
              })) : [],
              StoreItems: res.data.v3.StoreItems ? res.data.v3.StoreItems.map(item => {
                const fType = item.FileName ? item.FileName.split('.').pop() : '';
                return {
                  FileName: item.FileName || "",
                  FileUrl: item.FileName ? s3BaseUrl + item.FileName : "",
                  File: new FormData(),
                  FileType: fType && fType.match(/mov|mp4/i) ? 'video' : 'image',
                  StoreName: item.StoreName || "",
                  Address: item.Address || "",
                  Tel: item.Tel || "",
                  OpeningHours: item.OpeningHours || "",
                };
              }) : [],
              RecruitImage: new FormData(),
              RecruitImageFile: res.data.v3.RecruitImageFile || "",
              RecruitImageFileUrl: res.data.v3.RecruitImageFile ? s3BaseUrl + res.data.v3.RecruitImageFile : "",
              RecruitDescription: res.data.v3.RecruitDescription || "",
            };
          }
          if (res.data.v4) {
            this.V4 = {
                TopImageTitle: res.data.v4.TopImageTitle || "",
                TopImageItems: res.data.v4.TopImageItems ? res.data.v4.TopImageItems.map(item => {
                    return {
                        ImageFilename: item.ImageFilename || "",
                        TopImageUrl: item.ImageFilename ? s3BaseUrl + item.ImageFilename : "",
                        TopImage: new FormData(),
                    };
                }) : [],
                RepresentativeGreetingFileName: res.data.v4.RepresentativeGreetingFileName || "",
                RepresentativeGreetingFileUrl: res.data.v4.RepresentativeGreetingFileName ? s3BaseUrl + res.data.v4.RepresentativeGreetingFileName : "",
                RepresentativeGreetingFile: new FormData(),
                RepresentativeGreetingTitle: res.data.v4.RepresentativeGreetingTitle || "",
                RepresentativeGreetingSubTitle: res.data.v4.RepresentativeGreetingSubTitle || "",
                RepresentativeGreetingDescription: res.data.v4.RepresentativeGreetingDescription || "",
                ThoughtItems: res.data.v4.ThoughtItems ? res.data.v4.ThoughtItems.map(item => {
                    return {
                        FileName: item.FileName || "",
                        FileUrl: item.FileName ? s3BaseUrl + item.FileName : "",
                        File: new FormData(),
                        Title: item.Title || "",
                        Description: item.Description || "",
                    };
                }) : [],
                FoodImageItems: res.data.v4.FoodImageItems ? res.data.v4.FoodImageItems.map(item => {
                    return {
                        FoodImageName: item.FoodImageName || "",
                        FoodImageUrl: item.FoodImageName ? s3BaseUrl + item.FoodImageName : "",
                        FoodImage: new FormData(),
                    };
                }) : [],
                StoreItems: res.data.v4.StoreItems ? res.data.v4.StoreItems.map(item => {
                    return {
                        FileName: item.FileName || "",
                        FileUrl: item.FileName ? s3BaseUrl + item.FileName : "",
                        File: new FormData(),
                        StoreName: item.StoreName || "",
                        Address: item.Address || "",
                        Tel: item.Tel || "",
                        OpeningHours: item.OpeningHours || "",
                    };
                }) : [],
                RecruitImage: new FormData(),
                RecruitImageFile: res.data.v4.RecruitImageFile || "",
                RecruitImageFileUrl: res.data.v4.RecruitImageFile ? s3BaseUrl + res.data.v4.RecruitImageFile : "",
                RecruitTitle: res.data.v4.RecruitTitle || "",
                RecruitDescription: res.data.v4.RecruitDescription || "",
            };
          }
          this.MetaTags = res.data.MetaTags || [];
          this.FulltimeButtonColor = res.data.FulltimeButtonColor;
          this.ParttimeButtonColor = res.data.ParttimeButtonColor;
          this.ContractButtonColor = res.data.ContractButtonColor;
          this.NewgraduateButtonColor = res.data.NewgraduateButtonColor;
          this.CareerButtonColor = res.data.CareerButtonColor;
          this.CareerRecruitmentButtonColor = res.data.CareerRecruitmentButtonColor;
          this.AllButtonColor = res.data.AllButtonColor;
          this.UpdatedByName = res.data.UpdatedByName;
          if (res.data.UpdatedAt) {
            this.UpdatedAt = dayjs(res.data.UpdatedAt).format("YYYY-MM-DD HH:mm:ss");
          }
          console.log("response testing(2-29)");
        }).catch(e => {
          console.log("error in data fetching(2-29)", e);
          const res = e.response;
          if (res && res.status === 400) {
            this.isUpdate = false;
          }
          //TODO notfoundの場合はisupdate
        }).finally(() => {
          this.getLoading = false;
        });
      }
    },
    preview() {
      if(process.env.NODE_ENV === "production"){
        window.open(`https://${this.CompanyDomain}.tt-recruit.com/`);
        }
      else {
        window.open(`${process.env.VUE_APP_PREVIEW_ENDPOINT}/${this.CompanyDomain}/`);
      }
    },

    async uploadMedia() {
      try{
      if(this.AboutItems && this.AboutItems.length > 0 && this.TemplateVersion === 'v1'){
        var combinedAboutImages = new FormData(); // AboutItems is carrer steps so it is CompanyIntroImage
        this.AboutItems.forEach((aboutItem) => {
            if(aboutItem.AboutImage){
              const fileValue =  aboutItem.AboutImage.get("AboutImage");
              combinedAboutImages.append("CompanyIntroImage", fileValue);
            }
        });
      }
    
      if(this.ReasonItems && this.ReasonItems.length > 0){
        var combinedReasonImages = new FormData(); // ReasonItems is popular secret
        this.ReasonItems.forEach((reasonItem) => {
            if(reasonItem.ReasonImage){
              const fileValue =  reasonItem.ReasonImage.get("ReasonImage");
              combinedReasonImages.append("PopularSecretImage", fileValue);
            }
        });
      }

      if(this.SalaryModelItems && this.SalaryModelItems.length > 0 && this.TemplateVersion === 'v2'){
      var combinedSalaryImages = new FormData();
      this.SalaryModelItems.forEach((salaryModelItem) => {
          if(salaryModelItem.SalaryImage){
            const fileValue =  salaryModelItem.SalaryImage.get("SalaryImage");
            combinedSalaryImages.append("SalaryModelImage", fileValue);
          }
      });
      }

      if(this.InterviewItems && this.InterviewItems.length > 0  && this.TemplateVersion === 'v2'){
        var combinedInterviewImages = new FormData();
        this.InterviewItems.forEach((interviewItem) => {
            if(interviewItem.InterviewImage){
              const fileValue =  interviewItem.InterviewImage.get("InterviewImage");
              combinedInterviewImages.append("InterviewImage", fileValue);
            }
        });
      }
      if(this.GalleryItems && this.GalleryItems.length > 0  && this.TemplateVersion === 'v2'){
        var combinedGalleryImages = new FormData();
        this.GalleryItems.forEach((galleryItem) => {
            if(galleryItem.GalleryImage){
              const fileValue =  galleryItem.GalleryImage.get("GalleryImage");
              combinedGalleryImages.append("GalleryImage", fileValue);
            }
        });
      }
      if(this.HiringProcessItems && this.HiringProcessItems.length > 0  && this.TemplateVersion === 'v2'){
        var combinedHiringProcessImages = new FormData();
        this.HiringProcessItems.forEach((hiringProcessItem) => {
            if(hiringProcessItem.ProcessImage){
              const fileValue =  hiringProcessItem.ProcessImage.get("ProcessImage");
              combinedHiringProcessImages.append("HiringProcessImage", fileValue);
            }
        });
       }
      const allAllMediaData = new FormData();
      allAllMediaData.append("CompanyId", this.CompanyId);
      allAllMediaData.append("CompanyDomain", this.CompanyDomain);
      if(this.TopImageFile.get("TopImageFile")){
        allAllMediaData.append("TopFile", this.TopImageFile.get("TopImageFile")); // v1, v2
      }
      if(this.TemplateVersion === 'v1' && this.EyecatchImageFile.get("EyecatchImage")){
        allAllMediaData.append("EyeCatchImage", this.EyecatchImageFile.get("EyecatchImage")); // v1
      }
      if(this.CompanyImageFile.get("CompanyImage")){
        allAllMediaData.append("CompanyImage", this.CompanyImageFile.get("CompanyImage")); // v1,v2
      }
      if(this.AboutItems && this.AboutItems.length > 0  && this.TemplateVersion === 'v1'){
        for (var pair of combinedAboutImages.entries()) { // v1 optional array
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      if(this.ReasonItems && this.ReasonItems.length > 0){
        for (var pair of combinedReasonImages.entries()) { // v1, v2 optional
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      if(this.SalaryModelItems && this.SalaryModelItems.length > 0  && this.TemplateVersion === 'v2'){
        for (var pair of combinedSalaryImages.entries()) { // v2 optional
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      if(this.InterviewItems && this.InterviewItems.length > 0  && this.TemplateVersion === 'v2'){
        for (var pair of combinedInterviewImages.entries()) { // v2 optional
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      if(this.GalleryItems && this.GalleryItems.length > 0  && this.TemplateVersion === 'v2'){
        for (var pair of combinedGalleryImages.entries()) { // v2 optional
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      if(this.TemplateVersion === 'v2'){
        allAllMediaData.append("GreetingImage", this.GreetingImageFile.get("GreetingImageFile")); // v2
      }
      if(this.HiringProcessItems && this.HiringProcessItems.length > 0  && this.TemplateVersion === 'v2'){
        for (var pair of combinedHiringProcessImages.entries()) { // v2 optional
          allAllMediaData.append(pair[0], pair[1]);
        }
      }

      if (this.TemplateVersion === 'v3') {
        var combinedV3Images = new FormData();

        // TopImageItems
        this.V3.TopImageItems.forEach((item, index) => {
          if (item.TopImage.get("TopImage")) {
            combinedV3Images.append(`TopImage_${index}`, item.TopImage.get("TopImage"));
          }
        });

        // IntroductionImage
        if (this.V3.IntroductionFile.get("IntroductionFile")) {
          combinedV3Images.append("IntroductionImage", this.V3.IntroductionFile.get("IntroductionFile"));
        }

        // InterviewItems
        this.V3.InterviewItems.forEach((item, index) => {
          if (item.File.get("InterviewFile")) {
            combinedV3Images.append(`InterviewFile_${index}`, item.File.get("InterviewFile"));
          }
        });

        // CheckPointItems
        this.V3.CheckPointItems.forEach((item, index) => {
          if (item.File.get("CheckPointFile")) {
            combinedV3Images.append(`CheckPointFile_${index}`, item.File.get("CheckPointFile"));
          }
        });

        // FoodImageItems
        this.V3.FoodImageItems.forEach((item, index) => {
          if (item.FoodImage.get("FoodImage")) {
            combinedV3Images.append(`FoodImage_${index}`, item.FoodImage.get("FoodImage"));
          }
        });

        // StoreItems
        this.V3.StoreItems.forEach((item, index) => {
          if (item.File.get("StoreImage")) {
            combinedV3Images.append(`StoreFile_${index}`, item.File.get("StoreImage"));
          }
        });

        // RecruitImage
        if (this.V3.RecruitImage.get("RecruitImage")) {
          combinedV3Images.append("RecruitImage", this.V3.RecruitImage.get("RecruitImage"));
        }

        // V3のファイルをallAllMediaDataに追加
        for (var pair of combinedV3Images.entries()) {
          allAllMediaData.append(pair[0], pair[1]);
        }
      }

      if (this.TemplateVersion === 'v4') {
        var combinedV4Images = new FormData();

        // TopImageItems
        this.V4.TopImageItems.forEach((item, index) => {
          if (item.TopImage.get("TopImage")) {
            combinedV4Images.append(`TopImage_${index}`, item.TopImage.get("TopImage"));
          }
        });

        // RepresentativeGreetingFileName
        if (this.V4.RepresentativeGreetingFile.get("RepresentativeGreetingFile")) {
          combinedV4Images.append("RepresentativeGreetingFileName", this.V4.RepresentativeGreetingFile.get("RepresentativeGreetingFile"));
        }

        // ThoughtItems
        this.V4.ThoughtItems.forEach((item, index) => {
          if (item.File.get("ThoughtFile")) {
            combinedV4Images.append(`ThoughtFile_${index}`, item.File.get("ThoughtFile"));
          }
        });

        // FoodImageItems
        this.V4.FoodImageItems.forEach((item, index) => {
          if (item.FoodImage.get("FoodImage")) {
            combinedV4Images.append(`FoodImage_${index}`, item.FoodImage.get("FoodImage"));
          }
        });

        // StoreItems
        this.V4.StoreItems.forEach((item, index) => {
          if (item.File.get("StoreImage")) {
            combinedV4Images.append(`StoreFile_${index}`, item.File.get("StoreImage"));
          }
        });

        // RecruitImage
        if (this.V4.RecruitImage.get("RecruitImage")) {
          combinedV4Images.append("RecruitImage", this.V4.RecruitImage.get("RecruitImage"));
        }

        // V3のファイルをallAllMediaDataに追加
        for (var pair of combinedV4Images.entries()) {
          allAllMediaData.append(pair[0], pair[1]);
        }
      }
      // FOR TESTING/DEBUGGING PURPOSE
      // console.log('TopFile v1v2', allAllMediaData.get("TopFile"));  // v1,v2
      // console.log('EyeCatchImage v1', allAllMediaData.get("EyeCatchImage")); //v1
      // console.log('CompanyImage v1, v2', allAllMediaData.get("CompanyImage")); /// v1,v2
      // console.log('combinedAboutImageImages v1', allAllMediaData.getAll("CompanyIntroImage")); // v1
      // console.log('PopularSecretImage, reason images v1,v2', allAllMediaData.getAll("PopularSecretImage")); // v1,v2
      // console.log('SalaryModelImage v2', allAllMediaData.getAll("SalaryModelImage")); //v2
      // console.log('InterviewImage v2', allAllMediaData.getAll("InterviewImage")); //v2
      // console.log('GalleryImage v2', allAllMediaData.getAll("GalleryImage")); //v2
      // console.log('GreetingImage v2', allAllMediaData.get("GreetingImage")); //v2
      // console.log('HiringProcessImage v2', allAllMediaData.getAll("HiringProcessImage")); //v2
      await axios.post("/media/upload" , allAllMediaData);
      }
      catch(e){
        console.log("error in media", e);
      }
      finally{}
    },
    aboutImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.AboutItems.$each[key].AboutImageUrl.$dirty) return errors;
      !this.$v.AboutItems.$each[key].AboutImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    aboutTitleErrors(key) {
      const errors = [];
      if (!this.$v.AboutItems.$each[key].Title.$dirty) return errors;
      !this.$v.AboutItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.AboutItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    aboutContentsErrors(key) {
      const errors = [];
      if (!this.$v.AboutItems.$each[key].Contents.$dirty) return errors;
      const content = this.$v.AboutItems.$each[key].Contents.$model;
      const length = getLength(content);
      if (length > 30000) {
          errors.push("30000文字以内で入力してください");
      }
      !this.$v.AboutItems.$each[key].Contents.required && errors.push("入力必須項目です");
      return errors;
    },
    reasonImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.ReasonItems.$each[key].ReasonImageUrl.$dirty) return errors;
      !this.$v.ReasonItems.$each[key].ReasonImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    reasonTitleErrors(key) {
      const errors = [];
      if (!this.$v.ReasonItems.$each[key].Title.$dirty) return errors;
      !this.$v.ReasonItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.ReasonItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    reasonContentsErrors(key) {
      const errors = [];
      if (!this.$v.ReasonItems.$each[key].Contents.$dirty) return errors;
      !this.$v.ReasonItems.$each[key].Contents.maxLength && errors.push("30000文字以内で入力してください");
      !this.$v.ReasonItems.$each[key].Contents.required && errors.push("入力必須項目です");
      return errors;
    },
    reasonHeaderErrors(key) {
      const errors = [];
      if (!this.$v.ReasonItems.$each[key].Header.$dirty) return errors;
      !this.$v.ReasonItems.$each[key].Header.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    salaryImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.SalaryModelItems.$each[key].SalaryImageUrl.$dirty) return errors;
      !this.$v.SalaryModelItems.$each[key].SalaryImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    salaryTitleErrors(key) {
      const errors = [];
      if (!this.$v.SalaryModelItems.$each[key].Title.$dirty) return errors;
      !this.$v.SalaryModelItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.SalaryModelItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    salaryTotalAmountErrors(key) {
      const errors = [];
      if (!this.$v.SalaryModelItems.$each[key].TotalAmount.$dirty) return errors;
      !this.$v.SalaryModelItems.$each[key].TotalAmount.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.SalaryModelItems.$each[key].TotalAmount.required && errors.push("入力必須項目です");
      return errors;
    },
    breakdownTitleErrors(key, breakdownKey) {
      const errors = [];
      if (!this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Title.$dirty) return errors;
      !this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Title.required && errors.push("入力必須項目です");
    },
    breakdownAmountErrors(key, breakdownKey) {
      const errors = [];
      if (!this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Amount.$dirty) return errors;
      !this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Amount.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.SalaryModelItems.$each[key].SalaryBreakdownItems.$each[breakdownKey].Amount.required && errors.push("入力必須項目です");
    },
    stepTitleErrors(key) {
      const errors = [];
      if (!this.$v.CareerStepItems.$each[key].Title.$dirty) return errors;
      !this.$v.CareerStepItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.CareerStepItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    stepDescriptionErrors(key) {
      const errors = [];
      if (!this.$v.CareerStepItems.$each[key].Description.$dirty) return errors;
      !this.$v.CareerStepItems.$each[key].Description.maxLength && errors.push("30000文字以内で入力してください");
      !this.$v.CareerStepItems.$each[key].Description.required && errors.push("入力必須項目です");
      return errors;
    },
    scheduleTimeErrors(key) {
      const errors = [];
      if (!this.$v.DailyScheduleItems.$each[key].Time.$dirty) return errors;
      !this.$v.DailyScheduleItems.$each[key].Time.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.DailyScheduleItems.$each[key].Time.required && errors.push("入力必須項目です");
      return errors;
    },
    scheduleTitleErrors(key) {
      const errors = [];
      if (!this.$v.DailyScheduleItems.$each[key].Title.$dirty) return errors;
      !this.$v.DailyScheduleItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.DailyScheduleItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    interviewImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.InterviewItems.$each[key].InterviewImageUrl.$dirty) return errors;
      !this.$v.InterviewItems.$each[key].InterviewImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    interviewTitleErrors(key) {
      const errors = [];
      if (!this.$v.InterviewItems.$each[key].Title.$dirty) return errors;
      !this.$v.InterviewItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.InterviewItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    interviewNameInitialErrors(key) {
      const errors = [];
      if (!this.$v.InterviewItems.$each[key].NameInitial.$dirty) return errors;
      !this.$v.InterviewItems.$each[key].NameInitial.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.InterviewItems.$each[key].NameInitial.required && errors.push("入力必須項目です");
      return errors;
    },
    galleryImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.GalleryItems.$each[key].GalleryImageUrl.$dirty) return errors;
      !this.$v.GalleryItems.$each[key].GalleryImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    processImageFilenameErrors(key) {
      const errors = [];
      if (!this.$v.HiringProcessItems.$each[key].ProcessImageUrl.$dirty) return errors;
      !this.$v.HiringProcessItems.$each[key].ProcessImageUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    processTitleErrors(key) {
      const errors = [];
      if (!this.$v.HiringProcessItems.$each[key].Title.$dirty) return errors;
      !this.$v.HiringProcessItems.$each[key].Title.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.HiringProcessItems.$each[key].Title.required && errors.push("入力必須項目です");
      return errors;
    },
    metaTagNameErrors(key) {
      const errors = [];
      if (!this.$v.MetaTags.$each[key].Name.$dirty) return errors;
      !this.$v.MetaTags.$each[key].Name.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    metaTagPropertyErrors(key) {
      const errors = [];
      if (!this.$v.MetaTags.$each[key].Property.$dirty) return errors;
      !this.$v.MetaTags.$each[key].Property.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    metaTagContentErrors(key) {
      const errors = [];
      if (!this.$v.MetaTags.$each[key].Content.$dirty) return errors;
      !this.$v.MetaTags.$each[key].Content.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    ...mapActions("snackbar", ["openSnackbarSuccess", "openSnackbarApiError", "openSnackbarValidationError"]),
  },
  validations() {
    const commonRules = {
      // TopImageFilename: { required, maxLength: maxLength(255) },
      TopImageFileUrl:  {required},
      TopTitle: { required },
      // CompanyImageFilename: { required, maxLength: maxLength(255) },
      CompanyImageFileUrl : { required },
      ReasonTitle: { required, maxLength: maxLength(255) },
      ReasonItems: {
        $each: {
          // ImageFilename: { required, maxLength: maxLength(50) },
          ReasonImageUrl: { required },
          Title: { required, maxLength: maxLength(255) },
          Contents: { required, maxLength: maxLength(30000) },
          Header: {maxLength: maxLength(255)},
        }
      },
      MetaTags: {
        $each: {
          Name: { maxLength: maxLength(255) },
          Property: { maxLength: maxLength(255) },
          Content: { maxLength: maxLength(255) },
        }
      }
    };
    let versionRules = {};
    if (this.TemplateVersion === "v1") {
      versionRules = {
        // EyecatchImageFilename: { required, maxLength: maxLength(255) },
        EyecatchImageFileUrl : { required },
        EyecatchTitle: { required, maxLength: maxLength(255) },
        TopCompanyName: { maxLength: maxLength(255) },
        AboutTitle: { required, maxLength: maxLength(255) },
        AboutItems: {
          $each: {
            // ImageFilename: { required, maxLength: maxLength(50) },
            AboutImageUrl: { required },
            Title: { required, maxLength: maxLength(255) },
            Contents: { required, maxLength: maxLength(30000) },
          }
        },
      };
    } else if (this.TemplateVersion === "v2") {
      versionRules = {
        TopDescription: { maxLength: maxLength(30000) },
        CompanyTitle: { required, maxLength: maxLength(255) },
        CompanyDescription: { required, maxLength: maxLength(30000) },
        // GreetingImageFilename: { required, maxLength: maxLength(255) },
        GreetingImageFileUrl: { required },
        GreetingTitle: { required, maxLength: maxLength(255) },
        GreetingName: { required, maxLength: maxLength(255) },
        GreetingDescription: { required, maxLength: maxLength(30000) },
        SalaryModelItems: {
          $each: {
            // ImageFilename: { required, maxLength: maxLength(50) },
            SalaryImageUrl: { required },
            Title: { required, maxLength: maxLength(255) },
            TotalAmount: { required, maxLength: maxLength(255) },
            SalaryBreakdownItems: {
              $each: {
                Title: { required, maxLength: maxLength(255) },
                Amount: { required, maxLength: maxLength(255) },
              }
            },
          }
        },
        CareerStepItems: {
          $each: {
            Title: { required, maxLength: maxLength(255) },
            Description: { required, maxLength: maxLength(30000) },
          }
        },
        DailyScheduleItems: {
          $each: {
            Time: { required, maxLength: maxLength(255) },
            Title: { required, maxLength: maxLength(255) },
          }
        },
        InterviewItems: {
          $each: {
            // ImageFilename: { required, maxLength: maxLength(50) },
            InterviewImageUrl: { required },
            Title: { required, maxLength: maxLength(255) },
            NameInitial: { required, maxLength: maxLength(255) },
          }
        },
        GalleryItems: {
          $each: {
            GalleryImageUrl: { required },
            // ImageFilename: { required, maxLength: maxLength(50) },
          }
        },
        HiringProcessItems: {
          $each: {
            ProcessImageUrl: { required },
            // ImageFilename: { required, maxLength: maxLength(50) },
            Title: { required, maxLength: maxLength(255) },
          }
        },
      };
    }
    return {
      ...commonRules,
      ...versionRules,
    };
  },
  computed: {
    templateVersions() {
      return ["v1", "v2", "v3", "v4"];
    },
    imageUrlErrors() {
      const errors = [];
      if (!this.$v.TopImageFileUrl.$dirty) return errors;
      !this.$v.TopImageFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    topTitleErrors() {
      const errors = [];
      if (!this.$v.TopTitle.$dirty) return errors;
      const length = getLength(this.$v.TopTitle.$model);
      if (length > 255) {
          errors.push("255文字以内で入力してください");
      }
      !this.$v.TopTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    topDescriptionErrors() {
      const errors = [];
      if (!this.$v.TopDescription || !this.$v.TopDescription.$dirty) return errors;
      !this.$v.TopDescription.maxLength && errors.push("30000文字以内で入力してください");
      return errors;
    },
    eyecatchImageFilenameErrors() {
      const errors = [];
      if (!this.$v.EyecatchImageFileUrl || !this.$v.EyecatchImageFileUrl.$dirty) return errors;
      !this.$v.EyecatchImageFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    eyecatchTitleErrors() {
      const errors = [];
      if (!this.$v.EyecatchTitle || !this.$v.EyecatchTitle.$dirty) return errors;
      !this.$v.EyecatchTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.EyecatchTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    companyImageFilenameErrors() {
      const errors = [];
      if (!this.$v.CompanyImageFileUrl.$dirty) return errors;
      !this.$v.CompanyImageFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    companyTitleErrors() {
      const errors = [];
      if (!this.$v.CompanyTitle || !this.$v.CompanyTitle.$dirty) return errors;
      !this.$v.CompanyTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.CompanyTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    companyDescriptionErrors() {
      const errors = [];
      if (!this.$v.CompanyDescription || !this.$v.CompanyDescription.$dirty) return errors;
      !this.$v.CompanyDescription.maxLength && errors.push("30000文字以内で入力してください");
      !this.$v.CompanyDescription.required && errors.push("入力必須項目です");
      return errors;
    },
    greetingImageFilenameErrors() {
      const errors = [];
      if (!this.$v.GreetingImageFileUrl || !this.$v.GreetingImageFileUrl.$dirty) return errors;
      !this.$v.GreetingImageFileUrl.required && errors.push("入力必須項目です");
      return errors;
    },
    greetingTitleErrors() {
      const errors = [];
      if (!this.$v.GreetingTitle || !this.$v.GreetingTitle.$dirty) return errors;
      !this.$v.GreetingTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.GreetingTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    greetingNameErrors() {
      const errors = [];
      if (!this.$v.GreetingName || !this.$v.GreetingName.$dirty) return errors;
      !this.$v.GreetingName.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.GreetingName.required && errors.push("入力必須項目です");
      return errors;
    },
    greetingDescriptionErrors() {
      const errors = [];
      if (!this.$v.GreetingDescription || !this.$v.GreetingDescription.$dirty) return errors;
      !this.$v.GreetingDescription.maxLength && errors.push("30000文字以内で入力してください");
      !this.$v.GreetingDescription.required && errors.push("入力必須項目です");
      return errors;
    },
    topCompanyNameErrors() {
      const errors = [];
      if (!this.$v.TopCompanyName || !this.$v.TopCompanyName.$dirty) return errors;
      !this.$v.TopCompanyName.maxLength && errors.push("255文字以内で入力してください");
      return errors;
    },
    aboutEntireTitleErrors() {
      const errors = [];
      if (!this.$v.AboutTitle || !this.$v.AboutTitle.$dirty) return errors;
      !this.$v.AboutTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.AboutTitle.required && errors.push("入力必須項目です");
      return errors;
    },
    reasonEntireTitleErrors() {
      const errors = [];
      if (!this.$v.ReasonTitle.$dirty) return errors;
      !this.$v.ReasonTitle.maxLength && errors.push("255文字以内で入力してください");
      !this.$v.ReasonTitle.required && errors.push("入力必須項目です");
      return errors;
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>
  .custom-file-input >>> .v-input__append-inner,
  .custom-file-input >>> .v-input__prepend-outer {
    margin: 10px 5px;
  }
  .image-styles {
    max-width : 100%;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
</style>